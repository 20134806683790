<template>
<div class="option-range">
    <div class="type-chart">
        <div class="name-chart segment">
            <template v-for="(chart, i) in chartType">
            <div v-if="(chart.chart == 'cognition' && videoDetail.cognition_survey == 1 || chart.chart != 'cognition')"
                class="item-range"
                :class="{ active: chart.value == optionTypeSelected }"
                :key="chart.value"
                @click="handleSelecteTypeChart(chart.value, chart.interaction_tally)"
                data-total-report="action"
            >
                {{ chart.name }}
            </div>
            <div class="item-range" v-if="i < (chartType.length - 1) && ((chart.chart == 'cognition') && videoDetail.cognition_survey == 1 || chart.chart != 'cognition' && chart.name != '25%' || videoDetail.cognition_survey == 1 && chart.name == '25%')" :key="'split-' + i">|</div>
        </template>
            <template>
                <div class="lengend-column" v-if="labelChart.left">
                    <i class="icon icon-checkbox"
                     data-total-report="action"
                    :class="checkedLabelLeft ? 'checked' : ''" 
                    @click="handleSelectLabelLeft(labelChart.left)"></i>
                    <div class='pl-2'>{{labelChart.left}}</div>
                </div>
                <div class="lengend-column" v-if="labelChart.right">
                    <i class='icon icon-checkbox'
                     data-total-report="action"
                    :class="checkedLabelRight ? 'checked' : ''" 
                    @click="handleSelectLabelRight(labelChart.right)"></i>
                    <div class='pl-2'>{{labelChart.right}}</div>
                </div>
            </template>
            <template v-if="optionTypeSelected != 6 && textInteraction.rank_2 && textInteraction.rank_3">
                <div class="lengend-column">
                    <i class="icon icon-checkbox"
                     data-total-report="action"
                    :class="checkedLabelStack ? 'checked' : ''" 
                    @click="handleSelectLabelStack()"></i>
                    <div class='pl-2'>積み上げ</div>
                </div>
            </template>
        </div>
        <div class="name-chart" v-if="!is25percent">
            <template v-for="(option, i) in options">
            <div
                class="item-range"
                :class="{ active: option.step == optionSelected }"
                :key="option.step"
                data-total-report="action"
                @click="handleSelected(option.step)"
            >
                {{ option.second }}s
            </div>
            <div class="item-range" v-if="i < (options.length - 1)" :key="'split-' + i">|</div>
            </template>
        </div>
    </div>
    <div class="icon-lengend" v-if="selectChartType.chart == 'norm'">
        <div class="item-select active" :class="!checkedLabelNormVideo ? 'label-opacity' : ''"></div>
        <div class="pl-1 label" :class="!checkedLabelNormVideo ? 'label-opacity' : ''"
             data-total-report="action"
             @click="handleSelectLabelNormVideo(labelChartNorm.video)">対象動画インタラクション</div>
        <div class="item-select ml-3" :class="!checkedLabelNormBuyer ? 'label-opacity' : ''"></div>
        <div class="pl-1" :class="!checkedLabelNormBuyer ? 'label-opacity' : ''"
             data-total-report="action"
             @click="handleSelectLabelNormBuyer(labelChartNorm.buyer)">平均インタラクション</div>
    </div>
</div>
</template>
<script>
import EventBus from "@/commons/event_bus";
export default {
  props: {
    options: Array, //ex: [{step: 1, second: 15}, {step: 2, second: 30}, {step: 4, second: 60}, {step: 8, second: 120}]
    eventName: String, //ex: name of event register in event bus
    chartType: Array,
    eventChartType: String,
    labelChart: Object,
    is25percent: Boolean,
    videoDetail: Object,
    selectChartType:Object
  },
  data() {
    return {
      optionSelected: 1,
      optionTypeSelected: this.$store.getters.getChartTypeValue,
      optionLabelLeft: 1,
      eventSelectLabel: 'selectLabel',
      eventSelectLabelNorm: 'selectLabelNorm',
      checkedLabelLeft: true,
      checkedLabelRight: true,
      checkAllChartNorm: 1,
      checkGoodChartNorm: 0,
      checkBadChartNorm: 0,
      typeChartBasic: {
          all: 0,
          left: 1,
          right: 2
      },
      eventSelectChartNorm: 'selectChartNorm',
      labelChartNorm: {
          video: 'video',
          buyer: 'buyer'
      },
      checkedLabelNormVideo: true,
      checkedLabelNormBuyer: true,
      checkedLabelStack: true
    }
  },
  methods: {
    handleSelectLabelStack(){
        this.checkedLabelStack = !this.checkedLabelStack;
        this.$emit('selectedLabelStack', this.checkedLabelStack)
    },
    handleSelected(step) {
      this.optionSelected = step;
      EventBus.$emit(this.eventName, step);
    },
    handleSelecteTypeChart(typeValue, interaction_tally) {
        if (typeValue !== this.optionTypeSelected) {
          this.optionTypeSelected = typeValue;
          this.$store.commit('updateChartTypeValue', this.optionTypeSelected)
          EventBus.$emit(this.eventChartType, {typeValue: typeValue, interaction_tally: interaction_tally});
        }
    },
    handleSelectLabelLeft(labelChart) {
        this.checkedLabelLeft = !this.checkedLabelLeft;
        EventBus.$emit(this.eventSelectLabel, labelChart, this.checkedLabelLeft);
    },
    handleSelectLabelRight(labelChart) {
        this.checkedLabelRight = !this.checkedLabelRight;
        EventBus.$emit(this.eventSelectLabel, labelChart, this.checkedLabelRight);
    },
    handleSelectLabelNormVideo(labelChart) {
        this.checkedLabelNormVideo = !this.checkedLabelNormVideo;
        EventBus.$emit(this.eventSelectLabelNorm, labelChart, this.checkedLabelNormVideo);
    },
    handleSelectLabelNormBuyer(labelChart) {
        this.checkedLabelNormBuyer = !this.checkedLabelNormBuyer;
        EventBus.$emit(this.eventSelectLabelNorm, labelChart, this.checkedLabelNormBuyer);
    }
  },
  computed : {
    textInteraction() {
        return {
            rank_2 : this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length ? this.videoDetail.interaction_btn_1.text : '',
            rank_3 : this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length ? this.videoDetail.interaction_btn_2.text : '',
        }
    },
  }
};
</script>
<style lang="scss">
@import '@/styles/variable';
.option-range {
    .type-chart {
        display: flex;
        justify-content: space-between;
        .name-chart {
            z-index: 2;
            display: flex;
            color: $middle-black;
            line-height: 28px !important;
            @include font(s);
            .item-range {
                cursor: pointer;
                margin-right: 10px;
                &.active {
                    color: $black;
                    font-weight: bold;
                }
            }
            .lengend-column {
                display: flex;
                align-items: center;
                padding-left: 24px;
            }
            &.segment {
                line-height: 40px !important;
                .lengend-column-segment {
                    cursor: pointer;
                    padding: 6px 20px;
                    border: 1px solid $middle-black;
                    border-radius: 8px;
                    @include font(m);
                    display: flex;
                    align-items: center;
                    margin-left: 8px;
                    font-weight: bold;
                    &.active {
                        color: $green;;
                        border-color: $green;
                    }
                }
            }
        }
    }
    .icon-lengend {
        display: flex;
        align-items: center;
        height: 9px;
        justify-content: flex-end;
        cursor: pointer;
        @include font(xs);
        color: $middle-black;
        font-weight: bold;
        position: relative;
        top: 10px;
        .label {
            color: $green;
        }
        .item-select {
            height: 7px;
            width: 7px;
            border-radius: 100%;
            border: 2px solid $middle-black;
            &.active {
                border-color: $green;
            }
        }
        .label-opacity {
            opacity: 0.2;
        }
    }
}
</style>