<template>
  <highcharts
    id="chart-container"
    class="mt-2"
    :options="chartOptions"
    ref="lineCharts"
    :constructor-type="'chart'"
  ></highcharts>
</template>
<script>
import { Chart } from "highcharts-vue";
import {
  getXColumnsByStep,
  formatDataChartWithStep,
  lineChartOption,
} from "@/commons/charts";
import EventBus from "@/commons/event_bus";
const jQuery = require("jquery");


export default {
  props: {
    step: Number,
    part: Number,
    dataReport: Object,
    changeStepChart: String,
    yAxisPointLine: Array,
    chartType: Object,
    dataReportNorm: Object,
    dataBuyerNorm: Object,
    interactionIcon : Object
  },
  components: {
    highcharts: Chart,
  },
  watch: {
    step: function (newValue) {
      this.chartOptions.xAxis.tickInterval = newValue;
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(newValue, 0, 15),
      };
      this.prevSelected = this.currentSelected;
      this.currentSelected = 0;
      this.chartOptions.xAxis.min = 0;
      this.chartOptions.xAxis.tickInterval = newValue;
      this.chartOptions.xAxis.max = newValue * 15.4;
      this.initSeriesData();
    },
    part: function (newValue, oldValue) {
      this.prevPart = oldValue;
      this.prevSelected = this.currentSelected;
      this.currentSelected = (newValue - 1) * this.step * 15;
      this.chartOptions.xAxis.min = (newValue - 1) * this.step * 15;
      this.chartOptions.xAxis.tickInterval = this.step;
      this.chartOptions.xAxis.max =
        (newValue - 1) * this.step * 15 + this.step * 15.3;
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(this.step, (newValue - 1) * this.step * 15, 15),
      };
      this.initSeriesData();
    },
  },
  computed: {
    tick: {
      get: function () {
        return this.chartOptions.xAxis.tickInterval;
      },
      set: function (value) {
        this.chartOptions.xAxis.tickInterval = value;
      },
    },
  },
  data() {
    let chartOptions = JSON.parse(JSON.stringify(lineChartOption));
    chartOptions.yAxis.labels= {
        format: "{value}%",
        style: {
          color: '#999999',
          fontSize: '8px',
          fontWeight: 'normal'
        }
    }
    chartOptions.xAxis.labels= {
      style: {
        color: '#999999',
          fontSize: '8px',
          fontWeight: 'normal'
        }
    }
    chartOptions.xAxis.tickInterval = this.step;
    chartOptions.xAxis.grid = {
      columns: getXColumnsByStep(this.step, (this.part - 1) * this.step * 15, 15),
    };
    chartOptions.xAxis.min = (this.part - 1) * this.step * 15;
    chartOptions.xAxis.tickInterval = this.step;
    chartOptions.xAxis.max =
        (this.part - 1) * this.step * 15 + this.step * 15.3;
    let imagePlotVideo,imagePlotAvg;
    let vm = this;
    return {
      chart: null,
      prevPart: 1,
      typeChartNorm: 0,
      checkedLabelNorm: {
        buyer : true,
        video: true
      },
      chartOptions: {
        chart: {
          height: "270px",
          animation: false,
          events: {
            load: function (e) {
              vm.load(e);
            },
          },
        },
        ...chartOptions,
      },
      yMax: 0,
      imagePlotVideo,
      imagePlotAvg
    };
  },
  methods: {
    handleChangeChartNorm(value) {
      this.typeChartNorm = value;
      this.chartOptions.xAxis.min = (this.part - 1) * this.step * 15;
      this.chartOptions.xAxis.tickInterval = this.step;
      this.chartOptions.xAxis.max =
        (this.part - 1) * this.step * 15 + this.step * 15.4;
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(this.step, (this.part - 1) * this.step * 15, 15),
      };
      this.initSeriesData();
      this.$nextTick(() => {
        this.loaded(this.chart)
      })
    },
    selectLabelNorm(labelChart,visible) {
      this.chartOptions.series.forEach((serieData,index) => {
        if (serieData.name == labelChart) {
          this.chart.series[index].setVisible(visible)
          this.checkedLabelNorm[labelChart] = visible
        }
      })
    },
    initSeriesData() {
      let prefix = 'total_'
      if (this.typeChartNorm == 1) {
        prefix = '2_'
      } else if (this.typeChartNorm == 2) {
        prefix = '3_'
      }
      let data = this.dataReportNorm[prefix+'norm_detail'] || [];
      let data2 = this.dataBuyerNorm[prefix+'detail_norm'] ||  [];
      let avg1 = this.dataReportNorm[prefix+'avg_norm'] || 0;
      let avg2 = this.dataBuyerNorm[prefix+'avg_norm'] || 0;
      let data_report = [];
      let data_buyer = [];
      let total_report = this.dataReportNorm['total_norm_detail']
      let total_buyer = this.dataBuyerNorm['total_detail_norm']
      let data_total = []
      if (data.length && data2.length) {
        for (let item of data) {
          data_report.push(item.norm)
        }
        for (let i = 0; i < data.length; i++) {
          if(data2[i]) {
            data_buyer.push(data2[i].norm)
          }
        }
        if (total_report.length && total_buyer.length) {
          for (let item_total of total_report) {
            data_total.push(item_total.norm)
          }
          for (let item of total_buyer) {
            data_total.push(item.norm)
          }
          this.yMax = Math.max(...data_total)
        }
      }
      this.setSeriesData(data_report, data_buyer, avg1, avg2)
    },

    setSeriesData(dataSet1, dataSet2, avg1, avg2) {
      let seriesData = [];
      let series = [];
      let pointLines = [];
      let step = (this.step) ? this.step : 1;
      let dataReport = {};
      let dataBuyer = {};
      dataReport['data'] = dataSet1;
      dataReport['color']= "#49AB94";
      dataReport['avg']= avg1;
      dataReport['textPoint']= '対象動画インタラクション';
      dataReport['classPointLabel']= 'plotline-target-video';
      dataReport['name'] = "video";
      dataBuyer['data'] = dataSet2;
      dataBuyer['color'] = "#999999";
      dataBuyer['avg'] = avg2;
      dataBuyer['textPoint']= '平均インタラクション';
      dataBuyer['name'] = "buyer"; 
      dataBuyer['classPointLabel']= 'plotline-avg';
      seriesData.push(dataReport)
      seriesData.push(dataBuyer)
  
      seriesData.forEach((data) => {
        var serie;
        var pointline;
        serie = {
            data: formatDataChartWithStep(data.data,step),
            color:data.color,
            name: data.name,
            opacity: 1,
            visible: this.checkedLabelNorm[data.name],
            states: {
                hover: {
                    opacity: 1,
                    lineWidth: 2,
                },
                inactive: {
                    opacity: 1
                }
            },
        };
        series.push(serie)
        pointline = {
          value: data.avg,
          color: data.color,
          dashStyle: 'dash',
          label: {
              text: data.textPoint,
              align: 'right',
              className: data.classPointLabel,
              useHTML: true,
              formatter: function () {
                return '<div class="plot-line">' +data.textPoint+'</div>'
              },
          }
        }
        pointLines.push(pointline)
      });
      this.chartOptions.series = series
      this.chartOptions.yAxis.plotLines = pointLines
      this.chartOptions.yAxis.max = this.yMax
    },
    load(e) {
      let chart = e.target;
      this.chart = chart;
      this.loaded(chart);
    },
    loaded(chart) {
      if (chart.yAxis[0].chart.options.yAxis[0].plotLines) {
        let pointlines = this.chartOptions.yAxis.plotLines
        let dataTargetVideo = pointlines.find(e => e.label.text == "対象動画インタラクション" )
        let dataAvg = pointlines.find(e => e.label.text == "平均インタラクション" )
        if (dataTargetVideo && dataAvg && dataTargetVideo.value > dataAvg.value) {
          jQuery('.plotline-target-video').css('z-index', 150)
          jQuery('.plotline-avg').css('z-index', 100)
        } else {
          jQuery('.plotline-target-video').css('z-index', 100)
          jQuery('.plotline-avg').css('z-index', 150)
        }
      }
    }
  },
  mounted() {
    this.chart = this.$refs.lineCharts.chart;
    this.initSeriesData();
  },
  created() {
    if (this.$props.interactionIcon.rank_2.length && this.$props.interactionIcon.rank_3.length) {
      this.typeChartNorm = 0;
    } else if (!this.$props.interactionIcon.rank_2.length && this.$props.interactionIcon.rank_3.length) {
      this.typeChartNorm = 2;
    } else if (this.$props.interactionIcon.rank_2.length && !this.$props.interactionIcon.rank_3.length) {
      this.typeChartNorm = 1;
    }
    this.initSeriesData();
    EventBus.$on('selectChartNorm', this.handleChangeChartNorm);
    EventBus.$on('selectLabelNorm', this.selectLabelNorm);
  },
  beforeDestroy() {
    EventBus.$off('selectChartNorm', this.handleChangeChartNorm);
    EventBus.$off('selectLabelNorm', this.selectLabelNorm);
  },
};
</script>
<style lang="scss">
@import '@/styles/variable';
.plotline-target-video {
    .plot-line {
      background-color: $green;
      border-radius: 2px;
      height: 12px;
      color: $white;
      padding: 0px 1px 0px 1px;
      @include font(xs);
      line-height: 12px;
      margin-top: 8px;
    }
}
.plotline-avg {
    .plot-line {
      background-color: $middle-black;
      border-radius: 2px;
      height: 12px;
      color: $white;
      padding: 0px 1px 0px 1px;
      @include font(xs);
      line-height: 12px;
      margin-top: 8px;
    }
}
.title-x-axis {
  right: 0px;
}
</style>
