<template>
  <div class="row" v-show="Object.keys(video).length">
    <div class="col-13 mr-0 outline-area" v-if="!$store.getters.isLoadingApi && !isLoadingCognition">
      <div class="group-top-detail">
        <div class="td-title-left">
          <div class="name-video font-bold">{{ video.name }}</div>
          <!-- <div class="btn btn-download btn-primary-outline ml-5">
                    <i class="icon icon-small-download"></i>
                    <div>PDFレポート </div>
                </div> -->
          <div class="download-report-csv" v-if="video.is_csv_downloadable">
            <div class="btn btn-download btn-primary-outline ml-2" @click="downloadReportCSV">
              <i class="icon icon-small-download"></i>
              <div>数値CSV</div>
            </div>
          </div>
          <div class="download-report-csv tooltip_custom_hover" v-else>
            <div class="btn btn-download btn-primary-outline ml-2">
              <i class="icon icon-small-download"></i>
              <div>数値CSV</div>
            </div>
            <span class="exclaimation_tooltip title_tooltip">数値CSVは調査期間終了日翌日からダウンロードできます</span>
          </div>
          <div class="btn btn-download btn-primary-outline ml-2 article_dl" @click="handleDownloadCSV">
            <i class="icon icon-small-download"></i>
            <div>動画DL/csv</div>
          </div>
        </div>
        <div class="td-title-right" @click="closeTab">
          <i class="icon icon-close"></i>
        </div>
      </div>
      <text-box
        :videoId="video.id"
        :urlGet="urlApiGetSummaryTabular"
        :urlPost="urlApiPostSummaryTabular"
        :placeholder="placeHolderTextBox"
        :classTextBox="classSummaryTextBox"
        :idTextBox="'summary-text-box'"
        :topTitle="'総合分析'"
        :setHeight="15"
      >
      </text-box>

      <Video
        v-if="video && Object.keys(video).length"
        :videoDetail="video"
        :reportVideo="report_summary"
        :chartType="chartType"
        :videoImage="videoImages[0]"
        :is25percent="is25percent"
        :selectChartType="selectChartType"
        :dataReportCognition="dataReportCognition"
        :dataBuyerNorm="dataBuyerNorm"
        :dataReportNorm="dataReportNorm"
        :totalInteractionGood="total_good"
        :totalInteractionBad="total_bad"
        :youtubeAudienceRating="youtube_audience_rate"
        @selectedLabelStack="handleSelectLabelStack"
      ></Video>
      <video-capture
        :videoDetail="video"
        :videoImage="videoImages[0]"
        :reportVideo="report_summary"
        :tabulars="listTabular"
      >
      </video-capture>

      <comment-option
        v-if="Object.keys(video).length && (video.is_comment_csv == 1 || video.is_comment_csv == 3)"
        :videoDetail="video"
        :videoImage="videoImages[0]"
        :reportVideo="report_summary"
        :comments="comments"
        :keyword="keyword"
        :commentNotFoundMsg="commentNotFoundMsg"
        :attributeIds="attributeIds"
        :attributeIdSelected="attributeIdSelected"
        :scenes="scenes"
        :attributeValueCommentCount="attributeValueCommentCount"
        :isCsvCommentUploading="isCsvCommentUploading"
        :isCommentCsv="video.is_comment_csv"
        :commentSummary="commentSummary"
        :filterData="filterData"
        :allComments="allComments"
      >
      </comment-option>
      <text-box
        v-if="Object.keys(video).length && (video.is_comment_csv == 1 || video.is_comment_csv == 3)"
        :videoId="video.id"
        :urlGet="urlApiGetCommentTabular"
        :urlPost="urlApiPostCommentTabular"
        :placeholder="placeHolderTextBox"
        :classTextBox="classCommentTextBox"
        :idTextBox="'comment-text-box'"
        :dataCustom="'data-cdai'"
        :textToolTip="textToolTip"
      >
      </text-box>

      <second-ranking :videoDetail="video" :videoImage="videoImages[0]"></second-ranking>

      <text-box
        :videoId="video.id"
        :urlGet="urlApiGetVideoTabular"
        :urlPost="urlApiPostVideoTabular"
        :placeholder="placeHolderTextBox"
        :classTextBox="classTextBox"
        :isSetMargin="true"
        :idTextBox="'top-text-box'"
        :dataCustom="'data-max-scene'"
      >
      </text-box>

      <video-segment
        v-if="Object.keys(video).length && video.is_csv_downloadable"
        :reportSummary="report_summary"
        :videoDetail="video"
        :videoImage="videoImages[0]"
      ></video-segment>

      <report-intention
        v-if="video.is_intention == 2"
        :reportSummary="report_summary"
        :videoDetail="video"
        :videoImage="videoImages[0]"
      ></report-intention>
    </div>
    <div class="loader" v-else></div>
  </div>
</template>

<script>
import Video from '../../components/TopDetail/Video.vue'
import VideoCapture from '../../components/TopDetail/VideoCapture.vue'
import SecondRanking from '../../components/TopDetail/SecondRanking.vue'
import VideoSegment from '../../components/TopDetail/VideoSegment.vue'
import CommentOption from '../../components/TopDetail/CommentOption.vue'
import TextBox from '../../components/TopDetail/TextBox.vue'
import ReportIntention from '../../components/TopDetail/ReportIntention.vue'
import {
  getVideoDetail,
  listimageVideo,
  getVideoReport,
  getVideoReportCognition,
  getSummaryNormData,
  getYoutubeAudienceRating,
  listVideoComments,
  uploadCsvComment,
  getChatgptSummarization,
  getVideoIntention,
} from '@/commons/api_service'
import EventBus from '@/commons/event_bus'
import i18n from '@/i18n'
import {
  chartType,
  evenTypeComment,
  intentionLevels,
  summaryCommentIdealTypes,
  videoIntentionStatus,
  otherCommentFlg,
} from '@/commons/constants'

export default {
  name: 'TopDetail',
  props: {},
  components: {
    VideoCapture,
    SecondRanking,
    Video,
    VideoSegment,
    TextBox,
    CommentOption,
    ReportIntention,
  },
  data() {
    return {
      textToolTip: this.$t('texts.tooltip.detail2'),
      video: {},
      videoImages: [],
      report_summary: {},
      project_report_summary: null,
      chartType: chartType,
      interactionTally: 1,
      chartTypeValue: 1,
      is25percent: false,
      dataReportCognition: null,
      selectChartType: chartType[0],
      dataBuyerNorm: null,
      dataReportNorm: null,
      interaction_max: 0,
      interaction_avg: 0,
      listTabular: [],
      total_good: 0,
      total_bad: 0,
      tih: {
        total: null,
        left: null,
        right: null,
      },
      tih_percent: {
        total: null,
        left: null,
        right: null,
      },
      tia: {
        total: null,
        left: null,
        right: null,
      },
      tia_percent: {
        total: null,
        left: null,
        right: null,
      },
      youtube_audience_rate: {},
      urlApiGetSummaryTabular: 'api.video.summary-tabular',
      urlApiPostSummaryTabular: 'api.create.video.summary-tabular',
      urlApiGetVideoTabular: 'api.video.top-tabular',
      urlApiPostVideoTabular: 'api.create.video.top-tabular',
      urlApiGetCommentTabular: 'api.video.comment-tabular',
      urlApiPostCommentTabular: 'api.create.video.comment-tabular',
      placeHolderTextBox: 'テキストが入力できます。',
      classTextBox: 'top-tabular',
      classCommentTextBox: 'comment-tabular',
      classSummaryTextBox: 'summary-tabular',
      isStackBar: 0,
      step: 1,
      comments: {},
      keyword: '',
      attributeIds: '',
      scenes: '',
      attributeIdSelected: null,
      commentNotFoundMsg: '',
      attributeValueCommentCount: null,
      isLoadingCognition: false,
      csvDownload: false,
      isCsvCommentUploading: false,
      commentSummary: {},
      commentSummaryErrorText: '要約したコメントが取得できませんでした。',
      filterData: {
        event_type: [],
        intention_id: null,
        level: [],
        no_reaction_flg: 1,
        intentions: [],
        intentionLevels: intentionLevels.filter(level => level.level !== 0),
      },
      searchFlag: false,
      allComments: {},
      otherFlgComment: otherCommentFlg.disabled,
    }
  },
  methods: {
    handleSelectLabelStack(isStack) {
      if (isStack) {
        this.isStackBar = 0
      } else {
        this.isStackBar = 1
      }
      this.loadSummary(false)
      this.loadReportCognition(false)
      this.setGoodBadInteraction(false)
    },
    handleChangeTypeChart(payLoad) {
      this.chartTypeValue = payLoad.typeValue
      this.interactionTally = payLoad.interaction_tally
      chartType.forEach(data => {
        if (data.value == payLoad.typeValue) {
          this.selectChartType = data
        }
      })
      if (this.chartTypeValue == 1 || this.chartTypeValue == 2) {
        this.is25percent = false
        if (this.chartTypeValue == 2) {
          this.isStackBar = 0
        }
        this.loadSummary(false)
      }
      if (this.chartTypeValue == 3) {
        this.is25percent = true
        this.loadSummary(false)
      }
      if (this.chartTypeValue == 4 || this.chartTypeValue == 5) {
        this.is25percent = false
        this.loadReportCognition(false)
      }

      if (this.chartTypeValue == 6) {
        this.is25percent = false
        this.loadSummaryNormData()
      }
    },
    handleDownloadCSV() {
      let id = this.video.id
      if (id) {
        let url = this.$router.resolve({
          name: 'api.videos.download_csv',
          params: { id: id },
        }).href
        this.$http2.downloadCSV(url, { filename: `video_info_${id}.csv` }, () => {})
      }
    },
    downloadReportCSV() {
      if (this.video.is_csv_downloadable) {
        let id = this.video.id
        let url = this.$router.resolve({
          name: 'api.videos.report_file',
          params: { id: id },
        }).href
        this.$http2.getReportFile(url, { filename: `2_video_info_${id}.csv` }, () => {})
      }
    },
    downloadCommentReportCSV() {
      let id = this.video.id
      let msgErr = 'レポートの完成まで数日お待ちください'
      let url = this.$router.resolve({
        name: 'api.comment.csv',
        query: { video_id: id },
      }).href
      this.$http2.downloadCommentCSV(
        url,
        { filename: `comment_info_${id}.csv` },
        () => {},
        err => {
          if (err.status) {
            this.$toasted.error(msgErr)
          }
        },
        msgErr,
      )
    },
    loadSummary(isLoading = true) {
      let paramReport = {
        buyer_id: this.video.buyer_id,
        video_ids: this.video.id,
        interaction_tally: parseInt(this.interactionTally),
        is_stack_bar: this.isStackBar,
      }
      getVideoReport(
        paramReport,
        report => {
          this.report_summary = report.length ? report[0] : { ...this.report_summary }
          this.getTabulars()
          this.tih.total = this.report_summary.tih.total
          this.tih.left = this.report_summary.tih.left
          this.tih.right = this.report_summary.tih.right
          this.tih_percent.total = this.report_summary.tih_percent.total
          this.tih_percent.left = this.report_summary.tih_percent.left
          this.tih_percent.right = this.report_summary.tih_percent.right
          this.tia.total = this.report_summary.tia.total
          this.tia.left = this.report_summary.tia.left
          this.tia.right = this.report_summary.tia.right
          this.tia_percent.total = this.report_summary.tia_percent.total
          this.tia_percent.left = this.report_summary.tia_percent.left
          this.tia_percent.right = this.report_summary.tia_percent.right
        },
        false,
        isLoading,
      )
    },
    loadReportCognition(isLoading = true) {
      if (isLoading) this.isLoadingCognition = true
      let param = {
        buyer_id: this.video.buyer_id,
        video_id: this.video.id,
        interaction_tally: 0,
        is_stack_bar: this.isStackBar,
      }
      getVideoReportCognition(
        param,
        report => {
          this.isLoadingCognition = false
          if (report.length == 0) return false
          this.dataReportCognition = report[0].data
        },
        false,
        isLoading,
      )
    },
    loadSummaryNormData() {
      let params = {
        buyer_id: this.video.buyer_id,
        video_ids: this.video.id,
        is_stack_bar: this.isStackBar,
      }
      getSummaryNormData(params, data => {
        if (data.length == 0) return false
        this.dataBuyerNorm = data[0]
        this.dataReportNorm = data[1]
        // this.loadDataChartNorm();
      })
    },
    setGoodBadInteraction(isLoading = true) {
      let paramReport = {
        buyer_id: this.video.buyer_id,
        video_ids: this.video.id,
        interaction_tally: 1,
        is_stack_bar: this.isStackBar,
      }
      getVideoReport(
        paramReport,
        report => {
          let reports = report.length ? report[0] : { ...this.report_summary }
          this.total_good = reports.total_2
          this.total_bad = reports.total_3
          this.tih.total = reports.tih.total
          this.tih.left = reports.tih.left
          this.tih.right = reports.tih.right
          this.tih_percent.total = reports.tih_percent.total
          this.tih_percent.left = reports.tih_percent.left
          this.tih_percent.right = reports.tih_percent.right
          this.tia.total = reports.tia.total
          this.tia.left = reports.tia.left
          this.tia.right = reports.tia.right
          this.tia_percent.total = reports.tia_percent.total
          this.tia_percent.left = reports.tia_percent.left
          this.tia_percent.right = reports.tia_percent.right
        },
        false,
        isLoading,
      )
    },
    gotoURL() {
      this.$router.back()
    },
    getTabulars() {
      let params = {
        video_id: this.video.id,
      }
      let url = this.$router.resolve({
        name: 'api.video-tabular.detail',
      }).href
      this.$http2.get(
        url,
        params,
        data => {
          this.listTabular = data.map(item => {
            return {
              tabular_line_id: item.tabular_line_id,
              tabular: {
                cell_notes: item.tabular.cell_notes,
                free_notes: item.tabular.free_notes,
                second: {
                  start: item.tabular.second.start,
                  end: item.tabular.second.end,
                  old_end: item.tabular.second.end,
                },
                lefts: this.getListInteractionPerSecond(item.tabular.second.start, item.tabular.second.end, 'left'),
                rights: this.getListInteractionPerSecond(item.tabular.second.start, item.tabular.second.end, 'right'),
              },
            }
          })
        },
        error => {
          console.log(error)
        },
      )
    },
    getListInteractionPerSecond(start, end, type) {
      let result = []
      for (let i = start; i <= end; i++) {
        if (Object.keys(this.report_summary).length) {
          if (type == 'left' && this.report_summary.tally_result[i])
            result.push(Math.abs(this.report_summary.tally_result[i].left))
          if (type == 'left' && !this.report_summary.tally_result[i]) result.push(0)

          if (type == 'right' && this.report_summary.tally_result[i])
            result.push(Math.abs(this.report_summary.tally_result[i].right))
          if (type == 'right' && !this.report_summary.tally_result[i]) result.push(0)
        } else {
          if (type == 'left') result.push(0)
          if (type == 'right') result.push(0)
        }
      }
      return result
    },
    loadYoutubeAudienceRating() {
      let youtubeVideoId = this.video.youtube_video_id
      let query = {
        video_id: this.video.id,
      }
      if (youtubeVideoId !== null) {
        getYoutubeAudienceRating(query, data => {
          this.youtube_audience_rate = data.length ? data[0] : { ...this.youtube_audience_rate }
        })
      } else {
        this.youtube_audience_rate = {}
      }
    },
    closeTab() {
      window.close()
    },
    loadListVideoComments() {
      let param = {}

      if (this.keyword) {
        param.keywords = this.keyword
      }
      if (this.attributeIds) {
        param.attribute_value_ids = this.attributeIds
      }
      if (this.scenes) {
        param.scenes = this.scenes
      }

      param.no_reaction_flg = this.filterData.no_reaction_flg
      param.event_type = this.filterData.event_type.length > 0 ? this.filterData.event_type.toString() : null
      param.intention_id = this.filterData.intention_id ?? null
      param.level = this.filterData.level.length > 0 ? this.filterData.level.toString() : null
      param.others_flg = this.otherFlgComment

      listVideoComments(param, this.video.id, comments => {
        this.comments = comments ? comments : {}
        // save first response to allComments
        if (!this.searchFlag) {
          this.allComments = comments ? comments : {}
          this.searchFlag = true
        }
        if (this.comments.all_comment) this.commentNotFoundMsg = ''
        this.attributeValueCommentCount = this.comments.attribute_value_comment_count
      })
    },
    updateAttrComment(val) {
      this.attributeIds = val.length ? val.toString() : ''
      if (this.attributeIds) {
        this.commentNotFoundMsg = '該当するコメントがありません。'
      }
      this.loadListVideoComments()
    },
    updateAttrIdSelected(val) {
      this.attributeIdSelected = val
    },
    updateKeyWordComment(val) {
      this.keyword = val
      this.commentNotFoundMsg = 'キーワードに該当するコメントはありません。'
      this.loadListVideoComments()
    },
    updateSceneComment(val) {
      if (val.length) {
        let videoDuration = this.video.video_length
        let isOtherFlg = val.some(scene => Number(scene) == videoDuration + 1)
        if (isOtherFlg) {
          val = val.filter(scene => Number(scene) != videoDuration + 1)
          this.otherFlgComment = otherCommentFlg.enabled
        } else {
          this.otherFlgComment = otherCommentFlg.disabled
        }
      } else {
        this.otherFlgComment = otherCommentFlg.disabled
      }

      this.scenes = val.length ? val.toString() : ''
      this.commentNotFoundMsg = '該当するコメントがありません。'
      this.loadListVideoComments()
    },
    updateFilterComment(data) {
      this.filterData = JSON.parse(JSON.stringify(data))
      // sort filterData.level and filterData.event_type in ascending order
      this.filterData.level.sort((a, b) => a - b)
      this.filterData.event_type.sort((a, b) => a - b)
      this.commentNotFoundMsg = '該当するコメントがありません。'
      // scroll to element has id = 'top-comment-detail'
      let element = document.getElementById('top-comment-detail')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
      this.loadListVideoComments()
    },
    isCsvDownload(val) {
      this.csvDownload = val
      this.downloadCommentReportCSV()
    },
    uploadCsvCommentToS3(fileInfo) {
      let vm = this
      uploadCsvComment(
        this.video.id,
        fileInfo,
        function () {
          vm.$toasted.success(i18n?.t('messages.update_success'))
          vm.isCsvCommentUploading = false
        },
        function () {
          vm.$toasted.error(i18n?.t('messages.update_fail'))
          vm.isCsvCommentUploading = false
        },
      )
    },
    loadChatgptSummarization() {
      const param = { video_id: this.video.id }
      const idealTypes = Object.values(summaryCommentIdealTypes)
      getChatgptSummarization(param).then(data => {
        this.commentSummary = data && data.data ? data.data : []
        if (this.commentSummary.length < 4) {
          const existingTypes = this.commentSummary.map(summary => summary.type)
          const missingTypes = idealTypes.filter(type => !existingTypes.includes(type))
          const missingSummaries = missingTypes.map(type => ({
            type,
            summarization: this.commentSummaryErrorText,
          }))

          this.commentSummary = [...this.commentSummary, ...missingSummaries]
        }
        this.commentSummary = this.commentSummary
          .map(({ summarization, ...rest }) => ({
            ...rest,
            summarization: summarization.replace(/\\n/g, '\n'),
          }))
          .sort((a, b) => a.type - b.type)
      })
    },
    initFilterData() {
      let param = {
        video_ids: this.video.id,
      }

      if (this.video.interaction_btn_1) {
        this.filterData.event_type.push(evenTypeComment.btn1)
      }

      if (this.video.interaction_btn_2) {
        this.filterData.event_type.push(evenTypeComment.btn2)
      }
      // sort filterData to save param url for vuex
      this.filterData.event_type.sort((a, b) => a - b)

      if (this.video.is_intention == videoIntentionStatus.enabled) {
        getVideoIntention(param).then(data => {
          if (data && data.items && data.items.length > 0) {
            this.filterData.intentions.push(...data.items[0].intentions)
            this.filterData.intention_id = this.filterData.intentions[0].id
            this.filterData.level = this.filterData.intentionLevels.map(level => level.level)
            // sort filterData.level and filterData.event_type in ascending order
            this.filterData.level.sort((a, b) => a - b)
            this.loadListVideoComments()
          }
        })
      } else {
        this.loadListVideoComments()
      }
    },
  },
  created() {
    EventBus.$on('changeTypeChart', this.handleChangeTypeChart)
    EventBus.$on('updateAttrComment', this.updateAttrComment)
    EventBus.$on('updateAttrIdSelected', this.updateAttrIdSelected)
    EventBus.$on('updateKeyWordComment', this.updateKeyWordComment)
    EventBus.$on('updateSceneComment', this.updateSceneComment)
    EventBus.$on('updateFilterComment', this.updateFilterComment)
    EventBus.$on('isCsvDownload', this.isCsvDownload)
    EventBus.$on('uploadCsvComment', this.uploadCsvCommentToS3)
    let id = this.$route.params.video_id

    getVideoDetail(id).then(videoDetail => {
      this.video = videoDetail
      this.loadSummary()
      this.loadReportCognition()
      this.setGoodBadInteraction()
      this.loadYoutubeAudienceRating()
      this.loadChatgptSummarization()
      this.initFilterData()
    })
    let param = {
      video_ids: id,
      interaction_tally: 1,
    }
    listimageVideo(param).then(data => {
      this.videoImages = data
    })
  },
  beforeDestroy() {
    EventBus.$off('changeTypeChart', this.handleChangeTypeChart)
    EventBus.$off('updateAttrComment', this.updateAttrComment)
    EventBus.$off('updateAttrIdSelected', this.updateAttrIdSelected)
    EventBus.$off('updateKeyWordComment', this.updateKeyWordComment)
    EventBus.$off('updateSceneComment', this.updateSceneComment)
    EventBus.$off('isCsvDownload', this.isCsvDownload)
    EventBus.$off('uploadCsvComment', this.uploadCsvCommentToS3)
    EventBus.$off('updateFilterComment', this.updateFilterComment)
  },
}
</script>
<style lang="scss" scoped>
@import './../../styles/_top_detail.scss';

::v-deep .title {
  font-size: 24px;
  line-height: 39px;
  font-weight: bold;
}
</style>
