<template>
  <div class="chart-content row-3-col-2">
    <button-option
      v-if="!typeChart && interactionButtonColor.rank_2 && interactionButtonColor.rank_3"
      :reactionButton="reactionButton"
      @changeReactionButton="handleChangeReactionButton"
    >
    </button-option>
    <div class="chart-area">
      <div class="switch-tab">
        <div
          class="reaction"
          :class="chartMode == listChartMode.line.value ? 'checked' : 'unchecked'"
          data-report="action"
          @click="changeChartMode(listChartMode.line.value)"
        >
          {{ listChartMode.line.name }}
        </div>
        <div class="separate">｜</div>
        <div
          class="reaction"
          :class="chartMode == listChartMode.column.value ? 'checked' : 'unchecked'"
          data-report="action"
          @click="changeChartMode(listChartMode.column.value)"
        >
          {{ listChartMode.column.name }}
        </div>
      </div>
      <step-option :options="stepOptions" :selected="step" :eventName="changeStepOption"></step-option>
      <div class="title-y-axis">%</div>
      <div class="title-x-axis">Time</div>
      <i class="icon icon-prev" v-if="prevPartEnabled" @click="prevPart" data-report="action"></i>
      <i
        class="icon icon-next"
        v-if="nextPartEnabled && chartMode == listChartMode.line.value"
        @click="nextPart"
        data-report="action"
      ></i>

      <i
        class="icon icon-next"
        v-else-if="nextPartAvailable && chartMode == listChartMode.column.value"
        @click="nextPart"
        data-report="action"
      ></i>
      <div>
        <line-chart
          :dataReportSummary="reportSummary"
          @setChart="getChart"
          v-if="typeChart"
          :part="part"
          :step="step"
          :changeStepChart="changeStepOption"
          :seriesData="seriesData"
          :areaRangeLineSeriesData="areaRangeLineSeriesData"
          :selectedId="selectedSeriesId"
          :imageURL="image_url"
          :key="key"
          :column="column"
          :maxColumn="maxColumn"
          :lineChart="lineChart"
          @addClassActive="addClassActive"
          @removeClassActive="removeClassActive"
          :project="videoDetail"
          :chartMode="chartMode"
          :typeChart="typeChart"
          :intentionLevelSelected="intentionLevelSelected"
        ></line-chart>
        <line-change-button
          :areaRangeLineSeriesData="areaRangeLineSeriesData"
          @setChart="getChart"
          v-else-if="
            chartMode == listChartMode.line.value &&
            !typeChart &&
            (!reactionButton.btn1.checked || !reactionButton.btn2.checked)
          "
          :part="part"
          :dataReportSummary="reportSummary"
          :dataReport="videoIntention[0]"
          :step="step"
          :seriesData="convertSeriesDataForEachInteraction"
          :selectedId="selectedSeriesId"
          :interactionType="videoDetail.interaction_type"
          :imageURL="image_url"
          :column="column"
          :maxColumn="maxColumn"
          :numberColumn="column"
          @addClassActive="addClassActive"
          @removeClassActive="removeClassActive"
          :project="videoDetail"
          :questionIdSelected="questionIdSelected"
          :chartMode="chartMode"
          :typeChart="typeChart"
          :isArea="true"
          :reactionButton="reactionButton"
          :checkedReactionButton="reactionButton"
          :nameChart="'Percentage'"
          :lineChart="lineChart"
          :intentionLevelSelected="intentionLevelSelected"
        ></line-change-button>
        <column-change-button
          :areaRangeLineSeriesData="areaRangeLineSeriesData"
          :reactionButton="reactionButton"
          :intentionLevelSelected="intentionLevelSelected"
          @setChart="getChart"
          v-else-if="
            chartMode == listChartMode.column.value &&
            !typeChart &&
            (!reactionButton.btn1.checked || !reactionButton.btn2.checked)
          "
          :part="part"
          :dataReportSummary="reportSummary"
          :dataReport="videoIntention[0]"
          :step="step"
          :seriesData="convertSeriesDataForEachInteraction"
          :selectedId="selectedSeriesId"
          :interactionType="videoDetail.interaction_type"
          :imageURL="image_url"
          :column="column"
          :maxColumn="maxColumn"
          :numberColumn="column"
          @addClassActive="addClassActive"
          @removeClassActive="removeClassActive"
          :project="videoDetail"
          :questionIdSelected="questionIdSelected"
          :chartMode="chartMode"
          :typeChart="typeChart"
          :isArea="true"
          :checkedReactionButton="reactionButton"
          :nameChart="'Percentage'"
          :lineChart="lineChart"
        ></column-change-button>
        <area-range-line-chart
          v-else
          :dataReportSummary="reportSummary"
          @setChart="getChart"
          :part="part"
          :dataReport="videoIntention[0]"
          :step="step"
          :changeStepChart="changeStepOption"
          :seriesData="areaRangeLineSeriesData"
          :selectedId="selectedSeriesId"
          :intentionLevelSelected="intentionLevelSelected"
          :imageURL="image_url"
          :key="key"
          :column="column"
          :maxColumn="maxColumn"
          :lineChart="lineChart"
          :interactionType="videoDetail.interaction_type"
          @addClassActive="addClassActive"
          @removeClassActive="removeClassActive"
          :project="videoDetail"
          :questionIdSelected="questionIdSelected"
          :chartMode="chartMode"
          :typeChart="typeChart"
        ></area-range-line-chart>
      </div>
    </div>
  </div>
</template>
<script>
import { colorInteraction } from '@/commons/constants'
import StepOption from '@/components/TopDetail/StepOption.vue'
import { stepOptionTopDetailPageTopDetail } from '@/commons/charts'
import LineChart from '@/components/Charts/Intention/Line.vue'
import LineChangeButton from '@/components/Charts/Intention/LineChangeButton.vue'
import ColumnChangeButton from '@/components/Charts/Intention/ColumnChangeButton.vue'
import AreaRangeLine from '@/components/Charts/Intention/AreaRangeLine.vue'
import EventBus from '@/commons/event_bus'
import { listChartMode } from '@/commons/constants'
import ButtonOption from '@/components/Top/ButtonOption.vue'
export default {
  name: 'IntentionChart',
  components: {
    StepOption,
    LineChart,
    'area-range-line-chart': AreaRangeLine,
    ButtonOption,
    LineChangeButton,
    ColumnChangeButton,
  },

  props: [
    'reportSummary',
    'videoIntention',
    'attributeValueIdSelected',
    'allAttributeValueIdSelected',
    'selectedSeriesId',
    'seriesData',
    'videoImage',
    'videoDetail',
    'typeChart',
    'videoAttributeId',
    'questionIdSelected',
    'chartMode',
    'intentionLevelSelected',
    'areaRangeLineSeriesData',
  ],
  data() {
    return {
      chart: null,
      changeStepOption: 'changeStepSegmentChart',
      step: 1,
      part: 1,
      key: 1,
      column: 8,
      maxColumn: 8.5,
      lineChart: 'chartAttribute',
      nextPartAvailable: false,
      listChartMode: listChartMode,
      stepOptions: stepOptionTopDetailPageTopDetail,
      reactionButton: {
        btn1: {
          text: null,
          color: null,
          checked: true,
        },
        btn2: {
          text: null,
          color: null,
          checked: true,
        },
      },
    }
  },
  watch: {
    typeChart: function () {
      // this.nextPartAvailable = false
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.reflow()
        }
      })
    },
    questionIdSelected() {
      this.part = 1
    },
    videoDetail: {
      handler: function (value) {
        if (value && value.interaction_btn_1 && Object.keys(value.interaction_btn_1).length) {
          this.reactionButton.btn1 = {
            ...this.reactionButton.btn1,
            ...{
              text: value.interaction_btn_1.text,
              color: colorInteraction[value.interaction_btn_1.color].color,
            },
          }
        }

        if (value && value.interaction_btn_2 && Object.keys(value.interaction_btn_2).length) {
          this.reactionButton.btn2 = {
            ...this.reactionButton.btn2,
            ...{
              text: value.interaction_btn_2.text,
              color: colorInteraction[value.interaction_btn_2.color].color,
            },
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleChangeReactionButton(option) {
      if (this.reactionButton[option.type] != undefined) {
        this.reactionButton[option.type].checked = option.value
      }
    },
    getChart(chart) {
      this.chart = chart
    },
    handleChangeStepChart(step) {
      this.step = step
      this.part = 1
    },
    nextPart() {
      this.part += 1
    },
    prevPart() {
      this.part -= 1
    },
    addClassActive(data) {
      this.$emit('addClassActive', data)
    },
    removeClassActive(data) {
      this.$emit('removeClassActive', data)
    },
    nextPartActive(val) {
      this.nextPartAvailable = val
    },
    formatSeriesDataScreeningVideo(reportData, answerValueId) {
      let result = []
      if (reportData) {
        reportData.forEach(data => {
          let attrData
          attrData = data.values?.find(e => answerValueId == e.screening_answer_id)
          if (attrData) {
            attrData['second'] = data.second
            result.push(attrData)
          } else {
            attrData = {}
            attrData['second'] = data.second
            attrData['left_count'] = 0
            attrData['right_count'] = 0
            result.push(attrData)
          }
        })
      }
      return this.formatSeriesDataScreeningArray(result, answerValueId)
    },
    formatSeriesDataScreeningArray(reportData, answerValueId) {
      let data = []
      if (reportData) {
        data = reportData.map(e => Math.abs(e.left_count) + Math.abs(e.right_count))
      }
      return {
        id: answerValueId,
        data,
      }
    },
    formatSeriesDataChartEachInteractionSegment() {
      let series = []
      let reportData = this.reportSegments[0]?.data
      this.attributeValueIdSelected.forEach(id => {
        let result = []
        let attrValueid = id.split('-')[1]
        if (reportData) {
          reportData.forEach(data => {
            let attrData
            attrData = data.values?.find(e => attrValueid == e.attribute_value_id)
            if (attrData) {
              result.push([data.second, attrData.left_count, Math.abs(attrData.right_count)])
            } else {
              result.push([data.second, 0, 0])
            }
          })
        }
        let serie = {
          id: 'attrValue-' + attrValueid,
          data: result,
        }
        series.push(serie)
      })
      return series
    },
    changeChartMode(mode) {
      this.$emit('changeChartMode', mode)
    },
  },
  computed: {
    nextPartEnabled: function () {
      let seriesLength
      if (!this.questionIdSelected) {
        seriesLength = this.seriesData.map(e => e.data.length)
      } else {
        seriesLength = this.seriesDataFormatted.map(e => e.data.length)
      }

      let max = Math.max(...seriesLength) - 1
      return max > this.part * this.step * 8
    },
    prevPartEnabled: function () {
      return this.part > 1
    },
    image_url: function () {
      return this.$props.videoImage ? this.$props.videoImage.image_url : null
    },
    interactionButtonColor: function () {
      let color_rank_2 =
        this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length
          ? this.videoDetail.interaction_btn_1.color
          : ''
      let color_rank_3 =
        this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length
          ? this.videoDetail.interaction_btn_2.color
          : ''
      return {
        rank_2: color_rank_2,
        rank_3: color_rank_3,
      }
    },
    seriesDataFormatted: function () {
      let seriesDataFormatted = []
      if (!this.questionIdSelected) return this.seriesData
      if (this.reportScreening && this.reportScreening.data) {
        let reportData = this.reportScreening.data
        if (this.answerValueIdSelected.length == 0) {
          return seriesDataFormatted
        }
        this.answerValueIdSelected.forEach(id => {
          let seriesData = this.formatSeriesDataScreeningVideo(reportData, id)
          seriesDataFormatted.push(seriesData)
        })
      }

      return seriesDataFormatted
    },

    convertSeriesDataForEachInteraction: function () {
      let seriesData = []
      let vm = this
      const series = JSON.parse(JSON.stringify(this.areaRangeLineSeriesData))
      if (this.reactionButton.btn1.checked && !this.reactionButton.btn2.checked) {
        seriesData = series.map(function (item) {
          item.data = item.data.map(function (valueArray) {
            return valueArray[1]
          })
          item.colorLine = vm.interactionButtonColor.rank_2
          return item
        })
      } else if (!this.reactionButton.btn1.checked && this.reactionButton.btn2.checked) {
        seriesData = series.map(function (item) {
          item.data = item.data.map(function (valueArray) {
            return valueArray[2]
          })
          item.colorLine = vm.interactionButtonColor.rank_3
          return item
        })
      } else {
        seriesData = series.map(function (item) {
          item.data = item.data.map(function () {
            return null
          })
          return item
        })
      }

      return seriesData
    },
  },
  mounted() {},
  created() {
    EventBus.$on('changeStepSegmentChart', this.handleChangeStepChart)
    EventBus.$on('nextPartActive', this.nextPartActive)
  },
  beforeDestroy() {
    EventBus.$off('changeStepSegmentChart', this.handleChangeStepChart)
    EventBus.$off('nextPartActive', this.nextPartActive)
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variable';
.chart-content {
  position: relative;
  .interaction-checkbox {
    left: 2%;
    top: -65px;
  }
}
.chart-area {
  position: relative;
  @include font(xs);
  color: $middle-black;
  padding-top: 5px;
  .title-y-axis {
    position: absolute;
    top: 5px;
    left: 12px;
    font-weight: bold;
    z-index: 1;
  }
  .title-x-axis {
    position: absolute;
    right: 4px;
    bottom: 15px;
    font-weight: bold;
    z-index: 3;
  }
  #segment-chart-container {
    margin-top: 12px;
    margin-right: 12px;
  }
  .icon-next {
    position: absolute;
    right: 40px;
    bottom: -35px;
    z-index: 0;
    cursor: pointer;
    &.d-none {
      display: none;
    }
  }
  .icon-prev {
    position: absolute;
    z-index: 1;
    bottom: -35px;
    left: 42px;
    cursor: pointer;
    &.d-none {
      display: none;
    }
  }
}

.switch-tab {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  left: 10px;
  top: -20px;

  div {
    font-size: 10px;
  }

  .reaction {
    cursor: pointer;
  }

  .separate {
    margin: 0 5px;
  }

  .checked {
    font-weight: bold;
    color: $black;
  }
}
</style>
