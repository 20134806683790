<template>
  <div class="mt-5">
    <div class="title">最大値シーン</div>
    <div class="image-ranking">
        <div class="row-3 mt-4" v-if="interactionIcon.rank_2.length">
            <div class="row-3-col-1 max-capture-cut" v-for="item in rank_good" :key="item.second">
                <div class="card card-max-interaction w-100">
                    <div class="content">
                        <div class="title mb-3">
                            <div class="left">
                                <img style="margin-right : 8px; width : 24px" :src="interactionIcon.rank_2" alt="">
                                {{interactionText.rank_2}}の最大値カット No.{{item.rank}}
                            </div>
                            <div class="right">{{item.interaction_count}}%</div>
                        </div>
                        <div class="top">
                            {{item.second}}s
                        </div>
                        <div class="body">
                            <img class="card-image" :src="item.image_url">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row-3 mt-4" v-if="interactionIcon.rank_3.length">
            <div class="row-3-col-1 max-capture-cut" v-for="item_bad in rank_bad" :key="item_bad.second">
                <div class="card card-max-interaction w-100">
                    <div class="content">
                        <div class="title mb-3">
                            <div class="left">
                                <img style="margin-right : 8px; width : 24px" :src="interactionIcon.rank_3" alt="">
                                {{interactionText.rank_3}}の最大値カット No.{{item_bad.rank}}
                            </div>
                            
                            <div class="right">{{item_bad.interaction_count}}%</div>
                        </div>
                        <div class="top">
                            {{item_bad.second}}s
                        </div>
                        <div class="body">
                            <img class="card-image" :src="item_bad.image_url">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import {
  numberWithCommas
} from '../../commons/helpers';
export default {
  name: "SecondRanking",
  props: ['videoDetail', 'videoImage'],
  
  data() {
    return {
        rank_good: null,
        rank_bad: null,
    };
  },
  watch: {
      videoImage: function() {
        this.setImageSecond()
      }
  },
  mounted() {
    this.setImageSecond()
  },
  methods: {  
    setImageSecond() {
        if (this.$props.videoImage) {
            this.rank_good = [];
            this.rank_bad = [];
            let url = this.$props.videoImage.image_url
            let interaction_rank = this.$props.videoImage.interaction_rank;
            let listRankGood = interaction_rank["2_rank"];
            let listRankBad = interaction_rank["3_rank"];

            for (let item in listRankGood) {
                listRankGood[item].image_url = url + listRankGood[item].second + '.jpg';
                listRankGood[item].interaction_count = numberWithCommas(listRankGood[item].interaction_count)
                this.rank_good.push(listRankGood[item])
            }
            for (let item in listRankBad) {
                listRankBad[item].image_url = url + listRankBad[item].second + '.jpg';
                listRankBad[item].interaction_count = numberWithCommas(listRankBad[item].interaction_count)
                this.rank_bad.push(listRankBad[item])
            } 
        }
    }
  },
  computed : {
    interactionIcon(){
        return {
            rank_2 : this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length ? this.videoDetail.interaction_btn_1.btn_icon_url : '',
            rank_3 : this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length ? this.videoDetail.interaction_btn_2.btn_icon_url : '',
        }
    },
    interactionText(){
        return {
            rank_2 : this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length ? this.videoDetail.interaction_btn_1.text : '',
            rank_3 : this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length ? this.videoDetail.interaction_btn_2.text : '',
        }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./../../styles/_top_detail.scss";
</style>