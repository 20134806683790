<template>
  <div>
    <div class="video row-5 mt-2">
      <div class="row-5-col-3">
        <div class="video-detail">
          <div v-if="$props.videoDetail.video_url_type == videoUrlUpload" class="video-react-upload">
            <video
              :ref="$props.videoDetail.id"
              class="video-js vjs-default-skin vjs-big-play-centered vjs-nofull"
              controls
              preload="metadata"
              height="200"
              disablepictureinpicture
            >
              <source :src="$props.videoDetail.video_url" type="video/mp4" />
              <p class="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a web browser that
                <a href="http://videojs.com/html5-video-support/" target="_blank"> supports HTML5 video </a>
              </p>
            </video>
          </div>
          <div class="video-react-youtube" v-else>
            <div :id="'video-youtube-' + $props.videoDetail.id"></div>
            <div id="audio-player" class="audio-player-youtube">
              <div id="controls" class="d-flex controls-youtube">
                <i
                  :id="'play-' + $props.videoDetail.id"
                  class="play-youtube icon icon-video-play"
                  @click="videoPlayPause"
                ></i>
                <i
                  :id="'redo-' + $props.videoDetail.id"
                  class="redo-youtube icon icon-video-redo d-none-icon"
                  @click="videoReplay"
                ></i>
                <div class="icon-volume-youtube">
                  <i
                    :id="'mute-' + $props.videoDetail.id"
                    class="mute-youtube icon icon-video-volume-mute"
                    @mouseup="videoVolumeMute"
                  ></i>
                </div>
                <div class="custom-volume-youtube">
                  <input
                    type="range"
                    :id="'volume-' + $props.videoDetail.id"
                    class="volume-youtube"
                    value="0"
                    max="100"
                    @click="videoVolumeBar"
                    @change="changeVideoVolumeBar"
                  />
                  <div class="range-volume-youtube d-none-icon" :id="'range-volume-' + $props.videoDetail.id"></div>
                </div>
                <div class="custom-progress-youtube">
                  <input
                    type="range"
                    :id="'progress-bar-' + $props.videoDetail.id"
                    class="progress-bar-youtube"
                    value="0"
                    @change="processBar"
                    @mousedown="mousedownProcessBar"
                    @mouseup="mouseupProcessBar"
                  />
                  <div class="range-progress-youtube d-none-icon" :id="'range-progress-' + $props.videoDetail.id"></div>
                </div>
                <span :id="'time-' + $props.videoDetail.id" class="time-youtube"
                  ><span :id="'duration-' + $props.videoDetail.id" class="duration-youtube">0:00</span></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-5-col-2">
        <div class="chart-percent ml-5">
          <div class="parent-progress">
            <div class="chart video-percent-chart">
              <svg class="svg-chart-percent" v-if="interactionColor.rank_2.length && interactionColor.rank_3.length">
                <circle
                  :style="'stroke : ' + interactionColor.rank_3"
                  class="percent-chart-out"
                  cx="93"
                  cy="93"
                  r="93"
                />
                <circle
                  :style="'stroke : ' + interactionColor.rank_2"
                  class="percent-chart-active"
                  cx="93"
                  cy="93"
                  r="93"
                />
                <circle
                  :style="'stroke : ' + interactionColor.rank_3"
                  class="percent-chart-out2"
                  cx="93"
                  cy="93"
                  r="93"
                />
              </svg>
              <svg class="svg-chart-percent" v-if="!interactionColor.rank_2.length && interactionColor.rank_3.length">
                <circle
                  :style="'stroke : ' + interactionColor.rank_3"
                  class="percent-chart-out"
                  cx="93"
                  cy="93"
                  r="93"
                />
                <circle
                  :style="'stroke : ' + interactionColor.rank_3"
                  class="percent-chart-active"
                  cx="93"
                  cy="93"
                  r="93"
                />
                <circle
                  :style="'stroke : ' + interactionColor.rank_3"
                  class="percent-chart-out2"
                  cx="93"
                  cy="93"
                  r="93"
                />
              </svg>
              <svg class="svg-chart-percent" v-if="interactionColor.rank_2.length && !interactionColor.rank_3.length">
                <circle
                  :style="'stroke : ' + interactionColor.rank_2"
                  class="percent-chart-out"
                  cx="93"
                  cy="93"
                  r="93"
                />
                <circle
                  :style="'stroke : ' + interactionColor.rank_2"
                  class="percent-chart-active"
                  cx="93"
                  cy="93"
                  r="93"
                />
                <circle
                  :style="'stroke : ' + interactionColor.rank_2"
                  class="percent-chart-out2"
                  cx="93"
                  cy="93"
                  r="93"
                />
              </svg>
              <div class="chart-percent-text">
                <div class="total-reaction">
                  <div class="text">総リアクション数</div>
                  <div class="label">{{ total_interaction }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="data-video ml-10">
            <div class="good pt-3" v-if="interactionIcon.rank_2.length">
              <img style="width: 24px" :src="interactionIcon.rank_2" alt="" />
              <div class="label">{{ interactionText.rank_2 }}</div>
            </div>
            <div class="number" v-if="interactionIcon.rank_2.length">{{ total_good }}%</div>
            <div class="bad pt-3" v-if="interactionIcon.rank_3.length">
              <img style="width: 24px" :src="interactionIcon.rank_3" alt="" />
              <div class="label">{{ interactionText.rank_3 }}</div>
            </div>
            <div class="number" v-if="interactionIcon.rank_3.length">{{ total_bad }}%</div>
          </div>
        </div>
      </div>
    </div>
    <div class="report-video row-5">
      <div class="row-5-col-1">
        <div class="card card-avg-norm w-100" :class="[changeCardHeight ? 'card-2pcs-height' : 'card-3pcs-height']">
          <div class="content">
            <div class="top">
              <i class="icon icon-reaction icon-max-reaction"></i>
              <div class="label">最大リアクション値</div>
            </div>
            <div class="bottom">
              <div class="number">
                {{
                  cardTihPercentNumber == null
                    ? Number(cardTihPercentNumberDefault).toFixed(1)
                    : Number(cardTihPercentNumber).toFixed(1) | number0Hyphen
                }}%
              </div>
              <div class="item-range">|</div>
              <div class="number">
                {{ cardTihNumber == null ? cardTihNumberDefault : cardTihNumber | numberWithCommas | number0Hyphen }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="card card-avg-norm mt-2 w-100"
          :class="[changeCardHeight ? 'card-2pcs-height' : 'card-3pcs-height']"
        >
          <div class="content">
            <div class="top">
              <i class="icon icon-reaction icon-avg-reaction"></i>
              <div class="label">平均リアクション値</div>
            </div>
            <div class="bottom">
              <div class="number">
                {{
                  cardTiaPercentNumber == null
                    ? Number(cardTiaPercentNumberDefault).toFixed(1)
                    : Number(cardTiaPercentNumber).toFixed(1) | number0Hyphen
                }}%
              </div>
              <div class="item-range">|</div>
              <div class="number">
                {{ cardTiaNumber == null ? cardTiaNumberDefault : cardTiaNumber | numberWithCommas | number0Hyphen }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="card card-avg-norm w-100 mt-2"
          :class="[changeCardHeight ? 'card-2pcs-height' : 'card-3pcs-height']"
          v-if="selectChartType.chart == 'cognition' && videoDetail.cognition_survey == 1"
        >
          <div class="content">
            <div class="top">
              <i class="icon" :class="selectChartType.value === 4 ? 'icon-cognition' : 'icon-uncognition'"></i>
              <div class="label">{{ selectChartType.name }}</div>
            </div>
            <div class="bottom">
              <div class="number">{{ dataCognition ? dataCognition.recognition_value : 0 }}%</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row-5-col-4 bart-chart-area">
        <select-option
          :options="stepOptions"
          :eventName="changeStepOption"
          :chartType="chartType"
          :eventChartType="changeTypeChart"
          :labelChart="label"
          :is25percent="is25percent"
          :videoDetail="videoDetail"
          :selectChartType="selectChartType"
          @selectedLabelStack="handleSelectLabelStack"
        ></select-option>
        <div class="chart-area" v-show="selectChartType && selectChartType.chart == 'norm'">
          <div class="title-y-axis">{{ selectChartType.yAxis }}</div>
          <div class="title-x-axis">Time</div>
          <i class="icon icon-prev" v-if="prevPartEnabled" data-total-report="action" @click="prevPart"></i>
          <i class="icon icon-next" v-if="nextPartEnabled" data-total-report="action" @click="nextPart"></i>
          <chart-norm
            v-if="dataReportNorm"
            :part="part"
            :dataReport="reportVideo"
            :step="step"
            :changeStepChart="changeStepOption"
            :chartType="selectChartType"
            :dataReportNorm="dataReportNorm"
            :dataBuyerNorm="dataBuyerNorm"
            :interactionIcon="interactionIcon"
          ></chart-norm>
        </div>
        <div class="chart-area" v-show="!selectChartType || selectChartType.chart != 'norm'">
          <template v-if="!$store.getters.isLoadingApi">
            <div class="title-y-axis">{{ selectChartType.yAxis }}</div>
            <div class="title-youtube-report" v-if="youtubeAudienceRatingReport && !this.is25percent">
              <div class="lengend-column">
                <div class="item-select"></div>
                <div class="label">Youtube視聴率</div>
              </div>
              <div class="title-y-axis-right">Youtube%</div>
            </div>
            <div class="title-x-axis">Time</div>
            <i class="icon icon-prev" v-if="prevPartEnabled" data-total-report="action" @click="prevPart"></i>
            <i class="icon icon-next" v-if="nextPartEnabled" data-total-report="action" @click="nextPart"></i>
            <bar-chart
              :part="part"
              :dataReport="reportVideo"
              :step="step"
              :changeStepChart="changeStepOption"
              :seriesData="seriesData"
              :chartType="selectChartType"
              :currentTime="currentTime"
              :imageURL="image_url"
              :is25percent="is25percent"
              :labelXaxis="labelXaxis"
              :interactionType="videoDetail.interaction_type"
              :dataCognition="dataCognition"
              :youtubeRadioType="youtubeRadioType"
              :youtubeAudienceRatingReport="youtubeAudienceRatingReport"
              :project="videoDetail"
              :maxYCognition="maxYCognition"
            ></bar-chart>
          </template>
        </div>
        <div v-show="youtubeAudienceRatingRadio && !this.is25percent">
          <youtube-radio
            :youtubeAudienceRating="youtubeAudienceRating"
            v-on:click-youtube-radio="clickYoutubeRadio"
          ></youtube-radio>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import videojs_cus from './../../components/VideoJsCustom'
import BarChart from '@/components/TopDetail/BarChartSummary.vue'
import ChartNorm from '@/components/TopDetail/ChartNorm.vue'
import SelectOption from '@/components/TopDetail/SelectChartOption.vue'
import YoutubeRadio from '@/components/TopDetail/YoutubeRadio.vue'
import { stepOptionTopDetailPage } from '@/commons/charts'
import EventBus from '@/commons/event_bus'
import { numberWithCommas, roundDecimal, formatTallyResult } from '../../commons/helpers'

import {} from '../../commons/charts'
import { colorInteraction } from '@/commons/constants'

const jQuery = require('jquery')

export default {
  name: 'Video',
  props: [
    'videoDetail',
    'videoImage',
    'reportVideo',
    'chartType',
    'is25percent',
    'selectChartType',
    'dataReportCognition',
    'dataBuyerNorm',
    'dataReportNorm',
    'totalInteractionGood',
    'totalInteractionBad',
    'youtubeAudienceRating',
  ],
  components: {
    BarChart,
    SelectOption,
    ChartNorm,
    YoutubeRadio,
  },
  watch: {
    reportVideo: function () {
      this.formatNumberInteraction()
      this.formatInteractionDataToChart()
      this.setPossitionVideo()
      this.cardNumberDefault()
    },
    selectChartType: function (newValue) {
      if (newValue && newValue.chart != 'norm') {
        if (newValue.chart == 'cognition') {
          this.slectCognition = true
          this.getYmaxCognition()
        }
        this.formatInteractionDataToChart()
      }
      this.setCardHeightClass()
    },
    ratio: function () {
      this.setPossitionVideo()
    },
    ratio_bad: function () {
      this.setPossitionVideo()
    },
    totalInteractionGood: function () {
      this.formatNumberInteraction()
    },
    totalInteractionBad: function () {
      this.formatNumberInteraction()
    },
    youtubeAudienceRating: function () {
      this.setYoutubeAudienceReport()
    },
    isStackBar: function (value) {
      this.formatInteractionDataToChart()
      if (value) {
        this.getYmaxCognition()
      }
      this.part = 1
    },
    step: function () {
      this.getYmaxCognition()
    },
  },

  computed: {
    nextPartEnabled: function () {
      let max
      if (this.selectChartType.chart == 'norm') {
        max =
          this.dataReportNorm && this.dataReportNorm['total_norm_detail']
            ? this.dataReportNorm['total_norm_detail'].length - 1
            : 0
      } else {
        let seriesLength = this.seriesData.map(e => e.data.length)
        max = Math.max(...seriesLength) - 1
      }
      return max > this.part * this.step * 15
    },
    prevPartEnabled: function () {
      return this.part > 1
    },
    image_url: function () {
      return this.$props.videoImage ? this.$props.videoImage.image_url : null
    },
    interactionIcon() {
      return {
        rank_2:
          this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length
            ? this.videoDetail.interaction_btn_1.btn_icon_url
            : '',
        rank_3:
          this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length
            ? this.videoDetail.interaction_btn_2.btn_icon_url
            : '',
      }
    },
    interactionText() {
      return {
        rank_2:
          this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length
            ? this.videoDetail.interaction_btn_1.text
            : '',
        rank_3:
          this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length
            ? this.videoDetail.interaction_btn_2.text
            : '',
      }
    },
    interactionColor() {
      let color_rank_2 =
        this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length
          ? this.videoDetail.interaction_btn_1.color
          : ''
      let color_rank_3 =
        this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length
          ? this.videoDetail.interaction_btn_2.color
          : ''
      return {
        rank_2: color_rank_2 ? colorInteraction[color_rank_2].color : '',
        rank_3: color_rank_3 ? colorInteraction[color_rank_3].color : '',
      }
    },
  },
  data() {
    return {
      videoEntity: null,
      videoDuration: null,
      time_update_interval: 0,
      currentTime: 0,
      isPlayingYoutube: false,
      total_interaction: 0,
      total_good: 0,
      total_bad: 0,
      ratio: 0,
      ratio_bad: 0,
      stepOptions: stepOptionTopDetailPage,
      step: 1,
      part: 1,
      changeStepOption: 'changeStep',
      changeTypeChart: 'changeTypeChart',
      seriesData: null,
      type: 1,
      videoUrlUpload: 1,
      label: null,
      labelXaxis: null,
      dataCognition: null,
      isTentacle: false,
      labelChangeClick: null,
      maxInteraction: 0,
      avgInteraction: 0,
      changeCardHeight: false,
      cardTihNumber: null,
      cardTihPercentNumber: null,
      cardTiaNumber: null,
      cardTiaPercentNumber: null,
      cardTihNumberDefault: null,
      cardTihPercentNumberDefault: null,
      cardTiaNumberDefault: null,
      cardTiaPercentNumberDefault: null,
      youtubeAudienceRatingRadio: null,
      youtubeAudienceRatingReport: null,
      youtubeRadioType: null,
      isStackBar: true,
      slectCognition: false,
      maxYCognition: 0,
      interactionBtnColor: [],
    }
  },
  created() {
    if (!(this.dataReportCognition == null)) {
      this.getYmaxCognition()
    }
    this.setLabelClick()
    this.formatInteractionDataToChart()
    this.getInteractionBtnColor()
    EventBus.$on('changeStep', this.handleChangeStepChart)
    EventBus.$on('changeTypeChart', this.handleChangeTypeChart)
    EventBus.$on('changeCurrentTime', this.handleCurrentTime)
    EventBus.$on('changePart', this.handleChangePart)
    EventBus.$on('selectLabel', this.selectLabel)
    EventBus.$on('setMaAvgInteraction', this.setMaAvgInteraction)
    EventBus.$on('selectChartNorm', this.selectLabelNorm)
  },
  mounted() {
    if (this.$props.videoDetail.video_url_type && this.$props.videoDetail.video_url_type == this.videoUrlUpload) {
      this.videoUpload()
    } else {
      this.videoYoutube()
    }
    this.setPossitionVideo()
    if (this.selectChartType && this.selectChartType.chart != 'norm') {
      this.formatInteractionDataToChart()
    }
    this.formatNumberInteraction()
    this.setPossitionVideo()
    this.setCardHeightClass()
    this.setYoutubeAudienceReport()
    this.cardNumberDefault()
  },
  beforeDestroy() {
    EventBus.$off('changeStep', this.handleChangeStepChart)
    EventBus.$off('changeTypeChart', this.handleChangeTypeChart)
    EventBus.$off('changeCurrentTime', this.handleCurrentTime)
    EventBus.$off('changePart', this.handleChangePart)
    EventBus.$on('selectLabel', this.selectLabel)
    EventBus.$off('setMaAvgInteraction', this.setMaAvgInteraction)
  },
  methods: {
    handleSelectLabelStack(isStack) {
      this.isStackBar = isStack
      this.$emit('selectedLabelStack', isStack)
      EventBus.$emit('selectedLabelStack', isStack)
    },
    handleChangeTypeChart() {
      this.part = 1
    },
    handleChangeStepChart(step) {
      this.step = step
      this.part = 1
    },
    nextPart() {
      this.part += 1
    },
    prevPart() {
      this.part -= 1
    },
    setPossitionVideo() {
      let videoDetail = jQuery('.video-detail')
      let width = videoDetail.width()
      let height = (width * 9) / 16
      if (height > 800) {
        height = 800
        let widthVideo = (height * 16) / 9
        videoDetail.width(widthVideo)
      }
      videoDetail.height(height)
      let highChart = (height * 50) / 100
      if (highChart < 186) {
        highChart = 186
      }
      let strokeWidth = (highChart * 20) / 186
      let circumference = Math.PI * highChart
      let strokeDasharrayOut = circumference
      let strokeDashoffsetOut = -(circumference * this.ratio) / 100
      let strokeDasharrayActive = circumference
      let strokeDashoffsetActive = (circumference * this.ratio_bad) / 100
      let strokeDasharrayOut2 = circumference
      let strokeDashoffsetOut2 = -(circumference * this.ratio) / 100
      jQuery('.chart circle').css('stroke-width', strokeWidth + 'px')
      jQuery('.video-percent-chart').width(highChart).height(highChart)
      jQuery('.percent-chart-out').removeAttr('cx cy r')
      jQuery('.percent-chart-out').attr('cx', highChart / 2)
      jQuery('.percent-chart-out').attr('cy', highChart / 2)
      jQuery('.percent-chart-out').attr('r', highChart / 2)
      jQuery('.percent-chart-out').css('stroke-dasharray', strokeDasharrayOut + ' ' + strokeDasharrayOut)
      jQuery('.percent-chart-out').css('stroke-dashoffset', strokeDashoffsetOut)
      jQuery('.percent-chart-active').removeAttr('cx cy r')
      jQuery('.percent-chart-active').attr('cx', highChart / 2)
      jQuery('.percent-chart-active').attr('cy', highChart / 2)
      jQuery('.percent-chart-active').attr('r', highChart / 2)
      jQuery('.percent-chart-active').css('stroke-dasharray', strokeDasharrayActive)
      jQuery('.percent-chart-active').css('stroke-dashoffset', strokeDashoffsetActive)
      jQuery('.percent-chart-out2').removeAttr('cx cy r')
      jQuery('.percent-chart-out2').attr('cx', highChart / 2)
      jQuery('.percent-chart-out2').attr('cy', highChart / 2)
      jQuery('.percent-chart-out2').attr('r', highChart / 2)
      jQuery('.percent-chart-out2').css('stroke-dasharray', 5 + ' ' + strokeDasharrayOut2)
      jQuery('.percent-chart-out2').css('stroke-dashoffset', strokeDashoffsetOut2)
    },
    setPauseVideo() {
      if (this.videoEntity && this.$props.videoDetail.video_url_type == this.videoUrlUpload) {
        if ((!this.videoEntity.paused() && !this.videoEntity.ended()) || this.videoEntity.seeking()) {
          this.videoEntity.pause()
        }
      } else {
        if (this.videoEntity.getPlayerState() == 1) {
          this.videoEntity.pauseVideo()
        }
      }
    },
    cardNumberDefault() {
      let data = this.$props.reportVideo
      if (Object.keys(data).length) {
        this.cardTihNumberDefault = data.tih.total
        this.cardTihPercentNumberDefault = data.tih_percent.total
        this.cardTiaNumberDefault = data.tia.total
        this.cardTiaPercentNumberDefault = data.tia_percent.total
      }
    },
    setLabelClick() {
      let label = {
        left: this.interactionText.rank_2,
        right: this.interactionText.rank_3,
      }
      this.labelChangeClick = {
        left: {
          name: null,
          visible: true,
        },
        right: {
          name: null,
          visible: true,
        },
      }
      if (label) {
        this.labelChangeClick.left.name = label.left
        this.labelChangeClick.right.name = label.right
      }
    },
    selectLabel(labelChart, visible) {
      for (let index in this.labelChangeClick) {
        if (this.labelChangeClick[index].name == labelChart) {
          this.labelChangeClick[index].visible = visible
        }
      }
      let leftLabel = this.labelChangeClick.left.visible
      let rightLabel = this.labelChangeClick.right.visible
      if (leftLabel && rightLabel) {
        this.cardTihNumber = this.$props.reportVideo.tih.total
        this.cardTihPercentNumber = this.$props.reportVideo.tih_percent.total
        this.cardTiaNumber = this.$props.reportVideo.tia.total
        this.cardTiaPercentNumber = this.$props.reportVideo.tia_percent.total
      } else if (leftLabel && !rightLabel) {
        this.cardTihNumber = this.$props.reportVideo.tih?.left
        this.cardTihPercentNumber = this.$props.reportVideo.tih_percent.left
        this.cardTiaNumber = this.$props.reportVideo.tia.left
        this.cardTiaPercentNumber = this.$props.reportVideo.tia_percent.left
      } else if (!leftLabel && rightLabel) {
        this.cardTihNumber = this.$props.reportVideo.tih.right
        this.cardTihPercentNumber = this.$props.reportVideo.tih_percent.right
        this.cardTiaNumber = this.$props.reportVideo.tia.right
        this.cardTiaPercentNumber = this.$props.reportVideo.tia_percent.right
      } else {
        this.cardTihNumber = 0
        this.cardTihPercentNumber = 0
        this.cardTiaNumber = 0
        this.cardTiaPercentNumber = 0
      }

      // update visible chart series
      let vm = this
      vm.seriesData.forEach((data, index) => {
        if (data?.name === labelChart) {
          data.visible = visible
          vm.seriesData[index] = data
        }
      })
    },
    selectLabelNorm(labelChartNorm) {
      if (Object.keys(this.$props.reportVideo).length) {
        if (labelChartNorm == 0) {
          this.cardTihNumber = this.$props.reportVideo.tih.total
          this.cardTihPercentNumber = this.$props.reportVideo.tih_percent.total
          this.cardTiaNumber = this.$props.reportVideo.tia.total
          this.cardTiaPercentNumber = this.$props.reportVideo.tia_percent.total
        } else if (labelChartNorm == 1) {
          this.cardTihNumber = this.$props.reportVideo.tih.left
          this.cardTihPercentNumber = this.$props.reportVideo.tih_percent.left
          this.cardTiaNumber = this.$props.reportVideo.tia.left
          this.cardTiaPercentNumber = this.$props.reportVideo.tia_percent.left
        } else if (labelChartNorm == 2) {
          this.cardTihNumber = this.$props.reportVideo.tih.right
          this.cardTihPercentNumber = this.$props.reportVideo.tih_percent.right
          this.cardTiaNumber = this.$props.reportVideo.tia.right
          this.cardTiaPercentNumber = this.$props.reportVideo.tia_percent.right
        }
      }
    },
    formatInteractionDataToChart() {
      let dataSet1 = []
      let dataSet2 = []
      if (this.selectChartType.chart == 'summary') {
        if (this.$props.reportVideo !== null && Object.keys(this.$props.reportVideo).length > 0) {
          let sum_data = []
          if (this.is25percent) {
            let labelXaxis = []
            this.$props.reportVideo.tally_result2 = formatTallyResult(
              this.reportVideo.tally_result,
              this.videoImage.duration,
            )
            for (let reports of this.$props.reportVideo.tally_result2) {
              let xAxis = {}
              xAxis['start'] = reports.start
              xAxis['end'] = reports.end
              let right_data = reports.right
              dataSet1.push(reports.left)
              if (this.isStackBar) {
                right_data = Math.abs(reports.right)
              }
              dataSet2.push(right_data)
              sum_data.push(Math.abs(reports.left) + Math.abs(reports.right))
              labelXaxis.push(xAxis)
            }
            this.labelXaxis = labelXaxis
          } else {
            let data = this.$props.reportVideo
            let report_data = data.tally_result ? data.tally_result : []
            if (report_data.length) {
              for (let reports of report_data) {
                let right_data = reports.right
                if (this.isStackBar) {
                  right_data = Math.abs(reports.right)
                }
                dataSet1.push(reports.left)
                dataSet2.push(right_data)
              }
            }
          }
        }
      } else {
        if (this.dataReportCognition !== null && Object.keys(this.dataReportCognition).length > 0) {
          this.dataCognition =
            this.selectChartType.value == 4 ? this.$props.dataReportCognition[0] : this.$props.dataReportCognition[1]
          let report_data = this.dataCognition.tally_result ? this.dataCognition.tally_result : []
          if (report_data.length) {
            for (let reports of report_data) {
              let right_data = reports.right
              if (this.isStackBar) {
                right_data = Math.abs(reports.right)
              } else if (this.slectCognition && Math.sign(right_data) !== -1) {
                right_data = -reports.right
              }
              dataSet1.push(reports.left)
              dataSet2.push(right_data)
            }
          }
          this.slectCognition = false
        }
      }
      this.setSeriesData(dataSet1, dataSet2)
    },
    setSeriesData(dataSet1, dataSet2) {
      let seriesData = []
      this.label = {
        left: this.interactionText.rank_2,
        right: this.interactionText.rank_3,
      }
      let dataLeft = {}
      let dataRight = {}
      if (!this.isStackBar) {
        dataLeft['border'] = 'top'
        dataRight['border'] = 'bottom'
      } else {
        dataLeft['border'] = 'top'
        dataRight['border'] = 'none'
      }
      dataLeft['color'] = this.interactionColor.rank_2
      dataLeft['name'] = this.labelChangeClick.left.name
      dataLeft['visible'] = this.labelChangeClick.left.visible
      dataRight['color'] = this.interactionColor.rank_3
      dataRight['name'] = this.labelChangeClick.right.name
      dataRight['visible'] = this.labelChangeClick.right.visible
      if (this.label.left) {
        dataLeft['data'] = dataSet1
      } else {
        dataLeft['data'] = []
      }
      if (this.label.right) {
        dataRight['data'] = dataSet2
      } else {
        dataRight['data'] = []
      }
      seriesData.push(dataLeft)
      seriesData.push(dataRight)
      this.seriesData = seriesData
    },
    getInteractionBtnColor() {
      this.interactionBtnColor.push(this.interactionColor.rank_2, this.interactionColor.rank_3)
      this.$store.commit('setInteractionColor', this.interactionBtnColor)
    },
    formatNumberInteraction() {
      if (this.$props.reportVideo !== null && Object.keys(this.$props.reportVideo).length > 0) {
        let total_good = this.$props.totalInteractionGood
        let total_bad = this.$props.totalInteractionBad
        let total_interaction = roundDecimal(total_good + total_bad, 1)
        let total_good_per = Math.round((total_good / total_interaction) * 100 * 10) / 10
        let total_bad_per = Math.round((total_bad / total_interaction) * 100 * 10) / 10
        this.total_interaction = numberWithCommas(total_interaction)
        if (total_good_per && Number.isInteger(total_good_per)) {
          this.total_good = total_good_per + '.0'
        } else if (total_good_per) {
          this.total_good = total_good_per
        } else {
          this.total_good = 0
        }
        if (total_bad_per && Number.isInteger(total_bad_per)) {
          this.total_bad = total_bad_per + '.0'
        } else if (total_bad_per) {
          this.total_bad = total_bad_per
        } else {
          this.total_bad = 0
        }
        let ratio = 0
        let ratio_bad = 0
        let total = total_good + total_bad
        if (total > 0) {
          ratio = (total_good / (total_good + total_bad)) * 100
          ratio_bad = (total_bad / (total_good + total_bad)) * 100
        }
        if (this.$props.totalInteractionGood && this.$props.totalInteractionBad) {
          if (ratio == 0) {
            jQuery('.chart circle:nth-child(2)').css('stroke', '#EBEBEB')
          }
          if (ratio_bad == 0) {
            jQuery('.chart circle:nth-child(3)').css('stroke', '#EBEBEB')
          }
        }
        this.ratio = roundDecimal(ratio, 0)
        this.ratio_bad = roundDecimal(ratio_bad, 0)
      }
    },
    handleCurrentTime(currentTime) {
      if (this.$props.videoDetail.video_url_type && this.$props.videoDetail.video_url_type == this.videoUrlUpload) {
        this.videoEntity.currentTime(currentTime)
        this.videoEntity.pause()
      } else {
        clearInterval(this.time_update_interval)
        this.videoEntity.seekTo(currentTime)
        if (this.videoEntity.getPlayerState() == 5 || this.videoEntity.getPlayerState() == -1) {
          this.videoEntity.cueVideoById({
            videoId: this.videoEntity.getVideoData().video_id,
            startSeconds: currentTime,
          })
          this.videoEntity.pauseVideo()
        }
        this.currentTime = currentTime
        this.videoEntity.pauseVideo()
        this.updateProgressBar(currentTime, this.videoDuration)
      }
    },
    handleChangePart(part) {
      this.part = part
    },
    setMaAvgInteraction(dataMax, dataAvg) {
      this.maxInteraction = dataMax
      this.avgInteraction = dataAvg
    },
    videoUpload() {
      let vm = this
      this.videoEntity = videojs_cus(this.$refs[this.videoDetail.id], {
        language: 'ja',
        controlBar: {
          fullscreenToggle: false,
        },
      })
      this.videoEntity.ready(function () {
        this.tech_.off('dblclick')
      })
      this.videoEntity.on('play', function () {
        vm.time_update_interval = setInterval(() => {
          vm.currentTime = this.currentTime()
        }, 0)
      })
      this.videoEntity.on('error', function () {
        vm.hasSegment = false
        if (this.error().code === 3) {
          vm.videoEntity.error(null)
          vm.videoEntity.load()
        }
      })
      this.videoEntity.on('pause', function () {
        clearInterval(vm.time_update_interval)
      })
      this.videoEntity.on('seeked', function () {
        vm.currentTime = this.currentTime()
      })
    },
    videoYoutube() {
      let vm = this
      jQuery
        .getScript('https://www.youtube.com/iframe_api')
        .fail(function () {
          var tag = document.createElement('script')
          tag.src = 'https://www.youtube.com/iframe_api'
          var firstScriptTag = document.getElementsByTagName('script')[0]
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
          vm.onYouTubePlayer()
        })
        .done(function () {
          vm.onYouTubePlayer()
        })
    },
    onYouTubePlayer() {
      let video_url = this.$props.videoDetail.video_url
      let wrapper_video_id = 'video-youtube-' + this.$props.videoDetail.id,
        youtube_video_id = video_url
      this.videoEntity = new window.YT.Player(wrapper_video_id, {
        height: '100%',
        width: '100%',
        videoId: youtube_video_id,
        playerVars: {
          rel: 0,
          controls: 0,
          disablekb: 1,
          showinfo: 0,
          hl: 'ja',
          autoplay: 0,
          fs: 0,
          playsinline: 1,
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange,
        },
      })
    },
    onPlayerReady(event) {
      let playerVolume = event.target.getVolume()
      jQuery('#volume-' + this.$props.videoDetail.id).attr('value', playerVolume)
      this.setIconVolume(playerVolume)
      let timeDuration = event.target.getDuration() - 1
      jQuery('#duration-' + this.$props.videoDetail.id).text(this.formatTime(timeDuration))
      let vm = this
      vm.videoDuration = Math.floor(timeDuration)
      this.updateProgressBar()
      this.changeVideoVolumeBar()
      jQuery('#range-volume-' + this.$props.videoDetail.id).toggleClass('d-none-icon')
      jQuery('#range-progress-' + this.$props.videoDetail.id).toggleClass('d-none-icon')
      clearInterval(this.time_update_interval)
    },
    onPlayerStateChange(event) {
      // 0: end 1: playing 2: pause
      switch (event.data) {
        case 0:
          clearInterval(this.time_update_interval)
          jQuery('#play-' + this.$props.videoDetail.id).addClass('d-none-icon')
          jQuery('#redo-' + this.$props.videoDetail.id).toggleClass('d-none-icon')
          break
        case 1:
          clearInterval(this.time_update_interval)
          this.time_update_interval = setInterval(() => {
            this.currentTime = event.target.getCurrentTime()
            this.updateProgressBar()
          }, 0)
          this.isPlayingYoutube = true
          this.checkClassElement()
          break
        case 2:
          this.currentTime = event.target.getCurrentTime()
          clearInterval(this.time_update_interval)
          this.isPlayingYoutube = false
          this.checkClassElement()
          break
      }
    },
    checkClassElement() {
      let playButton = jQuery('#play-' + this.$props.videoDetail.id)
      let redoButton = jQuery('#redo-' + this.$props.videoDetail.id)
      if (playButton.hasClass('d-none-icon') && !redoButton.hasClass('d-none-icon')) {
        playButton.toggleClass('d-none-icon')
        redoButton.addClass('d-none-icon')
      }
      if (this.isPlayingYoutube) {
        playButton.removeClass('icon-video-play')
        playButton.addClass('icon-video-pause')
      } else {
        playButton.removeClass('icon-video-pause')
        playButton.addClass('icon-video-play')
      }
    },
    videoPlayPause() {
      !this.isPlayingYoutube ? this.videoEntity.playVideo() : this.videoEntity.pauseVideo()
    },
    videoReplay() {
      jQuery('#redo-' + this.$props.videoDetail.id).addClass('d-none-icon')
      jQuery('#play-' + this.$props.videoDetail.id).toggleClass('d-none-icon')
      this.videoEntity.playVideo()
    },
    videoVolumeMute() {
      let volumnButton = jQuery('#volume-' + this.$props.videoDetail.id)
      if (this.videoEntity.isMuted()) {
        this.videoEntity.unMute()
        volumnButton.val(this.videoEntity.getVolume())
        this.setIconVolume(this.videoEntity.getVolume())
      } else {
        this.videoEntity.mute()
        volumnButton.val(0)
        this.setIconVolume(0)
      }
      this.changeVideoVolumeBar()
    },
    videoVolumeBar(e) {
      let volumnButton = jQuery('#volume-' + this.$props.videoDetail.id)
      var progress = parseInt(e.pageX - volumnButton.offset().left),
        max = parseInt(e.target.max),
        offsetWidth = parseInt(e.target.offsetWidth)
      let value = (progress * max) / offsetWidth
      volumnButton.val(value)
      this.videoEntity.setVolume(value)
      this.setIconVolume(value)
    },
    changeVideoVolumeBar() {
      let widthVolume = jQuery('#volume-' + this.$props.videoDetail.id).val()
      jQuery('#range-volume-' + this.$props.videoDetail.id).width(widthVolume + '%')
    },
    setIconVolume(param) {
      let muteButton = jQuery('#mute-' + this.$props.videoDetail.id)
      // custom volume
      muteButton.removeClass(function () {
        return jQuery(this).attr('class')
      })
      if (param <= 0) {
        muteButton.addClass('mute-youtube icon icon-video-volume-mute')
      }
      if (param > 60) {
        muteButton.addClass('mute-youtube icon icon-video-volume-up')
      }
      if (param > 20 && param <= 60) {
        muteButton.addClass('mute-youtube icon icon-video-volume-down')
      }
      if (param > 0 && param <= 20) {
        muteButton.addClass('mute-youtube icon icon-video-volume-off')
      }
    },
    processBar(e) {
      clearInterval(this.time_update_interval)
      // Calculate the new time for the video.
      // new time in seconds = total duration in seconds * ( value of range input / 100 )
      var newTime = this.videoDuration * (e.target.value / 100)
      // Skip video to new time.
      this.videoEntity.seekTo(newTime)
      if (this.videoEntity.getPlayerState() == 5 || this.videoEntity.getPlayerState() == -1) {
        this.videoEntity.cueVideoById({ videoId: this.videoEntity.getVideoData().video_id, startSeconds: newTime })
        this.videoEntity.pauseVideo()
      }
      this.currentTime = newTime
      this.updateProgressBar(newTime, this.videoDuration)
    },
    updateProgressBar(currentTime = 0, duration = 0) {
      let value
      if (currentTime != 0 && duration != 0) {
        value = (currentTime / duration) * 100
      } else {
        value =
          this.videoEntity && !this.videoEntity.getCurrentTime() ? 0 : (this.currentTime / this.videoDuration) * 100
      }
      jQuery('#progress-bar-' + this.$props.videoDetail.id).val(value)
      jQuery('#range-progress-' + this.$props.videoDetail.id).width(value + '%')
    },
    mousedownProcessBar() {
      clearInterval(this.time_update_interval)
      if (this.videoEntity.getPlayerState() == 1) {
        this.isTentacle = true
      } else {
        this.isTentacle = false
      }
      this.videoEntity.pauseVideo()
    },
    mouseupProcessBar() {
      if (this.isTentacle) {
        this.videoEntity.playVideo()
        jQuery('#play-' + this.$props.videoDetail.id).toggleClass('icon-video-play')
        jQuery('#play-' + this.$props.videoDetail.id).addClass('icon-video-pause')
      }
    },
    formatTime(time) {
      time = Math.floor(time)
      var minutes = Math.floor(time / 60),
        seconds = time - minutes * 60
      seconds = seconds < 10 ? '0' + seconds : seconds
      return minutes + ':' + seconds
    },
    setCardHeightClass() {
      let selectTypeName = this.selectChartType.name
      let typeArray = ['Reaction', 'Percentage', '25%', '平均値比較']
      if (typeArray.includes(selectTypeName)) {
        this.changeCardHeight = true
      } else {
        this.changeCardHeight = false
      }
    },
    setYoutubeAudienceReport() {
      if (Object.keys(this.$props.youtubeAudienceRating).length > 0) {
        let youtubeAdinstreamLength = this.$props.youtubeAudienceRating.ad_instream.length
        let youtubeOrganicLength = this.$props.youtubeAudienceRating.organic.length
        if (youtubeAdinstreamLength == 0 && youtubeOrganicLength == 0) {
          this.youtubeAudienceRatingRadio = false
          this.youtubeAudienceRatingReport = null
        } else if (youtubeAdinstreamLength !== 0 && youtubeOrganicLength !== 0) {
          this.youtubeAudienceRatingRadio = true
          this.youtubeAudienceRatingReport = this.$props.youtubeAudienceRating.organic
        } else if (youtubeAdinstreamLength == 0 && youtubeOrganicLength !== 0) {
          this.youtubeAudienceRatingReport = this.$props.youtubeAudienceRating.organic
        } else if (youtubeAdinstreamLength !== 0 && youtubeOrganicLength == 0) {
          this.youtubeAudienceRatingReport = this.$props.youtubeAudienceRating.ad_instream
        }
      }
    },
    clickYoutubeRadio(youtubeReportType) {
      if (youtubeReportType == 'organic') {
        this.youtubeRadioType = 'organic'
        this.youtubeAudienceRatingReport = this.$props.youtubeAudienceRating.organic
      } else if (youtubeReportType == 'ad_instream') {
        this.youtubeRadioType = 'ad_instream'
        this.youtubeAudienceRatingReport = this.$props.youtubeAudienceRating.ad_instream
      } else {
        this.youtubeRadioType = null
      }
    },
    getYmaxCognition() {
      if (!(this.dataReportCognition == null)) {
        let maxLeft = 0
        let maxRight = 0
        let maxTotal = 0
        let reportTallyResult = [this.dataReportCognition[0].tally_result, this.dataReportCognition[1].tally_result]
        let flg = true
        let x = 0
        let y = 0
        while (flg) {
          if (reportTallyResult[0].length > y && !(reportTallyResult.length <= x)) {
            let leftVal
            let rightVal
            if (!(reportTallyResult[x][y] == undefined)) {
              leftVal = reportTallyResult[x][y].left
              rightVal = reportTallyResult[x][y].right
              if (Math.sign(rightVal) == -1) {
                rightVal = Math.abs(reportTallyResult[x][y].right)
              }
            }
            if (this.step == 1) {
              if (leftVal > maxLeft) {
                maxLeft = leftVal
              }
              if (rightVal > maxRight) {
                maxRight = rightVal
              }
              let totalVal = maxLeft + maxRight
              if (totalVal > maxTotal) {
                maxTotal = totalVal
              }
              y = y + 1
            } else if (this.step == 2) {
              let leftNextValue
              let rightNextValue
              if (!(reportTallyResult[x][y + 1] == undefined)) {
                leftNextValue = reportTallyResult[x][y + 1].left
                rightNextValue = reportTallyResult[x][y + 1].right
                if (Math.sign(rightNextValue) == -1) {
                  rightNextValue = Math.abs(reportTallyResult[x][y + 1].right)
                }
              } else {
                leftNextValue = 0
                rightNextValue = 0
              }
              let leftValueSum = leftVal + leftNextValue
              let rightValueSum = rightVal + rightNextValue
              if (leftValueSum > maxLeft) {
                maxLeft = leftValueSum
              }
              if (rightValueSum > maxRight) {
                maxRight = rightValueSum
              }
              let totalVal = maxLeft + maxRight
              if (totalVal > maxTotal) {
                maxTotal = totalVal
              }
              y = y + 2
            } else if (this.step == 4) {
              let leftNextValue
              let rightNextValue
              let leftThirdValue
              let rightThirdValue
              let leftfourthValue
              let rightfourthValue
              if (!(reportTallyResult[x][y + 3] == undefined)) {
                leftNextValue = reportTallyResult[x][y + 1].left
                rightNextValue = reportTallyResult[x][y + 1].right
                leftThirdValue = reportTallyResult[x][y + 2].left
                rightThirdValue = reportTallyResult[x][y + 2].right
                leftfourthValue = reportTallyResult[x][y + 3].left
                rightfourthValue = reportTallyResult[x][y + 3].right
                if (Math.sign(rightNextValue) == -1) {
                  rightNextValue = Math.abs(reportTallyResult[x][y + 1].right)
                  rightThirdValue = Math.abs(reportTallyResult[x][y + 2].right)
                  rightfourthValue = Math.abs(reportTallyResult[x][y + 3].right)
                }
              } else if (!(reportTallyResult[x][y + 2] == undefined)) {
                leftNextValue = reportTallyResult[x][y + 1].left
                rightNextValue = reportTallyResult[x][y + 1].right
                leftThirdValue = reportTallyResult[x][y + 2].left
                rightThirdValue = reportTallyResult[x][y + 2].right
                leftfourthValue = 0
                rightfourthValue = 0
                if (Math.sign(rightNextValue) == -1) {
                  rightNextValue = Math.abs(reportTallyResult[x][y + 1].right)
                  rightThirdValue = Math.abs(reportTallyResult[x][y + 2].right)
                }
              } else if (!(reportTallyResult[x][y + 1] == undefined)) {
                leftNextValue = reportTallyResult[x][y + 1].left
                rightNextValue = reportTallyResult[x][y + 1].right
                leftThirdValue = 0
                rightThirdValue = 0
                leftfourthValue = 0
                rightfourthValue = 0
                if (Math.sign(rightNextValue) == -1) {
                  rightNextValue = Math.abs(reportTallyResult[x][y + 1].right)
                }
              } else {
                leftNextValue = 0
                rightNextValue = 0
                leftThirdValue = 0
                rightThirdValue = 0
                leftfourthValue = 0
                rightfourthValue = 0
              }
              let leftValueSum = leftVal + leftNextValue + leftThirdValue + leftfourthValue
              let rightValueSum = rightVal + rightNextValue + rightThirdValue + rightfourthValue
              if (leftValueSum > maxLeft) {
                maxLeft = leftValueSum
              }
              if (rightValueSum > maxRight) {
                maxRight = rightValueSum
              }
              let totalVal = maxLeft + maxRight
              if (totalVal > maxTotal) {
                maxTotal = totalVal
              }
              y = y + 4
            }
          } else if (reportTallyResult[0].length <= y && !(reportTallyResult.length < x)) {
            y = 0
            x = x + 1
          } else if (reportTallyResult.length <= x) {
            this.maxYCognition = maxTotal
            this.stackBarCheck = false
            flg = false
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variable';
.video {
  display: flex;
  .video-detail {
    position: relative;
    .video-image {
      width: 100%;
      height: 100%;
    }
    .video-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      padding: 21px;
      background-color: $white;
      border-radius: 50%;
      cursor: pointer;
      .icon-bg {
        display: flex;
        width: 38px;
        height: 38px;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
  .chart-percent {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    .parent-progress {
      display: flex;
      align-items: center;
      justify-content: center;
      .chart {
        text-align: center;
        position: relative;
        width: 186px;
        height: 186px;
        .chart-percent-text {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            font-weight: bold;
            margin: 15px 0;
          }
          .text {
            font-size: 16px;
          }
          .label {
            font-size: 32px;
          }
        }
        svg {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          overflow: initial;
          transform: rotate(-90deg);
        }
        circle {
          stroke-width: 20px;
          fill: none;
          &:nth-child(1) {
            position: relative;
            z-index: 2;
            stroke-dashoffset: -1;
            stroke-linecap: round;
            transform-origin: center;
          }
          &:nth-child(2) {
            position: relative;
            z-index: 1;
            stroke-dasharray: calc(100 * 6);
            stroke-dashoffset: calc((100 * 6) - ((100 * 6) * 50) / 100);
            stroke-linecap: round;
            transform-origin: center;
          }
          &:nth-child(3) {
            position: relative;
            z-index: 2;
            stroke-dashoffset: -1;
            stroke-linecap: round;
            transform-origin: center;
          }
        }
      }
    }
    .data-video {
      .interaction {
        display: flex;
      }
      .good {
        display: flex;
      }
      .bad {
        display: flex;
      }
      .label {
        @include font(s);
        padding-left: 8px;
        line-height: 24px;
        font-weight: bold;
      }
      .number {
        @include font(xxl);
        font-weight: bold;
        padding: 4px;
        text-align: left;
      }
    }
  }
}
.report-video {
  padding-top: 20px;
  .bart-chart-area {
    .chart-area {
      position: relative;
      @include font(xs);
      font-weight: bold;
      height: 310px;
      .title-y-axis {
        position: absolute;
        top: 0;
        left: 0;
        color: $middle-black;
        z-index: 1;
      }
      .title-x-axis {
        position: absolute;
        right: 4px;
        bottom: 40px;
        color: $middle-black;
        z-index: 1;
      }
      .title-youtube-report {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        display: flex;
        .lengend-column {
          color: $green;
          display: flex;
          align-items: center;
          .item-select {
            height: 7px;
            width: 7px;
            border-radius: 100%;
            border: 2px solid $middle-black;
            border-color: $green;
          }
          .label {
            padding-left: 4px;
          }
        }
        .title-y-axis-right {
          color: #999;
          margin-left: 12px;
          font-weight: 700;
        }
      }
      .icon-next {
        position: absolute;
        right: 4px;
        bottom: 0px;
        z-index: 1;
        cursor: pointer;
      }
      .icon-prev {
        position: absolute;
        left: 4px;
        bottom: 0px;
        z-index: 1;
        cursor: pointer;
      }
      #chart-container {
        padding-top: 16px;
        //     .bar-chart-legend {
        //         line-height: 28px;
        //         top: 0px!important;
        //         left: 420px!important;
        //     }
      }
      .x-column-25 {
        top: 18px !important;
        .label-x {
          display: flex;
          justify-content: space-between;
          width: 136px;
          color: $middle-black;
          @include font(s);
        }
      }
      .label-youtube {
        margin-top: 16px;
      }
    }
    .loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 3px solid #49ab94;
      border-radius: 50%;
      border-top: 2px solid #ffffff;
      width: 36px;
      height: 36px;
      -webkit-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
      margin: auto;
    }
  }
}
.video-react-upload {
  height: 100%;
  width: 100%;
}
.video-js {
  // height: 355px !important;
  height: 100% !important;
  width: 100%;
}
.video-react-youtube {
  // height: 355px !important;
  height: 100% !important;
  position: relative;
  .audio-player-youtube {
    position: absolute;
    height: 40px;
    overflow: hidden;
    color: white;
    z-index: 2;
    bottom: 0;
    width: 100%;
    .controls-youtube {
      height: 40px;
      display: flex;
      background-color: rgba(43, 51, 63, 0.7);
      width: 100%;
      .play-youtube,
      .mute-youtube,
      .redo-youtube {
        font-size: 13px;
        /* position: relative; */
        margin-top: 11px;
        cursor: pointer;
        &.d-none-icon {
          display: none;
        }
      }
      .play-youtube,
      .redo-youtube {
        margin-left: 8px;
      }
      .icon-volume-youtube {
        width: 20px;
        display: flex;
        margin-left: 8px;
      }
      .custom-volume-youtube {
        position: relative;
        width: 45px;
        margin-top: 16px;
        margin-left: 2px;
      }
      .volume-youtube {
        width: 45px;
        -webkit-appearance: none;
        height: 3px;
        background: #e6e6e647;
        outline: none;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        cursor: pointer;
        position: absolute;
      }
      .range-volume-youtube {
        height: 3px;
        background-color: white;
        margin: 2px;
        &.d-none-icon {
          display: none;
        }
      }
      .time-youtube {
        font-size: 12px;
        position: absolute;
        top: 12px;
        cursor: pointer;
        margin-left: 3px;
        right: 5px;
      }
      .custom-progress-youtube {
        position: relative;
        margin-top: 16px;
        width: 110px;
        margin-left: 10px;
      }
      .progress-bar-youtube {
        -webkit-appearance: none;
        height: 3px;
        background: #e6e6e647;
        outline: none;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        cursor: pointer;
        width: 100%;
        position: absolute;
      }
      .range-progress-youtube {
        height: 3px;
        background-color: white;
        margin: 2px;
        &.d-none-icon {
          display: none;
        }
      }
      input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 10px;
        height: 10px;
        background: #ffffff;
        cursor: pointer;
        border-radius: 4px;
        opacity: 1;
      }
      input[type='range']::-moz-range-thumb {
        width: 10px;
        height: 10px;
        background-color: #ffffff;
        cursor: pointer;
      }
      @media (max-width: 1024px) {
        .custom-progress-youtube {
          width: 90px;
        }
      }
      @media (max-width: 900px) {
        .custom-volume-youtube {
          display: none;
        }
      }
    }
  }
}
</style>
