<template>
    <div class="comment-summary-wrap">
        <div class="inner">
            <div v-for="(item, index) in commentSummary" :key="index" class="comment-card">
                <div v-if="item.type == 1 || item.type == 3" class="top">
                    <div class="top-inner">
                        <p class="point-name">ポジティブポイント</p>
                        <p v-if="item.type == 1" class="reaction">リアクション率：上位10%</p>
                        <p v-else class="reaction">リアクション率：下位10%</p>
                    </div>
                    <div class="icon-box">  
                        <i class="icon icon-gpt"></i>
                    </div>
                </div>
                <div v-else class="top">
                    <div class="top-inner">
                        <p class="point-name">ネガティブポイント</p>
                        <p v-if="item.type == 2" class="reaction">リアクション率：上位10%</p>
                        <p v-else class="reaction">リアクション率：下位10%</p>
                    </div>
                    <div class="icon-box">
                        <i class="icon icon-gpt"></i>
                    </div>
                </div>
                <div class="bottom mt-3">
                    {{ item.summarization }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  props: [
    'commentSummary'
  ],
}
</script>
<style lang="scss" scoped>
.comment-summary-wrap{
    p{
        margin: 0;
    }
    i{
        background-repeat: no-repeat;
    }
    .inner{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px 10px;
        margin-top: 20px;
        .comment-card{
            box-sizing: border-box;
            height: 185px;
            width: 49%;
            border: 1px solid #EBEBEB;
            border-radius: 10px;
            font-size: 12px;
            padding: 2%;
            overflow-y: auto;
            .top{
                display: flex;
                align-items: center;
                position: relative;
                .top-inner{
                    display: flex;
                    align-items: center;
                    max-width: 60%;
                    gap: 10px;
                    flex-wrap: wrap;
                }
                .point-name{
                    font-weight: bold;
                    font-size: 16px;
                }
                .reaction{
                    color: #999999;
                    font-size: 12px;
                }
                .icon-box{
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    -webkit-transform: translate(0, -50%);
                    -ms-transform: translate(0, -50%);
                }
            }
        }
        @media screen and (max-width: 1512px) {
            .comment-card{
                .top{
                    .point-name{
                        font-size: 14px;
                    }
                    .reaction{
                        font-size: 10px;
                    }
                }
            }
        }
        @media screen and (max-width: 1400px){
            .comment-card{
                height: 205px;
            }
        }
        @media screen and (max-width: 1160px){
            .top-inner{
                display: block!important;
            }
        }
        @media screen and (min-width: 986px) and (max-width: 1160px){
            .comment-card{
                height: 255px;
            }
        }
        @media screen and (max-width: 985px){
            .comment-card{
                font-size: 10px;
            }
        }
    }
}

</style>
