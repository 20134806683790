<template>
  <div v-if="videoSegments.length && videoDetail.is_csv_downloadable" id="chart-segment-section">
    <div class="chart-segment" id="chart-segment">
      <div class="title" v-if="videoDetail.is_screening != 1">セグメント別</div>
      <div class="title" v-else>セグメント別とスクリーニング別</div>
      <div class="video-report-area mt-4 mb-15">
        <div class="row-3">
          <div class="row-3-col-1">
            <div class="card video-list">
              <div class="body">
                <div class="change-segment-mode">
                  <div class="label title-segment" data-report="action">
                    <span v-show="segmentMode == dataModeSegment.attribute">
                      {{ $t('labels.video_detail.go_to_attribute_mode') }}
                    </span>
                  </div>
                  <div class="label button-segment">
                    <div
                      class="d-flex"
                      v-show="segmentMode == dataModeSegment.default"
                      @click="changeSegmentMode"
                      data-report="action"
                    >
                      <i class="icon icon-small-filter mr-1"></i>
                      <span>{{ $t('labels.video_detail.go_to_attribute_mode') }}</span>
                    </div>
                    <div
                      v-show="segmentMode == dataModeSegment.attribute"
                      @click="changeSegmentMode"
                      data-report="action"
                    >
                      <span>{{ $t('labels.video_detail.back_to_default_mode') }}</span>
                    </div>
                  </div>
                </div>
                <div class="tabs">
                  <div
                    class="tab-item"
                    :class="{ active: typeChart }"
                    @click="changeTypeChart(true)"
                    data-report="action"
                  >
                    総リアクション
                  </div>
                  <div class="tab-split"></div>
                  <div
                    class="tab-item"
                    :class="{ active: !typeChart }"
                    @click="changeTypeChart(false)"
                    data-report="action"
                  >
                    各リアクション
                  </div>
                </div>
                <div v-show="segmentMode == dataModeSegment.default">
                  <div class="group-tag-list">
                    <div class="d-flex flex-wrap">
                      <template v-for="attribute in videoAttributes">
                        <div
                          class="tag tag-two"
                          :key="'attribute-' + attribute.id"
                          :class="{ active: videoAttributeId == attribute.id }"
                          @click="selectAttribute(attribute.id)"
                          data-report="action"
                        >
                          <div>{{ attribute.name }}</div>
                        </div>
                      </template>
                    </div>
                    <div class="d-flex flex-wrap" v-if="videoDetail.is_screening == 1">
                      <template v-for="(question, num) in videoScreening.items">
                        <div
                          class="tag tag-two"
                          :key="'question-' + question.id"
                          :class="{ active: questionIdSelected == question.id }"
                          @click="selectQuestion(question.id)"
                          data-report="action"
                        >
                          <div>Q{{ num + 1 }}.{{ formatQuestionText(question.question_text) }}</div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="list-checkbox" v-if="attributeIdSelected">
                    <template v-for="attribute_value in attributeValueSelected">
                      <div
                        class="list-item item-compare"
                        :class="{
                          'active selected': selectedSeriesId == 'attrValue-' + attribute_value.id,
                        }"
                        :key="'attribute-' + attribute_value.id"
                        :id="'attrValue-' + attribute_value.id"
                      >
                        <!--check click all-->
                        <div class="item" v-if="attribute_value.id == 'all'">
                          <i
                            data-report="action"
                            class="icon icon-checkbox"
                            :class="
                              attributeValueIdSelected.includes('attrValue-' + attribute_value.id) ? 'checked' : ''
                            "
                            @click="checkedAttributeValue('all')"
                          ></i>
                          <div class="item-name pl-2" @click="selectAttributeValue(attribute_value.id)">
                            <div class="name">全体</div>
                            <div class="percent">
                              <span v-if="typeChart">
                                <span>平均: </span>
                                {{ reportSummary.tia.total }}
                                {{ `（${reportSummary.tia_percent.total}%）` }}
                              </span>
                              <span v-else :style="colorTextBtnLeft">
                                <span v-show="colorTextBtnLeft.color">
                                  <span :style="colorCircleBtnLeft"></span>
                                  <span>平均: </span>
                                  {{ reportSummary.tia.left }}
                                  {{ `（${reportSummary.tia_percent.left}%）` }}
                                </span>
                              </span>
                            </div>
                            <div class="percent mt-1">
                              <span v-if="typeChart">
                                <span>人数: </span><span>{{ reportSummary.user_count }}</span>
                              </span>
                              <span v-else :style="colorTextBtnRight">
                                <span v-show="colorTextBtnRight.color">
                                  <span :style="colorCircleBtnRight"></span>
                                  <span>平均: </span>
                                  {{ typeChart ? reportSummary.tia.total : reportSummary.tia.right }}
                                  {{
                                    `（${
                                      typeChart ? reportSummary.tia_percent.total : reportSummary.tia_percent.right
                                    }%）`
                                  }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!--end check click all-->
                        <div class="item" v-else>
                          <i
                            class="icon icon-checkbox"
                            :class="
                              attributeValueIdSelected.includes('attrValue-' + attribute_value.id) ? 'checked' : ''
                            "
                            @click="checkedAttributeValue(attribute_value.id)"
                            data-report="action"
                          ></i>
                          <div
                            class="item-name pl-2"
                            @click="selectAttributeValue(attribute_value.id)"
                            data-report="action"
                          >
                            <div class="name">{{ attribute_value.value }}</div>
                            <div class="percent">
                              <span v-if="typeChart">
                                <span>平均: </span>
                                <span>
                                  {{ attribute_value.tiaTotal || 0 }}
                                  {{ `（${attribute_value.tiaPercentTotal || 0}%）` }}
                                </span>
                              </span>
                              <span :style="colorTextBtnLeft" v-else>
                                <span v-show="colorTextBtnLeft.color">
                                  <span :style="colorCircleBtnLeft"></span>
                                  <span>平均: </span>
                                  <span>
                                    {{ attribute_value.tiaLeft || 0 }}
                                    {{ `（${attribute_value.tiaPercentLeft || 0}%）` }}
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div class="percent mt-1">
                              <span v-if="typeChart">
                                <span>人数: </span>
                                <span>
                                  {{ attribute_value.count }}
                                  {{
                                    `（${roundDecimal(
                                      reportSummary.user_count > 0
                                        ? attribute_value.count / reportSummary.user_count
                                        : 0,
                                    )}%）`
                                  }}
                                </span>
                              </span>
                              <span v-else :style="colorTextBtnRight">
                                <span v-show="colorTextBtnRight.color">
                                  <span :style="colorCircleBtnRight"></span>
                                  <span>平均: </span>
                                  <span>
                                    {{ attribute_value.tiaRight || 0 }}
                                    {{ `（${attribute_value.tiaPerCentRight || 0}%）` }}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="list-checkbox" v-else>
                    <template v-for="answer in answerValueSelected">
                      <div
                        class="list-item item-compare"
                        :class="{
                          'active selected': selectedAnswerId == Object.keys(answer)[0],
                        }"
                        :key="'answer-' + Object.keys(answer)[0]"
                        :id="'answerValue-' + Object.keys(answer)[0]"
                      >
                        <!--check click all-->
                        <div class="item" v-if="Object.keys(answer)[0] == 'all'">
                          <i
                            class="icon icon-checkbox"
                            :class="answerValueIdSelected.includes(Object.keys(answer)[0]) ? 'checked' : ''"
                            @click="checkedAnswerValue(Object.keys(answer)[0])"
                          ></i>
                          <div class="item-name pl-2" @click="selectAnswerValue(Object.keys(answer)[0])">
                            <div class="name">全体</div>
                            <div class="percent">
                              <span v-if="typeChart">
                                <span>平均: </span>
                                {{ reportSummary.tia.total }}
                                {{ `（${reportSummary.tia_percent.total}%）` }}
                              </span>
                              <span v-else :style="colorTextBtnLeft">
                                <span v-show="colorTextBtnLeft.color">
                                  <span :style="colorCircleBtnLeft"></span>
                                  <span>平均: </span>
                                  {{ reportSummary.tia.left }}
                                  {{ `（${reportSummary.tia_percent.left}%）` }}
                                </span>
                              </span>
                            </div>
                            <div class="percent mt-1">
                              <span v-if="typeChart">
                                <span>人数: </span><span>{{ reportSummary.user_count }}</span>
                              </span>
                              <span v-else :style="colorTextBtnRight">
                                <span v-show="colorTextBtnRight.color">
                                  <span :style="colorCircleBtnRight"></span>
                                  <span>平均: </span>
                                  {{ typeChart ? reportSummary.tia.total : reportSummary.tia.right }}
                                  {{
                                    `（${
                                      typeChart ? reportSummary.tia_percent.total : reportSummary.tia_percent.right
                                    }%）`
                                  }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!--end check click all-->
                        <div class="item" v-else>
                          <i
                            class="icon icon-checkbox"
                            :class="answerValueIdSelected.includes(Object.keys(answer)[0]) ? 'checked' : ''"
                            @click="checkedAnswerValue(Object.keys(answer)[0])"
                            data-report="action"
                          ></i>
                          <div
                            class="item-name pl-2"
                            @click="selectAnswerValue(Object.keys(answer)[0])"
                            data-report="action"
                          >
                            <div class="name">{{ Object.values(answer)[0] }}</div>
                            <div class="percent">
                              <span v-if="typeChart">
                                <span>平均: </span>
                                <span>
                                  {{ answer.tiaTotal || 0 }}
                                  {{ `（${answer.tiaPercentTotal || 0}%）` }}
                                </span>
                              </span>
                              <span :style="colorTextBtnLeft" v-else>
                                <span v-show="colorTextBtnLeft.color">
                                  <span :style="colorCircleBtnLeft"></span>
                                  <span>平均: </span>
                                  <span>
                                    {{ answer.tiaLeft || 0 }}
                                    {{ `（${answer.tiaPercentLeft || 0}%）` }}
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div class="percent mt-1">
                              <span v-if="typeChart">
                                <span>人数: </span>
                                <span>
                                  {{ answer.count }}
                                  {{
                                    `（${roundDecimal(
                                      totalCountAttrValue > 0 ? answer.count / reportSummary.user_count : 0,
                                    )}%）`
                                  }}</span
                                >
                              </span>
                              <span v-else :style="colorTextBtnRight">
                                <span v-show="colorTextBtnRight.color">
                                  <span :style="colorCircleBtnRight"></span>
                                  <span>平均: </span>
                                  <span>
                                    {{ answer.tiaRight || 0 }}
                                    {{ `（${answer.tiaPerCentRight || 0}%）` }}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="answer-limited-msg" v-if="showAnswerLimitedMsg">
                    <div>
                      <i class="icon icon-close" @click="closeAnswerLimitedMsg" data-report="action"></i>
                      <p class="d-flex justify-content-center align-items-center">
                        回答は{{ maxValueChart }}つまで選択可能です
                      </p>
                    </div>
                  </div>
                </div>
                <div v-show="segmentMode == dataModeSegment.attribute">
                  <div class="group-tag-list">
                    <div class="d-flex flex-wrap">
                      <template v-for="attribute in videoAttributes">
                        <div
                          class="tag tag-two"
                          :key="'attribute-' + attribute.id"
                          :class="{ active: videoAttributeId == attribute.id }"
                          @click="selectAttributeAttributeMode(attribute.id)"
                          data-report="action"
                        >
                          <div>{{ attribute.name }}</div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div v-show="listAttributeSelectedText.length" class="value-item-content">
                    <span class="value-item-content-text">{{ listAttributeSelectedText }}</span>
                  </div>
                  <div class="list-checkbox" v-if="attributeIdSelectedAttributeMode">
                    <template v-for="attribute_value in attributeValueSelectedAttributeMode">
                      <div
                        class="list-item item-compare"
                        :class="{
                          'active selected': selectedSeriesIdMode == 'attrValue-' + attribute_value.id,
                        }"
                        :key="'attribute-' + attribute_value.id"
                        :id="'attrValue-' + attribute_value.id"
                      >
                        <!--check click all-->
                        <div class="item" v-if="attribute_value.id == 'all'">
                          <i
                            data-report="action"
                            class="icon icon-checkbox"
                            :class="
                              attributeValueIdSelectedAttributeMode.includes('attrValue-' + attribute_value.id)
                                ? 'checked'
                                : ''
                            "
                            @click="checkedAttributeValueAttributeMode('all')"
                          ></i>
                          <div class="item-name pl-2" @click="selectAttributeValueMode(attribute_value.id)">
                            <div class="name">全体</div>
                            <div class="percent">
                              <span v-if="typeChart">
                                <span>平均: </span>
                                {{ reportSummary.tia.total }}
                                {{ `（${reportSummary.tia_percent.total}%）` }}
                              </span>
                              <span v-else :style="colorTextBtnLeft">
                                <span v-show="colorTextBtnLeft.color">
                                  <span :style="colorCircleBtnLeft"></span>
                                  <span>平均: </span>
                                  {{ reportSummary.tia.left }}
                                  {{ `（${reportSummary.tia_percent.left}%）` }}</span
                                >
                              </span>
                            </div>
                            <div class="percent mt-1">
                              <span v-if="typeChart">
                                <span>人数: </span><span>{{ reportSummary.user_count }}</span>
                              </span>
                              <span v-else :style="colorTextBtnRight">
                                <span v-show="colorTextBtnRight.color">
                                  <span :style="colorCircleBtnRight"></span>
                                  <span>平均: </span>
                                  {{ typeChart ? reportSummary.tia.total : reportSummary.tia.right }}
                                  {{
                                    `（${
                                      typeChart ? reportSummary.tia_percent.total : reportSummary.tia_percent.right
                                    }%）`
                                  }}</span
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="item" v-else>
                          <i
                            class="icon icon-checkbox"
                            :class="
                              attributeValueIdSelectedAttributeMode.includes('attrValue-' + attribute_value.id)
                                ? 'checked'
                                : ''
                            "
                            @click="checkedAttributeValueAttributeMode(attribute_value.id)"
                            data-report="action"
                          ></i>
                          <div class="item-name pl-2">
                            <div class="name">{{ attribute_value.value }}</div>
                            <div class="percent">
                              <span v-if="typeChart">
                                <span>平均: </span>
                                <span>
                                  {{ attribute_value.tiaTotal || 0 }}
                                  {{ `（${attribute_value.tiaPercentTotal || 0}%）` }}
                                </span>
                              </span>
                              <span :style="colorTextBtnLeft" v-else>
                                <span v-show="colorTextBtnLeft.color">
                                  <span :style="colorCircleBtnLeft"></span>
                                  <span>平均: </span>
                                  <span>
                                    {{ attribute_value.tiaLeft || 0 }}
                                    {{ `（${attribute_value.tiaPercentLeft || 0}%）` }}
                                  </span></span
                                >
                              </span>
                            </div>
                            <div class="percent mt-1">
                              <span v-if="typeChart">
                                <span>人数: </span>
                                <span>
                                  {{ attribute_value.count }}
                                  {{
                                    `（${roundDecimal(
                                      reportSummary.user_count > 0
                                        ? attribute_value.count / reportSummary.user_count
                                        : 0,
                                    )}%）`
                                  }}</span
                                >
                              </span>
                              <span v-else :style="colorTextBtnRight">
                                <span v-show="colorTextBtnRight.color">
                                  <span :style="colorCircleBtnRight"></span>
                                  <span>平均: </span>
                                  <span>
                                    {{ attribute_value.tiaRight || 0 }}
                                    {{ `（${attribute_value.tiaPerCentRight || 0}%）` }}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="answer-limited-msg" v-if="showAnswerLimitedMsg">
                    <div>
                      <i class="icon icon-close" @click="closeAnswerLimitedMsg" data-report="action"></i>
                      <p class="d-flex justify-content-center align-items-center">
                        {{ $t('labels.video_detail.attribute_limited_msg') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <segment-chart
            :reportSummary="reportSummary"
            :reportSegments="reportSegmentValue"
            :attributeValueIdSelected="attributeValueIdSelectedValue"
            :allAttributeValueIdSelected="allAttributeValueIdSelectedValue"
            :answerValueIdSelected="segmentMode == dataModeSegment.default ? answerValueIdSelected : []"
            :selectedSeriesId="selectedSeriesIdValue"
            :selectedAnswerId="segmentMode == dataModeSegment.default ? selectedAnswerId : null"
            :seriesData="seriesData"
            :allSeriesData="allSeriesData"
            :videoImage="videoImage"
            :videoDetail="videoDetail"
            :typeChart="typeChart"
            :reportScreening="reportScreening"
            :videoAttributeId="videoAttributeId"
            :questionIdSelected="segmentMode == dataModeSegment.default ? questionIdSelected : null"
            :answerValueSelected="segmentMode == dataModeSegment.default ? answerValueSelected : []"
            :chartMode="chartMode"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            @changeChartMode="changeChartMode"
          ></segment-chart>
        </div>
      </div>
    </div>
    <text-box
      :videoId="videoDetail.id"
      :urlGet="urlApiGetVideoSegmentTabular"
      :urlPost="urlApiPostVideoSegmentTabular"
      :placeholder="placeHolderTextBox"
      :classTextBox="classTextBox"
      :idTextBox="'segment-text-box'"
      :dataCustom="'data-report'"
    >
    </text-box>
    <div class="group-list-segment mt-8 mb-18">
      <div class="mt-3">
        <div class="row-3 mt-5" v-if="interactionIcon.rank_2.length">
          <div class="row-3-col-1 mb-3 max-capture-cut" v-for="item in rank_good" :key="item.id">
            <div class="card card-segment card-subtitle" :class="{ inactive: !('interaction_count' in item) }">
              <div class="content">
                <div class="segment-title">
                  <div class="reaction-type mb-3">
                    <img style="margin-right: 8px; width: 24px" :src="interactionIcon.rank_2" alt="" />
                    {{ interactionText.rank_2 }}の最大値カット
                  </div>
                  <div class="title-right">
                    <div class="name">{{ item.value }}</div>
                  </div>
                </div>
                <div class="segment-body" v-if="'interaction_count' in item">
                  <div class="body-left">
                    <img class="card-image" :src="videoImage.image_url + item.event_at + '.jpg'" />
                  </div>
                  <div class="body-right">
                    <div class="data">{{ item.interaction_count }}%</div>
                    <div class="value">{{ item.event_at }}s</div>
                  </div>
                </div>
                <div class="segment-body" v-else>
                  <div class="message">このセグメントの反応値はございません。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-3 mt-5" v-if="interactionIcon.rank_3.length">
          <div class="row-3-col-1 mb-3 max-capture-cut" v-for="item in rank_bad" :key="item.id">
            <div class="card card-segment card-subtitle" :class="{ inactive: !('interaction_count' in item) }">
              <div class="content">
                <div class="segment-title">
                  <div class="reaction-type mb-3">
                    <img style="margin-right: 8px; width: 24px" :src="interactionIcon.rank_3" alt="" />
                    <div>{{ interactionText.rank_3 }}の最大値カット</div>
                  </div>
                  <div class="title-right">
                    <div class="name">{{ item.value }}</div>
                  </div>
                </div>
                <div class="segment-body" v-if="'interaction_count' in item">
                  <div class="body-left">
                    <img class="card-image" :src="videoImage.image_url + item.event_at + '.jpg'" />
                  </div>
                  <div class="body-right">
                    <div class="data">{{ item.interaction_count }}%</div>
                    <div class="value">{{ item.event_at }}s</div>
                  </div>
                </div>
                <div class="segment-body" v-else>
                  <div class="message">このセグメントの反応値はございません。</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { roundDecimal, numberWithCommas } from '@/commons/helpers'
import {
  getAttributesPromise,
  getReportSegmentRanksPromise,
  getVideoSegmentsPromise,
  getReportSegmentsPromise,
  getReportScreeningPromise,
  getVideoScreeningPromise,
  getReportSegmentAttribute,
} from '@/commons/api_service'
import { formatSeriesDataSegmentAttr } from '@/commons/charts'
import SegmentChart from '@/components/TopDetail/SegmentChart.vue'
import TextBox from '@/components/TopDetail/TextBox.vue'
import EventBus from '@/commons/event_bus'
import { listChartMode, dataModeSegment } from '@/commons/constants'
const jQuery = require('jquery')

export default {
  name: 'VideoSegment',
  components: { SegmentChart, TextBox },
  props: ['videoDetail', 'videoImage', 'reportSummary'],
  data() {
    return {
      attributes: [],
      videoSegments: [],
      reportSegmentRanks: {},
      videoAttributes: {},
      attributeIdSelected: null,
      attributeValueSelected: [],
      allAttributeValueIdSelected: [],
      attributeValueIdSelected: [],
      totalCountAttrValue: 0,
      rank_good: [],
      rank_bad: [],
      reportSegments: [],
      selectedSeriesId: null,
      selectedSeriesIdMode: null,
      seriesColumnData: [],
      allSeriesData: [],
      typeChart: true,
      urlApiGetVideoSegmentTabular: 'api.video.segment-tabular',
      urlApiPostVideoSegmentTabular: 'api.videos.segment-tabular.insert',
      placeHolderTextBox: 'テキストが入力できます。',
      classTextBox: 'segment-tabular',
      is_stack_bar: 0,
      reportScreening: [],
      videoScreening: [],
      questionIdSelected: null,
      answerValueSelected: [],
      answerValueIdSelected: [],
      selectedAnswerId: null,
      showAnswerLimitedMsg: false,
      chartMode: listChartMode.line.value,
      listChartMode: listChartMode,
      maxValueChart: 8,
      // for only segment data mode
      selectedSeriesIdAttributeMode: 'segment',
      dataModeSegment: dataModeSegment,
      segmentMode: dataModeSegment.default,
      attributeIdSelectedAttributeMode: null,
      attributeValueSelectedAttributeMode: [],
      attributeValueIdSelectedAttributeMode: [],
      reportSegmentAttribute: [],
      checkHasAllData: true,
    }
  },

  computed: {
    videoAttributeId() {
      if (this.segmentMode == dataModeSegment.default) return this.attributeIdSelected
      else return this.attributeIdSelectedAttributeMode
    },
    interactionIcon() {
      return {
        rank_2:
          this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length
            ? this.videoDetail.interaction_btn_1.btn_icon_url
            : '',
        rank_3:
          this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length
            ? this.videoDetail.interaction_btn_2.btn_icon_url
            : '',
      }
    },
    interactionText() {
      return {
        rank_2:
          this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length
            ? this.videoDetail.interaction_btn_1.text
            : '',
        rank_3:
          this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length
            ? this.videoDetail.interaction_btn_2.text
            : '',
      }
    },

    colorTextBtnLeft() {
      return {
        color: this.$store.getters.interactioncolors[0] ? this.$store.getters.interactioncolors[0] : '',
      }
    },
    colorTextBtnRight() {
      return {
        color: this.$store.getters.interactioncolors[1] ? this.$store.getters.interactioncolors[1] : '',
      }
    },
    colorCircleBtnLeft() {
      return {
        display: 'inline-block',
        width: '7px',
        height: '7px',
        marginTop: '2px',
        marginRight: '2px',
        border: '1px solid',
        borderColor: this.$store.getters.interactioncolors[0] ? this.$store.getters.interactioncolors[0] : '',
        borderRadius: '50%',
        background: this.$store.getters.interactioncolors[0] ? this.$store.getters.interactioncolors[0] : '',
      }
    },
    colorCircleBtnRight() {
      return {
        display: 'inline-block',
        width: '7px',
        height: '7px',
        marginTop: '2px',
        marginRight: '2px',
        border: '1px solid #ce6060',
        borderColor: this.$store.getters.interactioncolors[1] ? this.$store.getters.interactioncolors[1] : '',
        borderRadius: '50%',
        background: this.$store.getters.interactioncolors[1] ? this.$store.getters.interactioncolors[1] : '',
      }
    },
    interactionColor() {
      let color_rank_2 =
        this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length
          ? this.videoDetail.interaction_btn_1.color
          : ''
      let color_rank_3 =
        this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length
          ? this.videoDetail.interaction_btn_2.color
          : ''
      return {
        rank_2:
          color_rank_2 == 1
            ? 'good'
            : color_rank_2 == 2
            ? 'nattoku'
            : color_rank_2 == 3
            ? 'bad'
            : color_rank_2 == 4
            ? 'suki'
            : '',
        rank_3:
          color_rank_3 == 1
            ? 'good'
            : color_rank_3 == 2
            ? 'nattoku'
            : color_rank_3 == 3
            ? 'bad'
            : color_rank_3 == 4
            ? 'suki'
            : '',
      }
    },
    seriesData() {
      // format series data for chart segment by attributeValueIdSelected
      let seriesData = []
      if (this.segmentMode == dataModeSegment.default) {
        if (this.reportSegments[0] && this.reportSegments[0].data && this.attributeValueIdSelected.length) {
          this.attributeValueIdSelected.map(attrValueId => {
            attrValueId = attrValueId.replace('attrValue-', '')
            let data
            if (attrValueId == 'all') {
              this.checkHasAllData = true
              data = this.formatSeriesDataSegmentVideo(this.reportSummary?.tally_result, attrValueId)
            } else {
              data = this.formatSeriesDataSegmentVideo(this.reportSegments[0].data, attrValueId)
            }

            seriesData.push(data)
          })
        }
      } else {
        if (this.reportSegmentAttribute[0] && this.reportSegmentAttribute[0].data) {
          //1429 check only checked all
          if (this.reportSegmentAttribute[0].data == 'attrValue-all') {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.checkHasAllData = true
            let dataIdAll = this.formatSeriesDataSegmentVideo(this.reportSummary?.tally_result, 'all')

            seriesData.push(dataIdAll)

            return seriesData
          }

          const attrValueId = this.selectedSeriesIdAttributeMode
          const data = this.formatSeriesDataSegmentVideo(this.reportSegmentAttribute[0].data, attrValueId)

          //1429 add line all
          if (this.attributeValueIdSelectedAttributeMode.includes('attrValue-all')) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.checkHasAllData = true
            let dataIdAll = this.formatSeriesDataSegmentVideo(this.reportSummary?.tally_result, 'all')

            seriesData.push(dataIdAll)
          }
          seriesData.push(data)
        }
      }
      return seriesData
    },
    selectedSeriesIdValue() {
      if (this.segmentMode == dataModeSegment.default) return this.selectedSeriesId
      else {
        if (this.selectedSeriesIdMode == 'attrValue-all') {
          return 'attrValue-all'
        } else {
          return 'attrValue-' + this.selectedSeriesIdAttributeMode
        }
      }
    },
    reportSegmentValue() {
      if (this.segmentMode == dataModeSegment.default) return this.reportSegments
      else return this.reportSegmentAttribute
    },
    attributeValueIdSelectedValue() {
      if (this.segmentMode == dataModeSegment.default) return this.attributeValueIdSelected
      else {
        if (this.attributeValueIdSelectedAttributeMode.includes('attrValue-all')) {
          return ['attrValue-all', 'attrValue-' + this.selectedSeriesIdAttributeMode]
        } else {
          return ['attrValue-' + this.selectedSeriesIdAttributeMode]
        }
      }
    },
    allAttributeValueIdSelectedValue() {
      if (this.segmentMode == dataModeSegment.default) return this.allAttributeValueIdSelected
      else {
        return ['attrValue-all', 'attrValue-' + this.selectedSeriesIdAttributeMode]
      }
    },
    listAttributeSelectedText: function () {
      if (!this.attributeValueIdSelectedAttributeMode.length) return ''
      let results = Object.keys(this.videoAttributes).map(id => {
        const attribute = this.videoAttributes[id]
        if (attribute && attribute.values) {
          const texts = this.attributeValueIdSelectedAttributeMode
            .filter(attrValueId => attribute.values.some(value => value.id === parseInt(attrValueId.split('-')[1])))
            .map(attrValueId => attribute.values?.find(value => value.id === parseInt(attrValueId.split('-')[1])).value)
          return texts.join('or')
        } else {
          return ''
        }
      })

      results = results.filter(value => value !== '')
      return results.join(' | ')
    },
    totalCountScreening: function () {
      let total = 0
      if (this.questionIdSelected && Object.keys(this.videoScreening).length) {
        let currentQuestionData = this.videoScreening.items?.find(item => item.id == this.questionIdSelected)
        if (currentQuestionData) {
          currentQuestionData.answers.forEach(function (item) {
            total += item?.count
          })
        }
      }

      return total
    },
  },
  watch: {
    videoAttributes: function (videoAttributes) {
      if (Object.keys(videoAttributes).length) {
        this.attributeIdSelected = videoAttributes ? videoAttributes[Object.keys(videoAttributes)[0]].id : null
      }
    },
    attributeIdSelected(newValue) {
      this.setAttributeValueSelect(newValue)
    },
    attributeIdSelectedAttributeMode(newValue) {
      this.setAttributeValueSelectAttributeMode(newValue)
    },
    questionIdSelected(newValue) {
      this.setAnswerValueSelect(newValue)
    },
    attributeValueSelected() {
      this.getAllSeriesData()
    },
    attributeValueIdSelectedAttributeMode() {
      this.getDataAttributeMode()
    },
  },
  mounted() {
    this.initDataSegmentReport()
  },
  methods: {
    //1429

    formatDataCommon(data) {
      return data?.map(item => {
        if (item.id != 'all') {
          let findTiaByAttrId = this.reportSegments[0]?.tia?.find(itemTia => {
            return itemTia.attribute_value_id == item.id
          })

          let findTiaPercentByAttrId = this.reportSegments[0]?.tia_percent?.find(itemTia => {
            return itemTia.attribute_value_id == item.id
          })

          let itemFormat = {}
          itemFormat['id'] = item.id
          itemFormat['count'] = item?.count
          itemFormat['sequence'] = item.sequence
          itemFormat['value'] = item.value

          return {
            ...itemFormat,
            tiaTotal: findTiaByAttrId?.total,
            tiaPercentTotal: findTiaPercentByAttrId?.total,
            tiaLeft: findTiaByAttrId?.left,
            tiaPercentLeft: findTiaPercentByAttrId?.left,
            tiaRight: findTiaByAttrId?.right,
            tiaPerCentRight: findTiaPercentByAttrId?.right,
          }
        } else {
          return item
        }
      })
    },
    formatDataAttributeValueSelected() {
      if (this.segmentMode == 1) {
        this.attributeValueSelected = this.formatDataCommon(this.attributeValueSelected)
      } else {
        this.attributeValueSelectedAttributeMode = this.formatDataCommon(this.attributeValueSelectedAttributeMode)
      }
    },
    formatDataAnswerSelected() {
      this.answerValueSelected = this.answerValueSelected?.map(item => {
        let id = Object.keys(item)[0]
        if (id) {
          if (id != 'all') {
            let findTiaByAttrId = this.reportScreening?.tia?.find(itemTia => {
              return itemTia.screening_answer_id == id
            })

            let findTiaPercentByAttrId = this.reportScreening?.tia_percent?.find(itemTia => {
              return itemTia.screening_answer_id == id
            })

            let countUserAnswer = this.videoScreening?.items
              ?.find(question => {
                return question.id == this.questionIdSelected
              })
              ?.answers.find(answer => {
                return Object.keys(answer)[0] == id
              })

            let itemFormat = {}
            itemFormat[id] = item[id]

            return {
              ...itemFormat,
              tiaTotal: findTiaByAttrId?.total,
              tiaPercentTotal: findTiaPercentByAttrId?.total,
              tiaLeft: findTiaByAttrId?.left,
              tiaPercentLeft: findTiaPercentByAttrId?.left,
              tiaRight: findTiaByAttrId?.right,
              tiaPerCentRight: findTiaPercentByAttrId?.right,
              count: countUserAnswer?.count,
            }
          } else {
            let itemFormat = {}
            itemFormat[id] = item[id]
            return { ...itemFormat }
          }
        }
      })
    },

    selectedChart(id) {
      this.selectedAttributeValue(id)
    },
    selectedAnswerChart(id) {
      this.selectedAnswerId = id
    },
    changeTypeChart(isTotalInteraction) {
      this.typeChart = isTotalInteraction
    },
    roundDecimal(value) {
      return roundDecimal(value * 100, 1)
    },
    selectAttribute(videoAttributeId) {
      this.attributeIdSelected = videoAttributeId
      this.questionIdSelected = null
      this.answerValueIdSelected = []
    },
    selectAttributeAttributeMode(videoAttributeId) {
      this.attributeIdSelectedAttributeMode = videoAttributeId
    },
    selectQuestion(id) {
      this.questionIdSelected = id
      this.attributeIdSelected = null
      this.attributeValueIdSelected = []
    },
    selectedAttributeValue(attributeValueId) {
      let attrValue = 'attrValue-' + attributeValueId
      if (attributeValueId == 'segment') {
        this.selectedSeriesId = 'attrValue-segment'
        this.selectedSeriesIdMode = null
      }
      if (this.attributeValueIdSelected.includes(attrValue)) {
        this.selectedSeriesId = attrValue
        this.selectedSeriesIdMode = attrValue
      }
    },

    checkedAttributeValue(attributeValueId) {
      let attrValue = 'attrValue-' + attributeValueId
      let attributeValueIdSelected = JSON.parse(JSON.stringify(this.attributeValueIdSelected))

      if (attributeValueIdSelected.includes(attrValue)) {
        let index = attributeValueIdSelected.indexOf(attrValue)
        if (index > -1) {
          attributeValueIdSelected.splice(index, 1)
        }
        if (this.selectedSeriesId == attrValue) {
          this.selectedSeriesId = null
        }
      } else {
        this.maxValueChart = this.chartMode == listChartMode.line.value ? 8 : 5
        if (this.seriesData?.length >= this.maxValueChart) {
          this.showAnswerLimitedMsg = true
          return
        }
        attributeValueIdSelected.push('attrValue-' + attributeValueId)
      }
      // resort attributeValueIdSelected by attributeValueSelected
      let attributeValueSelected = JSON.parse(JSON.stringify(this.attributeValueSelected))
      let attributeValueIdSelectedResort = []
      attributeValueSelected?.map(attr => {
        if (attributeValueIdSelected.length > 0 && attributeValueIdSelected.includes('attrValue-' + attr.id)) {
          attributeValueIdSelectedResort.push('attrValue-' + attr.id)
        }
      })
      this.attributeValueIdSelected = attributeValueIdSelectedResort
      this.selectedAnswerId = null
      this.getReportAttributes()
    },

    checkedAttributeValueAttributeMode(attributeValueId) {
      let attrValue = 'attrValue-' + attributeValueId
      let attributeValueIdSelected = JSON.parse(JSON.stringify(this.attributeValueIdSelectedAttributeMode))
      if (attributeValueIdSelected.includes(attrValue)) {
        let index = attributeValueIdSelected.indexOf(attrValue)
        if (index > -1) {
          attributeValueIdSelected.splice(index, 1)
        }
        if (this.selectedSeriesIdMode == attrValue) {
          this.selectedSeriesIdMode = null
        }
      } else {
        if (attributeValueId == 'all') {
          attributeValueIdSelected.unshift('attrValue-' + attributeValueId)
        } else {
          attributeValueIdSelected.push('attrValue-' + attributeValueId)
        }
      }
      // resort attributeValueIdSelected by attributeValueSelected
      attributeValueIdSelected.sort(function (a, b) {
        let idA = parseInt(a.substring('attrValue-'.length), 10)
        let idB = parseInt(b.substring('attrValue-'.length), 10)

        return idA - idB
      })
      this.attributeValueIdSelectedAttributeMode = attributeValueIdSelected
    },
    checkedAnswerValue(id) {
      let answerValueIdSelected = JSON.parse(JSON.stringify(this.answerValueIdSelected))
      if (answerValueIdSelected.includes(id)) {
        let index = answerValueIdSelected.indexOf(id)
        if (index > -1) {
          answerValueIdSelected.splice(index, 1)
        }
        if (this.selectedAnswerId == id) {
          this.selectedAnswerId = null
        }
      } else {
        this.maxValueChart = this.chartMode == listChartMode.line.value ? 8 : 5
        if (answerValueIdSelected.length < this.maxValueChart) answerValueIdSelected.push(id)
        else {
          this.showAnswerLimitedMsg = true
          return
        }
      }
      // resort answerValueIdSelected by answerValueSelected
      let answerValueSelected = JSON.parse(JSON.stringify(this.answerValueSelected))
      let answerValueIdSelectedResort = []
      answerValueSelected.map(answer => {
        let key = Object.keys(answer)[0]
        if (answerValueIdSelected.includes(key)) {
          answerValueIdSelectedResort.push(key)
        }
      })
      this.answerValueIdSelected = answerValueIdSelectedResort
      this.selectedSeriesId = null
    },
    selectAttributeValue(attributeValueId) {
      let attrValue = 'attrValue-' + attributeValueId

      // let reportData = this.reportSegments[0].data.attribute_value_ids

      if (this.attributeValueIdSelected.includes(attrValue)) {
        if (this.selectedSeriesId == attrValue) {
          this.selectedSeriesId = null
        } else {
          this.selectedSeriesId = attrValue
        }
      }
    },

    selectAttributeValueMode(attributeValueId) {
      let attrValue = 'attrValue-' + attributeValueId

      if (this.attributeValueIdSelectedAttributeMode.includes(attrValue)) {
        if (this.selectedSeriesIdMode == attrValue) {
          this.selectedSeriesIdMode = null
        } else {
          this.selectedSeriesIdMode = attrValue
        }
      }
    },

    selectAnswerValue(id) {
      if (this.answerValueIdSelected.includes(id)) {
        if (this.selectedAnswerId == id) {
          this.selectedAnswerId = null
        } else {
          this.selectedAnswerId = id
        }
      }
    },
    initDataSegmentReport() {
      if (this.videoDetail && this.videoDetail.is_csv_downloadable) {
        let params = {
          video_id: this.videoDetail.id,
          interaction_tally: 1,
          is_stack_bar: this.is_stack_bar,
        }
        Promise.all([
          getAttributesPromise(),
          getVideoSegmentsPromise({ video_id: this.videoDetail.id }, true),
          getReportSegmentRanksPromise(params, true),
          getReportSegmentsPromise(params, true),
          getReportScreeningPromise({ video_id: this.videoDetail.id, interaction_tally: 1 }),
          getVideoScreeningPromise({ video_id: this.videoDetail.id }),
        ]).then(data => {
          this.attributes = data[0]
          this.videoSegments = data[1]
          this.reportSegmentRanks = data[2]
          this.reportSegments = data[3]
          this.reportScreening = data[4]
          this.videoScreening = data[5]
          this.formatAttributeVideo()
        })
      }
    },
    getAttributeDataById(attribute_id) {
      let attributeData = {}
      if (attribute_id) {
        attributeData = this.attributes?.find(e => e.id == attribute_id)
      }
      return attributeData
    },
    getAttributeValueDataById(attributeData, attribute_value_id) {
      let attributeValueData = {}
      if (attributeData && attribute_value_id) {
        attributeValueData = attributeData.values?.find(e => e.id == attribute_value_id)
      }
      return attributeValueData
    },
    getReportAttributesById(attribute_id) {
      let attributeData = this.getAttributeDataById(attribute_id)
      if (attributeData && attributeData.values) {
        return this.reportSegmentRanks.segment_ranks.filter(report =>
          attributeData.values?.find(e => report.attribute_value_id == e.id),
        )
      }
      return []
    },
    formatAttributeVideo() {
      let videoAttributes = {}
      this.videoSegments.forEach(data => {
        let attribute_id = data.attribute_id
        let attributeData = this.getAttributeDataById(attribute_id)
        let attribute_value_id = data.attribute_value_id
        let attributeValueData = this.getAttributeValueDataById(attributeData, attribute_value_id)

        let attr_values = {}
        if (attributeData && attributeValueData) {
          attr_values['id'] = attribute_value_id
          attr_values['value'] = attributeValueData.value
          attr_values['sequence'] = attributeValueData.sequence
          attr_values['count'] = data?.count ? data?.count : 0
          if (attribute_id in videoAttributes) {
            videoAttributes[attribute_id].attr_values.push(attr_values)
            videoAttributes[attribute_id].total_count += data?.count ? data?.count : 0
          } else {
            videoAttributes[attribute_id] = attributeData
            videoAttributes[attribute_id].attr_values = []
            videoAttributes[attribute_id].attr_values.push(attr_values)
            videoAttributes[attribute_id].total_count = 0
            videoAttributes[attribute_id].total_count += data?.count ? data?.count : 0
          }
        }
      })

      // sort attribute value by sequence
      for (let key in videoAttributes) {
        videoAttributes[key].attr_values.sort((a, b) => a.sequence - b.sequence)
      }

      this.videoAttributes = videoAttributes
    },
    setAttributeValueSelect(attribute_id) {
      let attributeValueSelected = []
      if (attribute_id in this.videoAttributes) {
        attributeValueSelected = this.videoAttributes[attribute_id].attr_values

        //1429 add value all
        if (!attributeValueSelected.some(v => 'all' === v.id)) {
          attributeValueSelected.unshift({
            id: 'all',
          })
        }

        this.attributeValueSelected = attributeValueSelected

        //1429
        this.formatDataAttributeValueSelected()
        this.attributeValueIdSelected = []
        this.checkedAttributeValue('all')
        this.totalCountAttrValue = this.videoAttributes[attribute_id].total_count
        this.$nextTick(() => {
          this.selectedSeriesId = this.attributeValueIdSelected.length ? this.attributeValueIdSelected[0] : null
        })
      }
      this.getReportAttributes()
    },
    setAttributeValueSelectAttributeMode(attribute_id) {
      let attributeValueSelected = []
      if (attribute_id in this.videoAttributes) {
        attributeValueSelected = this.videoAttributes[attribute_id].attr_values

        //1429 add value all
        if (!attributeValueSelected.some(v => 'all' === v.id)) {
          attributeValueSelected.unshift({
            id: 'all',
          })
        }

        this.attributeValueSelectedAttributeMode = attributeValueSelected
        this.formatDataAttributeValueSelected()
      }
    },
    setAnswerValueSelect(id) {
      this.answerValueIdSelected = []
      let videoScreeningSelected = this.videoScreening.items.filter(val => val.id === id)
      if (videoScreeningSelected.length) {
        if (!videoScreeningSelected[0].answers.some(v => 'all' === Object.keys(v)[0])) {
          videoScreeningSelected[0].answers.unshift({
            all: '全体',
          })
        }
        this.answerValueSelected = videoScreeningSelected[0].answers
        if (this.answerValueSelected.length) {
          this.selectedAnswerId = Object.keys(this.answerValueSelected[0])[0]
          this.checkedAnswerValue(this.selectedAnswerId)
        }
      }

      this.formatDataAnswerSelected()
    },
    getReportAttributes() {
      this.rank_good = []
      this.rank_bad = []
      if (this.reportSegmentRanks.attribute_ids && this.reportSegmentRanks.attribute_ids.length) {
        let reportAttributeData = this.getReportAttributesById(this.attributeIdSelected)
        let attributeData = this.getAttributeDataById(this.attributeIdSelected)
        if (reportAttributeData.length) {
          reportAttributeData.forEach(data => {
            if (this.attributeValueIdSelected.includes('attrValue-' + data.attribute_value_id)) {
              let attributeValueData = this.getAttributeValueDataById(attributeData, data.attribute_value_id)
              let top_good = data['2_top']
              let top_bad = data['3_top']
              if (attributeValueData) {
                top_good['attribute_value_id'] = data.attribute_value_id
                top_bad['attribute_value_id'] = data.attribute_value_id
                top_good['value'] = attributeValueData.value
                top_bad['value'] = attributeValueData.value
              }
              if (top_good['interaction_count']) {
                top_good['interaction_count'] = numberWithCommas(top_good['interaction_count'])
              }
              if (top_bad['interaction_count']) {
                top_bad['interaction_count'] = numberWithCommas(top_bad['interaction_count'])
              }
              this.rank_good.push(top_good)
              this.rank_bad.push(top_bad)
            }
          })
        }
      }
    },
    formatSeriesDataSegmentVideo(reportData, attributeValueId) {
      let result = []
      if (reportData) {
        reportData.forEach(data => {
          let attrData = {}
          if (attributeValueId !== 'all') {
            attrData = data.values?.find(e => attributeValueId == e.attribute_value_id)

            if (attrData) {
              attrData['second'] = data.second
              result.push(attrData)
            } else {
              attrData = {}
              attrData['second'] = data.second
              attrData['left_count'] = 0
              attrData['right_count'] = 0
              result.push(attrData)
            }
          } else {
            if (this.checkHasAllData) {
              attrData = data

              if (attrData) {
                result.push({
                  attribute_value_id: 'all',
                  second: data.second,
                  left_count: data.left,
                  right_count: data.right,
                })
              } else {
                attrData = {}
                attrData['second'] = data.second
                attrData['left'] = 0
                attrData['right'] = 0
                result.push(attrData)
              }
            }
          }
        })
      }
      this.checkHasAllData = false
      return formatSeriesDataSegmentAttr(result, attributeValueId)
    },
    addClassActive(data) {
      if (data) {
        jQuery('.list-item').not('.selected').removeClass('active')
        jQuery('#' + data).addClass('active')
      }
    },
    removeClassActive(data) {
      if (data) {
        jQuery('.list-item').not('.selected').removeClass('active')
      }
    },
    formatQuestionText(str) {
      if (str.length > 16) {
        str = str.slice(0, 16) + '...'
      }
      return str
    },
    closeAnswerLimitedMsg() {
      this.showAnswerLimitedMsg = false
    },
    getAllSeriesData() {
      this.allAttributeValueIdSelected = []
      this.allSeriesData = []
      this.attributeValueSelected.map(attr => {
        this.allAttributeValueIdSelected.push('attrValue-' + attr.id)
        let serieData
        if (attr.id == 'all') {
          serieData = this.formatSeriesDataSegmentVideo(this.reportSummary?.tally_result, attr.id)
        } else {
          serieData = this.formatSeriesDataSegmentVideo(this.reportSegments[0]?.data, attr.id)
        }

        this.allSeriesData.push(serieData)
      })
    },
    changeChartMode(mode) {
      if (this.chartMode == mode) return
      if (this.segmentMode == dataModeSegment.attribute) {
        this.chartMode = mode
        return
      }
      if (mode == listChartMode.column.value) {
        if (this.answerValueIdSelected.length > 5 || this.attributeValueIdSelected.length > 5) {
          this.maxValueChart = 5
          this.showAnswerLimitedMsg = true
          return
        }
      } else {
        this.showAnswerLimitedMsg = false
        this.maxValueChart = 8
      }
      this.chartMode = mode
    },
    changeSegmentMode() {
      if (this.chartMode == listChartMode.column.value) {
        if (this.answerValueIdSelected.length > 5 || this.attributeValueIdSelected.length > 5) {
          this.maxValueChart = 5
          this.showAnswerLimitedMsg = true
          return
        }
      } else {
        this.showAnswerLimitedMsg = false
        this.maxValueChart = 8
      }
      this.segmentMode =
        this.segmentMode == dataModeSegment.default ? dataModeSegment.attribute : dataModeSegment.default
    },
    getDataAttributeMode() {
      if (!this.attributeValueIdSelectedAttributeMode.length) {
        this.reportSegmentAttribute = []
        return
      }

      let attributeValueIdSelectedAttributeMode = JSON.parse(JSON.stringify(this.attributeValueIdSelectedAttributeMode))

      //1429 check only checked all
      if (
        attributeValueIdSelectedAttributeMode.length == 1 &&
        attributeValueIdSelectedAttributeMode.includes('attrValue-all')
      ) {
        this.reportSegmentAttribute = [
          {
            data: 'attrValue-all',
            attribute_value_ids: 'attrValue-all',
          },
        ]
        return
      }

      //1429 remove attribute-value all
      const index = attributeValueIdSelectedAttributeMode.indexOf('attrValue-all')
      if (index > -1) {
        attributeValueIdSelectedAttributeMode.splice(index, 1)
      }

      let paramsValueId = attributeValueIdSelectedAttributeMode.map(attr => attr.replace('attrValue-', '')).toString()

      let params = {
        video_id: this.videoDetail.id,
        attribute_value_ids: paramsValueId,
      }
      getReportSegmentAttribute(params).then(data => {
        if (data.data.length) {
          this.reportSegmentAttribute = [
            {
              data: this.formatSegmentDataAttribute(data),
              attribute_value_ids: [this.selectedSeriesIdAttributeMode],
            },
          ]
        } else {
          this.reportSegmentAttribute = [
            {
              data: this.formatDefaultSegmentDataAttribute(),
              attribute_value_ids: [this.selectedSeriesIdAttributeMode],
            },
          ]
        }
      })
    },
    formatDefaultSegmentDataAttribute() {
      let result = []
      for (let i = 0; i <= this.videoImage.duration + 1; i++) {
        const item = {
          second: i,
          values: [
            {
              attribute_value_id: this.selectedSeriesIdAttributeMode,
              left_count: 0,
              right_count: 0,
              second: i,
              btn_1: 0,
              btn_2: 0,
            },
          ],
        }
        result.push(item)
      }
      return result
    },
    formatSegmentDataAttribute(data) {
      let result = []
      data.data = data.data.sort((a, b) => a.second - b.second)
      for (let i = 0; i < data.data.length; i++) {
        let dataItem = data.data?.find(e => e.second == i)
        if (!dataItem) {
          dataItem = {
            second: i,
            left_count: 0,
            right_count: 0,
            btn_1: 0,
            btn_2: 0,
          }
        }
        const item = {
          second: i,
          values: [
            {
              attribute_value_id: this.selectedSeriesIdAttributeMode,
              left_count: dataItem.left_count,
              right_count: dataItem.right_count,
              second: i,
              btn_1: dataItem.right_count,
              btn_2: dataItem.left_count,
            },
          ],
        }
        result.push(item)
      }
      return result
    },
  },

  created() {
    EventBus.$on('selectedChart', this.selectedChart)
    EventBus.$on('selectedAnswerChart', this.selectedAnswerChart)
  },
  beforeDestroy() {
    EventBus.$off('selectedChart', this.selectedChart)
    EventBus.$off('selectedAnswerChart', this.selectedAnswerChart)
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variable';
.chart-segment {
  margin-top: 32px;
  .title {
    @include font(xxl);
    font-weight: bold;
  }
  .video-report-area {
    margin-top: 30px;
    height: 353px;
    .video-list {
      width: 100%;
      height: 353px;
      display: flex;
      flex-direction: column;
      position: relative;
      @include font(s);
      .body {
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: white;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $black;
        }
        // direction: rtl;
        overflow-y: auto;
        //padding-bottom: 8px;
      }
      //.setting {
      //  justify-content: end;
      //  max-height: 28px;
      //  font-weight: 700;
      //  font-size: 10px;
      //  line-height: 16px;
      //  color: #49AB94;
      //  padding: 6px 12px;
      //  margin-top: auto;
      //  border: 1px solid #EBEBEB;
      //  border-bottom-left-radius: 8px;
      //  border-bottom-right-radius: 8px;
      //  cursor: pointer;
      //}
      .tabs {
        direction: ltr;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 20px 0;
        .tab-item {
          cursor: pointer;
          color: $middle-black;
          padding-left: 8px;
          padding-right: 8px;
          &.active {
            color: $black;
            font-weight: 700;
          }
          &:last-child {
            padding-left: 8px;
          }
        }
        .tab-split {
          width: 1px;
          background-color: $light-black;
          height: 8px;
        }
      }
      .group-tag-list {
        display: flex;
        flex-direction: column;
        direction: ltr;
        padding: 5px 20px 20px;
        border-top: 0;
        .tag {
          font-family: 'Noto Sans JP';
          margin-right: 10px;
          margin-top: 10px;
          height: 40px;
        }
      }
      .list-checkbox {
        display: flex;
        flex-wrap: wrap;
        direction: ltr;
        cursor: pointer;
        align-items: center;
        .list-item {
          display: flex;
          padding: 12px 20px;
          max-width: 50%;
          flex: 0 0 50%;
          box-sizing: border-box;
          &.active {
            background-color: $light-green;
          }
          .name {
            color: #333333;
            font-size: 12px;
            line-height: 19px;
          }
          .percent {
            @include font(xs);
            line-height: 8px;
            color: $middle-black;
            font-size: 9px;
          }
          .icon-circle-red {
            display: inline-block;
            width: 7px;
            height: 7px;
            margin-top: 2px;
            margin-right: 2px;
            border: 1px solid #ce6060;
            border-radius: 50%;
            background: red;
          }
          .icon-circle-blue {
            display: inline-block;
            width: 7px;
            height: 7px;
            margin-top: 2px;
            margin-right: 2px;
            border: 1px solid #4976ab;
            border-radius: 50%;
            background: #4976ab;
          }
          .percent-good {
            @include font(xs);
            color: $green;
            display: flex;
            align-items: center;
            .dot-good {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: $green;
            }
          }
          .percent-bad {
            @include font(xs);
            color: $red;
            display: flex;
            align-items: center;
            .dot-bad {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: $red;
            }
          }
          &.item-compare {
            justify-content: space-between;
            .item {
              width: 100%;
              display: flex;
              align-items: center;
              .item-name {
                flex: 1 1;
              }
            }
          }
        }
      }
      .answer-limited-msg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 8px;

        div {
          position: relative;
          color: #333333;
          font-size: 16px;
          line-height: 22px;
          width: 80%;
          height: 200px;
          background-color: #ffffff;
          border: 1px solid #ebebeb;
          border-radius: 8px;
          padding: 0 20px;
          display: flex;
          justify-content: center;

          p {
            text-align: left;
          }
        }

        @media screen and (max-width: 1400px) {
          div {
            width: 210px;
          }
        }

        i {
          position: absolute;
          cursor: pointer;
          top: 20px;
          right: 20px;
          z-index: 1;
        }
      }

      .value-item-content {
        text-align: left;
        padding: 0 20px;
        &-text {
          color: $black;
          font-size: 12px;
          line-height: 19px;
        }
      }
    }
  }
}
.group-list-segment {
  .card-segment {
    height: 100%;
    .data {
      @include font(xxl);
      font-weight: bold;
    }
  }
}

.change-segment-mode {
  display: flex;
  direction: ltr;
  justify-content: space-between;
  padding: 20px 28px 0;

  .icon-small-filter {
    display: inline-block;
  }

  .title-segment,
  .button-segment {
    font-weight: 700;
    cursor: pointer;
    color: $black;
    user-select: none;
  }

  .button-segment {
    color: $green;
  }
}
</style>
