<template>
  <highcharts
    id="chart-container"
    class="mt-2"
    :options="chartOptions"
    ref="barChart"
    :constructor-type="'chart'"
  ></highcharts>
</template>
<script>
import { Chart } from "highcharts-vue";
import EventBus from "@/commons/event_bus";
import {
  barChartOption,
  formatDataChartWithStepBarChart,
  getImageTooltipEl,
  getReactionTagTooltipEl,
  getTooltipLineChart,
  getXColumnsByStep,
} from "@/commons/charts";
import { addPointZero, interactionAvg, interactionMax, numberWithCommas, roundDecimal } from '@/commons/helpers';
import rounded from 'highcharts-rounded-corners';
import * as Highcharts from 'highcharts';
import jQuery from "jquery";

rounded(Highcharts)

export default {
  props: {
    changeStepChart: String,
    seriesData: Array,
    chartType: Object,
    currentTime: Number,
    dataReport: Object,
    imageURL: String,
    step: Number,
    part: Number,
    is25percent: Boolean,
    labelXaxis: Array,
    interactionType: Number,
    dataCognition: Object,
    youtubeRadioType: String,
    youtubeAudienceRatingReport: Array,
    project : Object,
    maxYCognition : Number
  },
  components: {
    highcharts: Chart,
  },
  watch: {
    step: function(newValue) {
      this.initSeriesData()
      this.changeStepOrTypeChart(newValue)
    },
    part: function (newValue, oldValue) {
      this.updateSeriesByPart(newValue, oldValue)
    },
    seriesData: {
      handler: function() {
        if(this.is25percent) {
          let labelXaxis = this.labelXaxis;
          this.chartOptions.plotOptions.column.pointWidth = 136;
          this.chartOptions.xAxis.grid = {
            columns: getXColumnsByStep(1, 0, 3),
          };
          this.chartOptions.xAxis.min = -0.2;
          this.chartOptions.xAxis.max = 3;
          this.chartOptions.xAxis.tickInterval = 1;
          this.chartOptions.xAxis.className = 'x-column-25';
          this.chartOptions.xAxis.labels = {
            useHTML: true,
            formatter: function () {
              let legendHtml = ''
              if(labelXaxis) {
                labelXaxis.forEach((data, index) => {
                  if (this.value == index) {
                    legendHtml = "<div class='label-x'><div>"+data.start+"</div><div>"+data.end+"</div></div>"
                  }
                })
              }
              return legendHtml
            },
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 'normal'
            }

          }
          this.initSeriesData();
        } else {
          this.chartOptions.plotOptions.column.pointWidth = 20;
          this.chartOptions.xAxis.className = 'null';
          this.chartOptions.xAxis.labels = {
            useHTML: true,
            formatter: function () {
              if (this.value >= 0) {
                return '<div>' + this.value + '</div>'
              } else return ''
            },
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 'normal'
            }

          }
          this.changeStepOrTypeChart(this.step)
          this.initSeriesData();
        }
      },
      deep: true
    },
    currentTime: function(newValue) {
      if (!this.is25percent) {
        this.currentSelected = newValue;
        let maxX = 15;
        let part = Math.ceil(newValue / (maxX  * this.step));
        let flg = Number.isInteger(Math.floor(newValue) / (15 * this.step));
        if ((part > this.$props.part && part > 0 && !flg) || (part < this.$props.part && part > 0)) {
          EventBus.$emit('changePart', part);
        }
        this.updateLineChart()
      }
    },
    changeTime: function(newValue) {
      if(!this.is25percent){
        EventBus.$emit('changeCurrentTime', newValue);
      }
    },
    youtubeRadioType: function() {
      this.setYoutubeAudienceRatingChart();
      this.initSeriesData();
      let vm = this;
      let youtubeData
      this.chartOptions.series.map(function(serie, index) {
        if (serie.name == "youtubeAudience") {
          youtubeData = serie
        }
        if (youtubeData) {
          vm.chart.series[index].remove();
          let seriesDataYoutube = vm.setYoutubeAudienceRatingChart()
          let maxRight = 0;
          for (let item of seriesDataYoutube.data) {
            if (maxRight < Math.abs(item)) {
              maxRight = Math.abs(item)
            }
          }

          vm.$nextTick(() => {
            vm.chart.yAxis[1].update({
              max: maxRight
            });
            vm.chart.yAxis[1].update();
            vm.chart.addSeries(seriesDataYoutube);
          })
        }
      })
    },
    isStackBar: function(){
      // this.initSeriesData();
    }
    
  },
  computed: {
    tick: {
      get: function() {
        return this.chartOptions.xAxis.tickInterval;
      },
      set: function(value) {
        this.chartOptions.xAxis.tickInterval = value;
      },
    },
    textInteraction(){
      return {
        rank_2 : this.project.interaction_btn_1 && Object.keys(this.project.interaction_btn_1) ? this.project.interaction_btn_1.text : '',
        rank_3 : this.project.interaction_btn_2 && Object.keys(this.project.interaction_btn_2) ? this.project.interaction_btn_2.text : ''
      }
    },
    isSummaryPercent() {
      return this.$props.chartType.name === "Percentage"
    },
  },
  data() {
    let currentSelected = 0;
    let prevStep = 1;
    let line;
    let currentWidth;
    let vm = this;
    let chartOptions = barChartOption;
    let tooltipEl;
    let lineXaxis;
    if (!this.is25percent){
      chartOptions.plotOptions.column.events = {
        click: function(e) {
          vm.legendClickCallback(e)
        }
      }
      chartOptions.xAxis.tickInterval = this.step;
      chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(this.step, (this.part - 1) * this.step * 15, 15),
      };
      chartOptions.xAxis.min = (this.part - 1) * this.step * 15;
      chartOptions.xAxis.tickInterval = this.step;
      chartOptions.xAxis.max =
        (this.part - 1) * this.step * 15 + this.step * 15.3;
      currentSelected = this.currentTime
      chartOptions.plotOptions.column.pointWidth = 20;
      chartOptions.xAxis.className = 'null';
      chartOptions.xAxis.labels = {
        useHTML: true,
        formatter: function () {
          return '<div>'+this.value+'</div>'
        },
        style: {
          color: '#999999',
          fontSize: '8px',
          fontWeight: 'normal'
        }
      }
    } else {
      let labelXaxis = this.labelXaxis;
      chartOptions.plotOptions.column.pointWidth = 136;
      chartOptions.xAxis.min = 0;
      chartOptions.xAxis.max = 3;
      chartOptions.xAxis.tickInterval = 1;
      chartOptions.xAxis.className = 'x-column-25';
      chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(this.step, 0, 3),
      };
      chartOptions.xAxis.labels = {
        useHTML: true,
        formatter: function () {
          let legendHtml = ''
          if(labelXaxis) {
            labelXaxis.forEach((data, index) => {
              if (this.value == index) {
                legendHtml = "<div class='label-x'><div>"+data.start+"</div><div>"+data.end+"</div></div>"
              }
            })
          }
          return legendHtml
        },
        style: {
          color: '#999999',
          fontSize: '8px',
          fontWeight: 'normal'
        }
      }
    }
    chartOptions.tooltip = {
      useHTML: true,
      crossHair: [false,true],
      formatter: function() {
        let second = this.key;
        let tooltipEl;
        if (vm.is25percent) {
          let data = {}
          data['left'] = numberWithCommas(Math.abs(vm.$props.dataReport.tally_result2[second].left))
          data['right'] = numberWithCommas(Math.abs(vm.$props.dataReport.tally_result2[second].right))
          let reactionTagsEl = getReactionTagTooltipEl(
            vm.$props.project,
            data
          );
          tooltipEl = '<div style="width: 69px; display: flex; flex-direction: column" class="tootlip-line">'
          +reactionTagsEl.left+reactionTagsEl.right + '</div>'
          return tooltipEl

        } else if (this.series.type == 'column') {
          let data =  {
            left: 0,
            right: 0
          }
          let report_tally_result
          if (vm.chartType.chart == "cognition") {
            report_tally_result = (vm.$props.dataCognition && vm.$props.dataCognition.tally_result)
                                  ? vm.$props.dataCognition.tally_result : []
          } else {
            report_tally_result = (vm.$props.dataReport && vm.$props.dataReport.tally_result)
                                  ? vm.$props.dataReport.tally_result : []
          }
          let tally_result = report_tally_result.find(
            (e) => e.second == second
          ) || { left: 0, right: 0 };
          data['left'] = numberWithCommas(Math.abs(tally_result['left']))
          data['right'] = numberWithCommas(Math.abs(tally_result['right']))
          let tally_left = Math.abs(tally_result['left']);
          let tally_right = Math.abs(tally_result['right'])
          if (vm.step == 2) {
            let tally_result_step_2 = report_tally_result.find(
              (e) => e.second == second + 1
            ) || { left: 0, right: 0 };
            data['left'] = numberWithCommas(tally_left + Math.abs(tally_result_step_2['left']))
            data['right'] = numberWithCommas(tally_right + Math.abs(tally_result_step_2['right']))
            tally_left = tally_left + Math.abs(tally_result_step_2['left']);
            tally_right = tally_right + Math.abs(tally_result_step_2['right']);
          }

          if (vm.step == 4) {
            let tally_result2 = report_tally_result.find(
              (e) => e.second == second + 1
            ) || { left: 0, right: 0 };
            let tally_result3 = report_tally_result.find(
              (e) => e.second == second + 2
            ) || { left: 0, right: 0 };
            let tally_result4 = report_tally_result.find(
              (e) => e.second == second + 3
            ) || { left: 0, right: 0 };
            data['left'] = numberWithCommas(tally_left + Math.abs(tally_result2['left']) + Math.abs(tally_result3['left']) + Math.abs(tally_result4['left']))
            data['right'] = numberWithCommas(tally_right + Math.abs(tally_result2['right']) + Math.abs(tally_result3['right']) + Math.abs(tally_result4['right']))
            tally_left = tally_left + Math.abs(tally_result2['left']) + Math.abs(tally_result3['left']) + Math.abs(tally_result4['left'])
            tally_right = tally_right + Math.abs(tally_result2['right']) + Math.abs(tally_result3['right']) + Math.abs(tally_result4['right'])
          }

          if (vm.step == 8) {
            let tally_result2 = report_tally_result.find(
              (e) => e.second == second + 1
            ) || { left: 0, right: 0 };
            let tally_result3 = report_tally_result.find(
              (e) => e.second == second + 2
            ) || { left: 0, right: 0 };
            let tally_result4 = report_tally_result.find(
              (e) => e.second == second + 3
            ) || { left: 0, right: 0 };
            let tally_result5 = report_tally_result.find(
              (e) => e.second == second + 4
            ) || { left: 0, right: 0 };
            let tally_result6 = report_tally_result.find(
              (e) => e.second == second + 5
            ) || { left: 0, right: 0 };
            let tally_result7 = report_tally_result.find(
              (e) => e.second == second + 6
            ) || { left: 0, right: 0 };
            let tally_result8 = report_tally_result.find(
              (e) => e.second == second + 7
            ) || { left: 0, right: 0 };
            data['left'] = numberWithCommas(tally_left + Math.abs(tally_result2['left']) + Math.abs(tally_result3['left']) + Math.abs(tally_result4['left']) + Math.abs(tally_result5['left']) + Math.abs(tally_result6['left']) + Math.abs(tally_result7['left']) + Math.abs(tally_result8['left']))
            data['right'] = numberWithCommas(tally_right + Math.abs(tally_result2['right']) + Math.abs(tally_result3['right']) + Math.abs(tally_result4['right']) + Math.abs(tally_result5['right']) + Math.abs(tally_result6['right']) + Math.abs(tally_result7['right']) + Math.abs(tally_result8['right']))
            tally_left = tally_left + Math.abs(tally_result2['left']) + Math.abs(tally_result3['left']) + Math.abs(tally_result4['left']) + Math.abs(tally_result5['left']) + Math.abs(tally_result6['left']) + Math.abs(tally_result7['left']) + Math.abs(tally_result8['left'])
            tally_right = tally_right + Math.abs(tally_result2['right']) + Math.abs(tally_result3['right']) + Math.abs(tally_result4['right']) + Math.abs(tally_result5['right']) + Math.abs(tally_result6['right']) + Math.abs(tally_result7['right']) + Math.abs(tally_result8['right'])
          }

          let imgEl = getImageTooltipEl(
            vm.imageURL + second + ".jpg"
          );
          if (vm.chartType.chart == "summary" && vm.$props.dataReport.interaction_tally) {
            let leftData = roundDecimal(tally_left,1);
            let rightData = roundDecimal(tally_right,1);

            if (vm.isSummaryPercent) {
              leftData = roundDecimal(leftData / vm.step, 1)
              rightData = roundDecimal(rightData / vm.step, 1)
            }

            data['left'] = addPointZero(String(leftData)) + '% '
            data['right'] = addPointZero(String(rightData)) + '% '
          }
          let reactionTagsEl = getReactionTagTooltipEl(
            vm.$props.project,
            data
          );
          tooltipEl = getTooltipLineChart(imgEl, reactionTagsEl);
          return tooltipEl
        } else {
          return ''
        }
      },
      borderWidth: 0,
      backgroundColor: 'transparent',
      shadow: false,
      padding:0,
      followPointer: false,
      shared:false,
      distance: 2,
      className: "tootlip-line",

    }
    return {
      chart: null,
      tooltipEl,
      currentSelected,
      prevStep,
      line,
      currentWidth,
      lineXaxis,
      changeTime: null,
      chartOptions: {
        chart: {
          type: 'column',
          height: "270px",
          animation: false,
          events: {
            load: function (e) {
              this.chart = e.chart
              vm.load(e);
            },
            redraw: function (e) {
              vm.redraw(e);
            },
          },
        },
        ...chartOptions,
      },
      pointline: {},
      isShowBorder: true,
      isStackBar : this.$store.getters.getStackBarValue,
      isSelectInteraction1 : true,
      isSelectInteraction2 : true,
      dataSum: {},
    };
  },
  methods: {
    handleSelectStack(isStack){
      this.isStackBar = isStack;
      this.$store.commit('updateStackBarValue', this.isStackBar)
    },
    load(e) {
      this.chart = e.target;
    },
    redraw(e) {
      this.chart = e.target;
    },
    handleChangeStep(step) {
      this.step = step;
    },
    selectLabel(labelChart, visible) {
      if ( labelChart == this.textInteraction.rank_2 ) {
        this.isSelectInteraction1 = visible;
      } else if ( labelChart == this.textInteraction.rank_3 ) {
        this.isSelectInteraction2 = visible;
      }
      if (!this.isStackBar) {
        this.chartOptions.series.forEach((serieData,index) => {
          if (serieData.name == labelChart) {
            this.chart.series[index].setVisible(visible)
            serieData.visible = visible
          }
        })
      } else {
        let isShowBorder = true
        this.chartOptions.series.forEach((serieData,index) => {
          this.chart.series[index].update({borderRadiusTopRight: '0', borderRadiusTopLeft: '0'});
          if (serieData.name == labelChart) {
            this.chart.series[index].setVisible(visible)
            serieData.visible = visible
          }
          if (isShowBorder && this.chart.series[index].visible) {
            isShowBorder = false;
            this.chart.series[index].update(
              {
                borderRadiusTopRight: (this.is25percent) ? '8' : '7',
                borderRadiusTopLeft: (this.is25percent) ? '8' : '7',
              });
          }
          if (this.chart.series[index].visible && this.chart.series[index].userOptions.isSerieRightBorderShow) {
            this.chart.series[index].update(
              {
                borderRadiusTopRight: (this.is25percent) ? '8' : '7',
                borderRadiusTopLeft: (this.is25percent) ? '8' : '7',
              });
          }
        })
      }
      if (!this.is25percent) {
        this.initPointLineData()
        this.setYmax()
      }
    },
    initSeriesData() {
      let series = [];
      let step = (this.step) ? this.step : 1;
      if (this.seriesData.length) {
        this.isShowBorder = true;
        let seriesDataLeft = (this.seriesData[0]) ? this.seriesData[0] : {};
        let seriesDataRight = (this.seriesData[1]) ? this.seriesData[1] : {};
        let dataLeft = [];
        let dataRight = [];
        if (seriesDataLeft) {
          dataLeft = (this.is25percent) ?
          formatDataChartWithStepBarChart(seriesDataLeft.data, 1) :
          formatDataChartWithStepBarChart(seriesDataLeft.data, step);
          if(this.isSummaryPercent) {
            dataLeft = dataLeft.map(function (item) {
              if(item.y) {
                item.y = Number(item.y) / step
              }
              return item
            })
          }
          let serieLeft = {
            type: 'column',
            stacking: 'normal',
            name: seriesDataLeft.name,
            data:dataLeft,
            color: seriesDataLeft.color,
            animation: false,
            border: seriesDataLeft.border,
            isSerieRightBorderShow: false,
            visible: seriesDataLeft.visible,
            yAxis: 0,
            states: {
                hover: {
                    opacity: 1,
                    lineWidth: 2,
                },
                inactive: {
                    opacity: 1
                }
            },
          };
          serieLeft = this.setBoderSeries(seriesDataLeft,serieLeft)
          series.push(serieLeft);
        }
        if (seriesDataRight) {
          dataRight = (this.is25percent) ?
          formatDataChartWithStepBarChart(seriesDataRight.data,1) :
          formatDataChartWithStepBarChart(seriesDataRight.data,step);
          if(this.isSummaryPercent) {
            dataRight = dataRight.map(function (item) {
              if(item.y) {
                item.y = Number(item.y) / step
              }
              return item
            })
          }
          let dataRight1 = [];
          let dataRight2 = [];
          if (!this.isStackBar) {
            dataRight1 = dataRight
          } else {
            dataRight.forEach((serieData,index) => {
              let data = dataLeft.find(e => e.x == serieData.x &&  e.y > 0)
              if (data) {
                dataRight1.push(dataRight[index])
              } else {
                dataRight2.push(dataRight[index])
              }
            })
          }
          let serieRight = {
            type: 'column',
            stacking: 'normal',
            name: seriesDataRight.name,
            color: seriesDataRight.color,
            border: seriesDataRight.border,
            animation: false,
            visible: seriesDataRight.visible,
            yAxis: 0,
            states: {
                hover: {
                    opacity: 1,
                    lineWidth: 2,
                },
                inactive: {
                    opacity: 1
                }
            },
          };
          serieRight = this.setBoderSeries(seriesDataRight,serieRight)

          let serieRight1 = {
            ...serieRight,
            isSerieRightBorderShow: false,
            data: dataRight1.length ? dataRight1 : []
          };
          series.push(serieRight1);

          let serieRight2 = {
            ...serieRight,
            data: dataRight2.length ? dataRight2 : [],
            isSerieRightBorderShow: true,
            borderRadiusTopLeft: (this.is25percent) ? '8' : '7',
            borderRadiusTopRight: (this.is25percent) ? '8' : '7'
          };
          series.push(serieRight2);
        }
      }
      let seriesDataYoutube = this.setYoutubeAudienceRatingChart()
      if (seriesDataYoutube && this.$props.chartType && !this.is25percent) {
        this.chartOptions.yAxis[1].visible = true
        series.push(seriesDataYoutube)
      } else if (!seriesDataYoutube) {
        this.chartOptions.yAxis[1].visible = false
      }
      this.chartOptions.series = series
      this.initPointLineData()
      this.setYmax()

      this.$nextTick(() => {
        jQuery('#chart-container .highcharts-point').attr('data-total-report', 'action')
        jQuery('.youtube-audience-series').attr('data-total-report', 'action')
      })
    },
    updateSeriesByPart(newValue, oldValue) {
      this.prevPart = oldValue;
      if (this.step == 8) {
        this.chartOptions.xAxis.min = -3.5;
      } else {
        this.chartOptions.xAxis.min = (newValue - 1) * this.step * 15;
      }
      this.chartOptions.xAxis.tickInterval = this.step;
      this.chartOptions.xAxis.max =
        (newValue - 1) * this.step * 15 + this.step * 15.3;
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(this.step, (newValue - 1) * this.step * 15, 15),
      };
      this.chartOptions.xAxis.plotLines[0].value = this.currentSelected
      this.initSeriesData();
    },
    setBoderSeries(serieData, series) {
       if (!this.isStackBar) {
        if (serieData.border == "top") {
          series.borderRadiusTopLeft = (this.is25percent) ? '8' : '7';
          series.borderRadiusTopRight = (this.is25percent) ? '8' : '7';
        }
        if (serieData.border == "bottom") {
          series.borderRadiusTopLeft = '0';
          series.borderRadiusTopRight = '0';
          series.borderRadiusBottomRight = (this.is25percent) ? '8' : '7';
          series.borderRadiusBottomLeft = (this.is25percent) ? '8' : '7';
        }
      } else {
        if( serieData.border == "none" ) {
          if (!this.isSelectInteraction1) {
            series.borderRadiusTopLeft = (this.is25percent) ? '8' : '7';
            series.borderRadiusTopRight = (this.is25percent) ? '8' : '7';
          }
          series.borderRadiusBottomLeft = '0';
          series.borderRadiusBottomRight = '0';
        }
        if (serieData.visible && this.isShowBorder) {
          this.isShowBorder = false;
          if (serieData.border == "top") {
            series.borderRadiusTopLeft = (this.is25percent) ? '8' : '7';
            series.borderRadiusTopRight = (this.is25percent) ? '8' : '7';
          }
        }
      }
      return series
    },
    initPointLineData() {
      if (!this.is25percent) {
        let data1 = (this.seriesData[0] && this.seriesData[0].visible) ? this.seriesData[0].data : []
        let data2 =  (this.seriesData[1] && this.seriesData[1].visible) ? this.seriesData[1].data : []
        let data_sum = this.formatyPointLine(data1,data2)
        const isOnlyInteraction2 = !this.isSelectInteraction1 && this.isSelectInteraction2 && !this.isStackBar
        this.dataSum = data_sum;
        if (Object.keys(data_sum).length) {
          let pointLine = [];
          let pointLineMax = {
            name: 'max',
            value: data_sum['max'] * (isOnlyInteraction2 ? -1 : 1),
            color: '#AB7249',
            zIndex: (data_sum['avg'] > data_sum['max']) ? 100 : 150,
            dashStyle: 'dash',
            label: {
                className: 'span-max-plot-line',
                text: '最大反応値',
                align: 'right',
                useHTML: true,
                formatter: function () {
                  return '<div class="max-plot-line">最大反応値</div>'
                },
            }
          }
          pointLine.push(pointLineMax)
          let pointLineAvg = {
            name: 'avg',
            value: data_sum['avg'] * (isOnlyInteraction2 ? -1 : 1),
            color: '#49AB94',
            zIndex: (data_sum['max'] > data_sum['avg']) ? 100 : 150,
            dashStyle: 'dash',
            label: {
                className: 'span-avg-plot-line',
                useHTML: true,
                formatter: function () {
                  return '<div class="avg-plot-line">平均反応値</div>'
                },
                text: '平均反応値',
                align: 'right',
            }
          }
          pointLine.push(pointLineAvg)
          this.chartOptions.yAxis[0].plotLines = pointLine
          if (this.$props.chartType && this.$props.chartType.chart == "summary") {
            EventBus.$emit('setMaAvgInteraction', data_sum['max'], data_sum['avg'])
          }
        } else {
          if (this.$props.chartType && this.$props.chartType.chart == "summary") {
            EventBus.$emit('setMaAvgInteraction', '-', '-')
          }
        }
      } else {
        this.chartOptions.yAxis[0].plotLines = []
      }
      if (this.is25percent) {
        this.chartOptions.xAxis.plotLines = []
      } else {
        this.chartOptions.xAxis.plotLines = [
          {
            className: "line-show-tooltip",
            color: "#999999",
            width: 1,
            value: this.currentSelected,
            zIndex: 4
          },
        ];
      }

      this.$nextTick(() => {
        this.chart.yAxis[0].update({
          plotLines: this.chartOptions.yAxis[0].plotLines,
        });
      }, 100);
    },
    formatyPointLine(data1,data2) {
      let sum_data = []
      if (data1.length >= data2.length) {
        for (let i = 0; i < data1.length; i++) {
          let value = 0
          value = (data2[i]) ? Math.abs(data1[i]) + Math.abs(data2[i]) : Math.abs(data1[i])
          sum_data.push(value)
        }
      } else {
        for (let i = 0; i < data2.length; i++) {
          let value = 0
          value = (data1[i]) ? Math.abs(data1[i]) + Math.abs(data2[i]) : Math.abs(data2[i])
          sum_data.push(value)
        }
      }
      let interaction_max = interactionMax(sum_data)
      let interaction_avg = interactionAvg(sum_data)
      let pointLine = {};
      if (!isNaN(interaction_max)) {
        pointLine['max'] = interaction_max
        // if chart is percentage, max value = max value / step
        if(this.isSummaryPercent) {
          pointLine['max'] = roundDecimal(pointLine['max'] / this.step, 1)
        }
      }
      if (!isNaN(interaction_avg)) {
        pointLine['avg'] = interaction_avg
        if(this.isSummaryPercent) {
          pointLine['avg'] = roundDecimal(pointLine['avg'] / this.step, 1)
        }
      }
      this.pointline = pointLine
      return pointLine
    },
    setYmax() {
      this.chartOptions.yAxis[0].endOnTick = true;
      this.chartOptions.yAxis[0].tickInterval = null;
      this.chartOptions.yAxis[0].tickPositioner = undefined;
      this.chartOptions.yAxis[0].min = null;
      if (!this.is25percent && !(this.$props.chartType.chart == "cognition")) {
        let sumTotalLeft = 0;
        let sumTotalRight = 0;
        if (this.chartOptions.series.length) {
          this.chartOptions.series.forEach((serieData) => {
            if (serieData.type == 'column') {
              let maxLeft = 0
              for (let item of serieData.data) {
                if (maxLeft < Math.abs(item['y'])) {
                  maxLeft = Math.abs(item['y'])
                }
              }
              sumTotalLeft += maxLeft
            } else if (serieData.type == 'line') {
              let maxRight = 0
              for (let item of serieData.data) {
                if (maxRight < Math.abs(item)) {
                  maxRight = Math.abs(item)
                }
              }
              sumTotalRight += maxRight
            }
          })
        }
        if (this.$props.step == 8) {
          if (sumTotalLeft <= this.pointline.max) {
            this.chartOptions.yAxis[0].max = this.pointline.max;
          } else {
            this.chartOptions.yAxis[0].max = sumTotalLeft;
          }
        } else {
          this.chartOptions.yAxis[0].max = sumTotalLeft;
          this.chartOptions.yAxis[0].alignTicks = false
        }
        this.chartOptions.yAxis[1].max = sumTotalRight
        this.chartOptions.yAxis[1].min = 0
        this.chartOptions.yAxis[1].alignTicks = false

        // config for Interaction chart
        if (this.$props.chartType.name === 'Reaction' || this.isSummaryPercent) {
          let maxValue = this.getMaxYValueSeries(JSON.parse(JSON.stringify(this.chartOptions.series)));
          if (!this.isStackBar) {
            maxValue = this.getMaxValueOneSeries(this.chartOptions.series[0].data)
          }
          if (this.dataSum && this.$props.step == 1) {
            this.chartOptions.yAxis[0].max = this.dataSum.max
          } else {
            this.chartOptions.yAxis[0].max = this.dataSum.max > maxValue ? this.dataSum.max : maxValue;
          }

          if (!this.isStackBar && !this.isSelectInteraction1 && this.isSelectInteraction2) {
            const minValue = this.getMinValueOneSeries(this.chartOptions.series[1].data)
            if (this.dataSum) {
              this.chartOptions.yAxis[0].min = (this.dataSum.max * -1) < minValue ? (this.dataSum.max * -1) : minValue;
            }
            this.chartOptions.yAxis[0].max = 0;
          } else if (!this.isStackBar && this.isSelectInteraction1 && !this.isSelectInteraction2) {
            this.chartOptions.yAxis[0].min = 0;
          }
        }

      } else if(this.$props.chartType.chart == "cognition") {
        if(this.isStackBar){
          this.chartOptions.yAxis[0].max = this.maxYCognition
        } else {
          this.chartOptions.yAxis[0].tickInterval = this.maxYCognition > 10 ? 5 : 2
          this.chartOptions.yAxis[0].endOnTick = false;
          this.chartOptions.yAxis[0].max = this.maxYCognition
          this.chartOptions.yAxis[1].max = 0
        }
      } else {
        this.chartOptions.yAxis[0].max = null
        this.chartOptions.yAxis[1].max = null
      }

      this.$nextTick(() => {
        this.chart.yAxis[0].update({
          max: this.chartOptions.yAxis[0].max,
          min: this.chartOptions.yAxis[0].min,
        });
      }, 100);
    },
    getMaxYValueSeries(series) {
      let dataTotal = [];
      if (series.length) {
        series.forEach(function (item) {
          if(item.type === 'column' && item.visible) {
            item.data.forEach(function (value, index) {
              if (!dataTotal[index]) {
                dataTotal[index] = value
              } else if (dataTotal[index]['x'] === value['x']) {
                dataTotal[index]['y'] += value['y']
              }
            })
          }
        })
      }
      return roundDecimal(Math.max(...dataTotal.map(value => value['y'])), 1)
    },
    getMaxValueOneSeries(seriesData) {
      return roundDecimal(Math.max(...seriesData.map(value => value['y'])), 1)
    },
    getMinValueOneSeries(seriesData) {
      return roundDecimal(Math.min(...seriesData.map(value => value['y'])), 1)
    },
    updateLineChart() {
      let chart = this.chart;
      chart.xAxis[0].options.plotLines[0].value = this.currentSelected;
      chart.xAxis[0].update();
    },
    legendClickCallback(e) {
      this.changeTime = e.point.category
    },
    setYoutubeAudienceRatingChart() {
      let youtubeData = this.$props.youtubeAudienceRatingReport;
      if (youtubeData) {
        let youtubeRatingData = youtubeData.map(data => data.rate);
        this.isShowBorder = true;
        let seriesYoutube = {
          className: 'youtube-audience-series',
          type: 'line',
          pointRadius: 0,
          stacking: 'normal',
          name: 'youtubeAudience',
          data: youtubeRatingData,
          color: '#49AB94',
          animation: false,
          isSerieRightBorderShow: false,
          visible: true,
          yAxis: 1,
          states: {
              hover: {
                  opacity: 1,
                  lineWidth: 2,
              },
              inactive: {
                  opacity: 1
              },
          },
          marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: false
                }
              }
          }
        };
        return seriesYoutube
      } else {
        return null
      }
    },
    changeStepOrTypeChart(newValue) {
      if (!this.is25percent) {
        this.chartOptions.xAxis.tickInterval = newValue;
        this.chartOptions.xAxis.grid = {
          columns: getXColumnsByStep(newValue, 0, 15),
        };
        switch (newValue) {
          case 2:
            this.chartOptions.xAxis.min = -0.5;
            break
          case 4:
            this.chartOptions.xAxis.min = -1.5;
            break
          case 8:
            this.chartOptions.xAxis.min = -3.5;
            break
          default:
            if (this.chartType.chart == "cognition") {
              this.chartOptions.xAxis.min = 0;
            } else {
              this.chartOptions.xAxis.min = -0.1;
            }
            break
        }
        this.chartOptions.xAxis.max = newValue * 15.3;
        this.tick = newValue;
      }
    }
  },
  mounted() {
    this.initSeriesData();
    this.changeStepOrTypeChart(this.step)
  },
  created() {
    this.initSeriesData();
    EventBus.$on('selectLabel', this.selectLabel);
    EventBus.$on('selectedLabelStack', this.handleSelectStack);
  },
  beforeDestroy() {
    EventBus.$off('selectLabel', this.selectLabel);
    EventBus.$off('selectedLabelStack', this.handleSelectStack);
  }
};
</script>
<style lang="scss">
@import '@/styles/variable';
.x-column-25 {
  // top: 18px!important;
  .label-x {
      display: flex;
      justify-content: space-between;
      width: 136px;
      color: $middle-black;
      @include font(s);
  }
}
.span-max-plot-line {
  z-index: 150;
  .max-plot-line {
    background-color: $orange;
    color: $white;
    border-radius: 2px;
    height: 12px;
    padding: 0px 1px 0px 1px;
    @include font(xs);
    line-height: 12px;
    margin-top: 8px;
  }
}
.span-avg-plot-line {
  z-index: 100;
  .avg-plot-line {
    background-color: $green;
    color: $white;
    border-radius: 2px;
    height: 12px;
    padding: 1px 1px 0px 1px;
    @include font(xs);
    line-height: 12px;
    margin-top: 8px;
  }
}
</style>
