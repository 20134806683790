<!-- eslint-disable vue/no-side-effects-in-computed-properties -->
<template>
  <highcharts
    class="mt-2 screening-option"
    :options="chartOptions"
    ref="BasicColumnChart"
    :constructor-type="'chart'"
  ></highcharts>
</template>
<script>
import { Chart } from 'highcharts-vue'

const jQuery = require('jquery')
import EventBus from '@/commons/event_bus'
import { roundDecimal, roundNumberLabelChart } from '@/commons/helpers'
import {
  formatDataChartWithStepBarChart,
  getImageTooltipEl,
  getReactionTagTooltipEl,
  getTooltipLineChart,
} from '@/commons/charts'

export default {
  components: {
    highcharts: Chart,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    part: Number,
    reportScreening: [Object, Array],
    answerValueIdSelected: Array,
    selectedAnswerId: [Number, String],
    answerValueSelected: Array,
    seriesData: Array,
    selectedId: String,
    dataReport: [Object, Array],
    dataReportSummary: Object,
    project: Object,
    imageURL: String,
    questionIdSelected: [Number, String],
  },
  watch: {
    step(newVal) {
      let vm = this
      this.chartOptions.xAxis.min = 0
      this.chartOptions.xAxis.labels.formatter = function () {
        if (vm.part > 1) return this.value % 2 ? ((this.value + 1) * vm.step + (vm.part - 1) * 16 * vm.step) / 2 : ''
        return this.value % 2 ? ((this.value + 1) * newVal) / 2 : ''
      }
      this.chartOptions.xAxis.min = -0.5
      this.nextPartCheck()
      this.formatDataChartWithStep()
    },
    maxSecond() {
      this.nextPartCheck()
    },
    part() {
      this.nextPartCheck()
      this.formatDataChartWithStep()
    },
    answerValueIdSelected() {
      if (!this.questionIdSelected) return
      this.formatDataChartWithStep()
    },
    selectedAnswerId() {
      if (!this.questionIdSelected) return
      this.initSeriesData()
    },
    selectedId: function () {
      if (this.questionIdSelected) return
      this.initSeriesData()
    },
    listAttributeId() {
      if (this.questionIdSelected) return
      this.initSeriesData()
    },
  },
  data() {
    let vm = this
    return {
      maxSecond: 8,
      chartOptions: {
        chart: {
          height: '340px',
          type: 'column',
          animation: false,
        },
        legend: {
          enabled: false,
        },
        title: {
          text: '',
        },
        tooltip: {
          useHTML: true,
          crossHair: [false, true],
          formatter: function () {
            return vm.formatTooltip(vm, this)
          },
          borderWidth: 0,
          backgroundColor: 'transparent',
          shadow: false,
          padding: 0,
          followPointer: false,
          shared: false,
          distance: 2,
          className: 'tootlip-line',
        },
        plotOptions: {
          column: {
            pointWidth: window.innerWidth > 1600 ? 10 : 6,
          },
          series: {
            borderRadiusTopRight: 8,
            borderRadiusTopLeft: 8,
            pointPadding: 0.2,
            borderWidth: 0,
            animation: false,
            point: {
              events: {
                click: function (e) {
                  if (vm.questionIdSelected) {
                    EventBus.$emit('selectedAnswerChart', e.target.point.series.userOptions.id)
                  } else {
                    let idString = e.target.point.series.userOptions.id
                    let id = idString.split('-')[1]
                    if (id) {
                      EventBus.$emit('selectedChart', id)
                    }
                  }
                },
                mouseOver: function () {
                  vm.$nextTick(() => {
                    let elementHover = jQuery('.highcharts-point-hover')
                    elementHover.css('fill', '#49AB94')
                    elementHover.siblings().css('fill', '#49AB94')
                    this.series.data.forEach(function (point) {
                      point.update({
                        opacity: 1,
                      })
                    })
                  })
                },
                mouseOut: function () {
                  vm.$nextTick(() => {
                    jQuery('.highcharts-point').removeAttr('style')
                    this.series.data.forEach(function (point) {
                      point.update({
                        opacity: 1,
                      })
                    })
                  })
                },
              },
            },
          },
        },
        xAxis: {
          min: -0.5,
          max: 15,
          tickWidth: 0,
          lineColor: '#FFFFFF',
          gridLineColor: '#EBEBEB',
          gridLineWidth: 1,
          labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 500,
              lineHeight: '12px',
            },
            formatter: function () {
              if (vm.part > 1)
                return this.value % 2 ? ((this.value + 1) * vm.step + (vm.part - 1) * 16 * vm.step) / 2 : ''
              return this.value % 2 ? ((this.value + 1) * vm.step) / 2 : ''
            },
          },
        },
        yAxis: {
          title: {
            text: '',
          },
          lineWidth: 1,
          lineColor: '#EBEBEB',
          gridLineWidth: 0,
          ignoreMaxPadding: true,
          min: 0,
          labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 500,
              lineHeight: '12px',
            },
            formatter: function () {
              return this.value <= 100 ? roundDecimal(this.value, 1) + '%' : ''
            },
          },
        },
        series: [],
      },
    }
  },
  computed: {
    dataSegmentFormatted() {
      if (!this.dataReport) return null
      let listAttributeValues = this.dataReport.attribute_value_ids
      let reportData
      if (listAttributeValues == 'attrValue-all') {
        reportData = this.dataReportSummary?.tally_result
      } else {
        reportData = this.dataReport.data
      }

      return this.formatDataChart(reportData, listAttributeValues, 'attribute_value_id')
    },
    dataSegmentAllFormatted() {
      if (!this.dataReportSummary) return null
      let listAttributeValues = 'attrValue-all'
      let reportData = this.dataReportSummary?.tally_result
      return this.formatDataChart(reportData, listAttributeValues, 'attribute_value_id')
    },
    dataScreeningAllFormatted() {
      let listAnswerId = 'attrValue-all'
      let reportData = this.dataReportSummary?.tally_result
      return this.formatDataChart(reportData, listAnswerId, 'screening_answer_id')
    },
    dataScreeningFormatted() {
      let listAnswerId = this.reportScreening.screening_answer_id
      let reportData = this.reportScreening.data
      return this.formatDataChart(reportData, listAnswerId, 'screening_answer_id')
    },
    listAttributeId() {
      let listAttributeId = []
      if (this.seriesData && this.seriesData.length) {
        this.seriesData.map(attribute => {
          listAttributeId.push(attribute.id)
        })
      }
      return listAttributeId
    },
  },
  methods: {
    initJQuery() {
      let grids = jQuery('.screening-option').find('.highcharts-xaxis-grid').last().children()
      for (let i = 0; i < 16; i++) {
        if (i % 2) grids.eq(i).css('display', 'none')
      }

      jQuery('#chart-segment .highcharts-point').attr('data-report', 'action')
    },
    initSeriesData() {
      let seriesData = null
      if (!this.dataReport || (this.dataReport && !this.dataReport.data.length)) {
        // reset chart to default
        this.chartOptions.series = {
          data: [0, 0, 0, 0, 0, 0, 0, 0],
        }
        this.chartOptions.yAxis.max = 0.5
        this.chartOptions.yAxis.tickInterval = 0.5
        this.$nextTick(() => {
          this.initJQuery()
        })
        return
      }
      if (this.reportScreening && this.questionIdSelected) {
        seriesData = this.reportScreening.data
      } else {
        seriesData = this.dataReport.data
      }

      if (seriesData && seriesData.length) {
        this.formatDataChartWithStep(seriesData)
      }
    },
    formatDataChartWithStep() {
      if (!this.questionIdSelected) {
        this.formatDataChartSegmentWithStep()
      } else {
        this.formatDataChartScreeningWithStep()
      }
      this.setYMax()
    },
    formatDataChartScreeningWithStep() {
      if (!this.questionIdSelected) return
      let series = []
      if (
        this.answerValueIdSelected &&
        this.answerValueIdSelected.length &&
        Object.keys(this.dataScreeningFormatted).length
      ) {
        this.answerValueIdSelected.map(val => {
          let data
          if (val == 'all') {
            data = this.dataScreeningAllFormatted[val]
          } else {
            data = this.dataScreeningFormatted[val]
          }

          if (!data) return
          series.push({
            id: val,
            color: this.selectedAnswerId == val ? '#49AB94' : '#EDF7F4',
            data: data['total'],
          })
        })
      } else {
        for (let i = 0; i < 5; i++) {
          let valArr = []
          for (let i = 1; i <= 16; i++) {
            valArr.push(0)
          }
          series.push({ data: valArr })
        }
      }

      this.chartOptions.plotOptions.series.groupPadding = 0.5 - 0.1 * series.length
      this.chartOptions.series = series
      this.$nextTick(() => {
        this.initJQuery()
      })
    },
    formatDataChartSegmentWithStep() {
      if (this.questionIdSelected) return
      let series = []
      if (
        this.seriesData &&
        this.seriesData.length &&
        this.dataScreeningFormatted &&
        this.dataSegmentFormatted &&
        Object.keys(this.dataSegmentFormatted).length
      ) {
        this.seriesData.map(val => {
          let attributeValueId = val.id.split('-')[1]
          let data
          if (attributeValueId == 'all') {
            data = this.dataSegmentAllFormatted[attributeValueId]
          } else {
            data = this.dataSegmentFormatted[attributeValueId]
          }
          if (!data) return
          series.push({
            id: val.id,
            color: this.selectedId == val.id ? '#49AB94' : '#EDF7F4',
            data: data['total'],
          })
        })
      } else {
        for (let i = 0; i < 5; i++) {
          let valArr = []
          for (let i = 1; i <= 16; i++) {
            valArr.push(0)
          }
          series.push({ data: valArr })
        }
      }

      this.chartOptions.plotOptions.series.groupPadding = 0.5 - 0.1 * this.seriesData.length
      this.chartOptions.series = series
      this.$nextTick(() => {
        this.initJQuery()
      })
    },
    formatDataChart(reportData, listId, keyName) {
      // format data for each id
      let formattedData = {}
      if (!listId) return formattedData
      if (listId == 'attrValue-all') {
        if (reportData.length) {
          let result = []
          reportData.forEach(data => {
            let attrData
            if (data) {
              attrData = {}
              attrData['second'] = data.second
              attrData['left_count'] = data.left
              attrData['right_count'] = data.right
              result.push(attrData)
            } else {
              attrData = {}
              attrData['second'] = data.second
              attrData['left_count'] = 0
              attrData['right_count'] = 0
              result.push(attrData)
            }
          })

          formattedData['all'] = this.formatSeriesDataArray(result)
        }
      } else {
        listId.forEach(id => {
          let result = []
          if (reportData.length) {
            reportData.forEach(data => {
              let attrData
              attrData = data.values.find(e => id == e[keyName])
              if (attrData) {
                attrData['second'] = data.second
                result.push(attrData)
              } else {
                attrData = {}
                attrData['second'] = data.second
                attrData['left_count'] = 0
                attrData['right_count'] = 0
                result.push(attrData)
              }
            })

            formattedData[id] = this.formatSeriesDataArray(result)
          }
        })
      }

      return formattedData
    },
    formatSeriesDataArray(reportData) {
      let result = {}
      // format to get left_count, right_count, total
      if (reportData) {
        result['left_count'] = reportData.map(e => Math.abs(e.left_count))
        result['right_count'] = reportData.map(e => Math.abs(e.right_count))
        result['total'] = reportData.map(e => Math.abs(e.left_count) + Math.abs(e.right_count))
      }

      // format to data in chart
      let minSecond = (this.part - 1) * this.step * 8
      let maxSecond = this.part * this.step * 8
      let leftData = [],
        rightData = [],
        totalData = []
      let formattedLeftCount = formatDataChartWithStepBarChart(result['left_count'], this.step)
      let formattedRightCount = formatDataChartWithStepBarChart(result['right_count'], this.step)
      let formattedTotal = formatDataChartWithStepBarChart(result['total'], this.step)
      for (let i = minSecond; i <= maxSecond - this.step; i += this.step) {
        let valueLeftCount = formattedLeftCount.find(e => e.x == i)
        if (valueLeftCount) {
          leftData.push(valueLeftCount.y / this.step)
        } else {
          leftData.push(0)
        }

        let valueRightCount = formattedRightCount.find(e => e.x == i)
        if (valueRightCount) {
          rightData.push(valueRightCount.y / this.step)
        } else {
          rightData.push(0)
        }

        let valueTotal = formattedTotal.find(e => e.x == i)
        if (valueTotal) {
          totalData.push(valueTotal.y / this.step)
        } else {
          totalData.push(0)
        }
      }

      result['left_count'] = leftData
      result['right_count'] = rightData
      result['total'] = totalData
      result['all_total'] = formattedTotal.map(e => e.y / this.step)

      // add zero point to make chart
      let valueTotal = result['total']
      let valueConvert = []
      for (let i = 0; i < valueTotal.length; i++) {
        valueConvert.push(valueTotal[i])
        if (i < valueTotal.length - 1) {
          valueConvert.push(0)
        }
      }
      result['total'] = valueConvert

      return result
    },
    formatTooltip(vm, tooltip) {
      if (tooltip.key % 2 != 0 || vm.step !== 1) return ''
      let position = tooltip.key / 2
      if (
        (tooltip.series.userOptions.id != vm.selectedId && !vm.questionIdSelected) ||
        (tooltip.series.userOptions.id != vm.selectedAnswerId && vm.questionIdSelected)
      )
        return ''

      let tooltipEl
      if (tooltip.series.type == 'column') {
        let data = {
          left: 0,
          right: 0,
        }

        let second = Math.floor(((tooltip.key + 1) * vm.step + (vm.part - 1) * 16 * vm.step) / 2)
        let imgEl = getImageTooltipEl(vm.imageURL + second + '.jpg')

        let leftData, rightData
        if (!vm.questionIdSelected) {
          let attributeValueId = tooltip.series.userOptions.id.split('-')[1]
          if (attributeValueId == 'all') {
            leftData = roundDecimal(vm.dataSegmentAllFormatted[attributeValueId]['left_count'][position], 1)
            rightData = roundDecimal(vm.dataSegmentAllFormatted[attributeValueId]['right_count'][position], 1)
          } else {
            leftData = roundDecimal(vm.dataSegmentFormatted[attributeValueId]['left_count'][position], 1)
            rightData = roundDecimal(vm.dataSegmentFormatted[attributeValueId]['right_count'][position], 1)
          }
        } else {
          let answerValueId = tooltip.series.userOptions.id
          if (answerValueId == 'all') {
            leftData = roundDecimal(vm.dataScreeningAllFormatted[answerValueId]['left_count'][position], 1)
            rightData = roundDecimal(vm.dataScreeningAllFormatted[answerValueId]['right_count'][position], 1)
          } else {
            leftData = roundDecimal(vm.dataScreeningFormatted[answerValueId]['left_count'][position], 1)
            rightData = roundDecimal(vm.dataScreeningFormatted[answerValueId]['right_count'][position], 1)
          }
        }

        data['left'] = String(leftData) + '% '
        data['right'] = String(rightData) + '% '

        let reactionTagsEl = getReactionTagTooltipEl(vm.$props.project, data)
        tooltipEl = getTooltipLineChart(imgEl, reactionTagsEl)
        return tooltipEl
      } else {
        return ''
      }
    },
    setYMax() {
      if (!this.dataReport) return
      this.maxSecond = 0
      let maxValue = 0.5
      if (this.questionIdSelected && this.answerValueIdSelected.length) {
        this.answerValueIdSelected.map(answer => {
          let listValue = []
          if (answer == 'all') {
            this.dataReportSummary?.tally_result.forEach(data => {
              let attrData
              attrData = data
              if (attrData && (attrData['left'] != 0 || attrData['right'] != 0)) {
                attrData['second'] = data.second
                listValue.push(attrData)
              }
            })
          } else {
            this.reportScreening.data.forEach(data => {
              let attrData
              attrData = data.values.find(e => answer == e['screening_answer_id'])
              if (attrData && (attrData['left_count'] != 0 || attrData['right_count'] != 0)) {
                attrData['second'] = data.second
                listValue.push(attrData)
              }
            })
          }

          // change maxSecond
          if (listValue.length == 0) return
          let maxSecond = Math.max(...listValue.map(e => e['second']))
          if (this.maxSecond < maxSecond) this.maxSecond = maxSecond

          // get max value
          let max
          if (answer == 'all') {
            max = Math.max(...this.dataScreeningAllFormatted[answer]['all_total'])
          } else {
            max = Math.max(...this.dataScreeningFormatted[answer]['all_total'])
          }
          if (maxValue < max) maxValue = max
        })
      } else if (this.seriesData && this.seriesData.length) {
        this.seriesData.map(attribute => {
          let listValue = []
          const attributeId = attribute.id.split('-')[1]

          let attrData
          //1429
          if (attributeId == 'all') {
            this.dataReportSummary?.tally_result.forEach(data => {
              attrData = data
              if (attrData && (attrData['left'] != 0 || attrData['right'] != 0)) {
                attrData['second'] = data.second
                listValue.push(attrData)
              }
            })
          } else {
            if (this.dataReport.data.length && Array.isArray(this.dataReport.data))
              this.dataReport.data.forEach(data => {
                attrData = data.values.find(e => attributeId == e['attribute_value_id'])
                if (attrData && (attrData['left_count'] != 0 || attrData['right_count'] != 0)) {
                  attrData['second'] = data.second
                  listValue.push(attrData)
                }
              })
          }

          // change maxSecond
          if (listValue.length == 0) return
          let maxSecond = Math.max(...listValue.map(e => e['second']))
          if (this.maxSecond < maxSecond) this.maxSecond = maxSecond

          let max
          // get max value
          //1429
          if (attributeId == 'all') {
            max = Math.max(...this.dataSegmentAllFormatted[attributeId]['all_total'])
          } else {
            max = Math.max(...this.dataSegmentFormatted[attributeId]['all_total'])
          }
          if (maxValue < max) maxValue = max
        })
      }

      // set maxY for chart
      if (maxValue > 0) {
        const { max, interval } = roundNumberLabelChart(maxValue)
        this.chartOptions.yAxis.max = max <= 100 ? max : 100
        this.chartOptions.yAxis.tickInterval = interval
      }
      this.chartOptions.xAxis.tickInterval = 1
    },
    nextPartCheck() {
      if (this.maxSecond >= this.part * this.step * 8) EventBus.$emit('nextPartActive', true)
      else EventBus.$emit('nextPartActive', false)
    },
  },
  mounted() {
    this.initSeriesData()
  },
  created() {},
}
</script>
<style lang="scss" scoped>
::v-deep {
  .highcharts-credits {
    display: none;
  }

  .highcharts-series {
    opacity: 1;
  }

  .highcharts-container {
    margin-top: 10px;
  }
}
</style>
