<template>
  <highcharts class="mt-2" :options="chartOptions" ref="StackColumnChart" :constructor-type="'chart'"></highcharts>
</template>
<script>
import { Chart } from 'highcharts-vue'
import EventBus from '@/commons/event_bus'
import {
  formatDataChartWithStepBarChart,
  getImageTooltipEl,
  getReactionTagTooltipEl,
  getTooltipLineChart,
} from '@/commons/charts'
import { roundDecimal, roundNumberLabelChart } from '@/commons/helpers'
const jQuery = require('jquery')

export default {
  components: {
    highcharts: Chart,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    part: Number,
    seriesData: Array,
    selectedId: Number,
    intentionLevelSelected: Array,
    dataReport: Object,
    project: Object,
    imageURL: String,
    dataReportSummary: Object,
  },
  watch: {
    step(newVal) {
      let vm = this
      this.chartOptions.xAxis.min = 0
      this.chartOptions.xAxis.labels.formatter = function () {
        if (vm.part > 1) return this.value % 2 ? ((this.value + 1) * vm.step + (vm.part - 1) * 16 * vm.step) / 2 : ''
        return this.value % 2 ? ((this.value + 1) * newVal) / 2 : ''
      }
      this.chartOptions.xAxis.min = -0.5
      this.nextPartCheck()
      this.formatDataChartWithStep()
    },
    maxSecond() {
      this.nextPartCheck()
    },
    part() {
      this.nextPartCheck()
      this.formatDataChartWithStep()
    },
    intentionLevelSelected() {
      this.initSeriesData()
    },
    selectedId: function () {
      this.initSeriesData()
    },
    seriesData: function () {
      this.initSeriesData()
    },
  },
  data() {
    let vm = this
    return {
      maxVal: 40,
      minVal: 0,
      maxSecond: 8,
      interactionBtnColor: this.$store.getters.interactioncolors,
      chartOptions: {
        chart: {
          height: '340px',
          type: 'column',
          animation: false,
        },
        legend: {
          enabled: false,
        },
        title: {
          text: '',
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            return vm.formatTooltip(vm, this)
          },
          borderWidth: 0,
          backgroundColor: 'transparent',
          shadow: false,
          padding: 0,
          followPointer: false,
          shared: false,
          distance: 2,
          className: 'tootlip-line',
        },
        plotOptions: {
          column: {
            pointWidth: window.innerWidth > 1600 ? 10 : 6,
            stacking: 'normal',
            borderColor: '#EBEBEB',
          },
          series: {
            pointPadding: 0.2,
            borderWidth: 0,
            animation: false,
            point: {
              events: {
                click: function (e) {
                  let idString = e.target.point.series.userOptions.stack
                  if (idString || idString === 0) {
                    EventBus.$emit('selectedChart', idString)
                  }
                },
                mouseOver: function (e) {
                  const currentStack = e.target.series.userOptions.stack
                  vm.chartOptions.series.forEach(function (otherSeries) {
                    if (otherSeries.stack === currentStack) {
                      otherSeries.opacity = 1
                    }
                  })
                },
                mouseOut: function (e) {
                  const currentStack = e.target.series.userOptions.stack
                  if (currentStack != vm.selectedId) {
                    vm.chartOptions.series.forEach(function (otherSeries) {
                      if (otherSeries.stack === currentStack) {
                        otherSeries.opacity = 0.07
                      }
                    })
                  }
                },
              },
            },
          },
        },
        xAxis: {
          min: -0.5,
          max: 15,
          tickWidth: 0,
          lineColor: '#EBEBEB',
          labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 500,
              lineHeight: '12px',
            },
            formatter: function () {
              if (vm.part > 1)
                return this.value % 2 ? ((this.value + 1) * vm.step + (vm.part - 1) * 16 * vm.step) / 2 : ''
              return this.value % 2 ? ((this.value + 1) * vm.step) / 2 : ''
            },
          },
        },
        yAxis: {
          title: {
            text: '',
          },
          lineWidth: 1,
          lineColor: '#EBEBEB',
          gridLineWidth: 1,
          gridLineColor: '#EBEBEB',
          ignoreMaxPadding: true,
          labels: {
            style: {
              color: '#999999',
              fontSize: '8px',
              fontWeight: 500,
              lineHeight: '12px',
            },
            formatter: function () {
              return this.value <= 100 && this.value >= -100 ? roundDecimal(Math.abs(this.value), 1) + '%' : ''
            },
          },
        },
        series: [],
      },
    }
  },
  computed: {
    dataScreeningFormatted() {
      return this.formatDataChart(this.seriesData, this.intentionLevelSelected)
    },
    isGoodBad() {
      return (
        this.project && this.project.interaction_btn_1.text == 'Good' && this.project.interaction_btn_2.text == 'Bad'
      )
    },
  },
  methods: {
    initSeriesData() {
      this.formatDataChartWithStep()
    },
    formatDataChartWithStep() {
      this.formatDataChartScreeningWithStep()
      this.setYMax()

      this.$nextTick(function () {
        jQuery('#chart-report-intention .highcharts-point').attr('data-report', 'action')
      })
    },
    formatDataChartScreeningWithStep() {
      let series = []
      if (this.intentionLevelSelected && this.intentionLevelSelected.length) {
        this.intentionLevelSelected.map(val => {
          let data = this.dataScreeningFormatted[val]
          if (!data) return
          // this function to border below column when behind column is zero
          let dataReformat = this.reformatDataChart(data)
          series.push({
            color: this.interactionBtnColor[0],
            opacity: this.selectedId == val ? 1 : 0.1,
            data: dataReformat['left_count'],
            stack: val,
            borderRadiusTopRight: 8,
            borderRadiusTopLeft: 8,
            borderWidth: 1,
          })
          series.push({
            color: this.interactionBtnColor[1],
            opacity: this.selectedId == val ? 1 : 0.07,
            data: dataReformat['right_count'],
            stack: val,
            borderRadiusBottomRight: this.isGoodBad ? 8 : 0,
            borderRadiusBottomLeft: this.isGoodBad ? 8 : 0,
            borderWidth: 1,
          })
          // add border below column when behind column is zero
          series.push({
            color: this.interactionBtnColor[1],
            opacity: this.selectedId == val ? 1 : 0.07,
            data: dataReformat['reformat'],
            stack: val,
            borderRadiusTopRight: 8,
            borderRadiusTopLeft: 8,
            borderWidth: 1,
          })
        })
      } else {
        for (let i = 0; i < 5; i++) {
          let valArr = []
          for (let i = 1; i <= 16; i++) {
            valArr.push(0)
          }
          series.push({ data: valArr })
        }
      }

      this.chartOptions.plotOptions.series.groupPadding = 0.5 - 0.1 * this.intentionLevelSelected.length
      this.chartOptions.series = series
    },
    formatDataChart(reportData, listId) {
      // format data for each id
      let formattedData = {}
      listId.forEach(id => {
        let result = []
        if (reportData.length) {
          reportData.forEach(report => {
            if (report.id == id) {
              report.data.forEach(data => {
                let attrData = {}
                attrData['second'] = data[0]
                attrData['left_count'] = data[1]
                attrData['right_count'] = data[2]
                result.push(attrData)
              })
            }
          })

          formattedData[id] = this.formatSeriesDataArray(result)
        }
      })
      return formattedData
    },
    formatSeriesDataArray(reportData) {
      let result = {}
      // format to get left_count, right_count
      if (reportData) {
        result['left_count'] = reportData.map(e => Math.abs(e.left_count))
        result['right_count'] = reportData.map(e => Math.abs(e.right_count))
      }

      // format to data in chart
      let minSecond = (this.part - 1) * this.step * 8
      let maxSecond = this.part * this.step * 8
      let leftData = [],
        rightData = []
      let formattedLeftCount = formatDataChartWithStepBarChart(result['left_count'], this.step)
      let formattedRightCount = formatDataChartWithStepBarChart(result['right_count'], this.step)

      formattedLeftCount.map(e => {
        e.y = e.y / this.step
      })

      formattedRightCount.map(e => {
        e.y = (e.y / this.step) * (this.isGoodBad ? -1 : 1)
      })

      for (let i = minSecond; i <= maxSecond - this.step; i += this.step) {
        let valueLeftCount = formattedLeftCount.find(e => e.x == i)
        if (valueLeftCount) {
          leftData.push(valueLeftCount.y)
        } else {
          leftData.push(0)
        }

        let valueRightCount = formattedRightCount.find(e => e.x == i)
        if (valueRightCount) {
          rightData.push(valueRightCount.y)
        } else {
          rightData.push(0)
        }
      }

      result['left_count'] = leftData
      result['right_count'] = rightData

      // add zero point to make chart
      Object.keys(result).forEach(key => {
        let value = result[key]
        let valueConvert = []
        result[key + '_label'] = value
        for (let i = 0; i < value.length; i++) {
          valueConvert.push(value[i])
          if (i < value.length - 1) {
            valueConvert.push(0)
          }
        }
        result[key] = valueConvert
      })

      result['all_left_count'] = formattedLeftCount.map(e => e.y)
      result['all_right_count'] = formattedRightCount.map(e => e.y)

      return result
    },
    reformatDataChart(data) {
      let result = JSON.parse(JSON.stringify(data))
      if (this.isGoodBad) {
        result['reformat'] = []
        return result
      }
      let dataReformat = []
      result['right_count'].forEach((e, index) => {
        if (result['left_count'][index] == 0 && e != 0) {
          dataReformat.push(e)
          result['right_count'][index] = 0
        } else {
          dataReformat.push(0)
        }
      })
      result['reformat'] = dataReformat
      return result
    },
    formatTooltip(vm, tooltip) {
      if (tooltip.key % 2 != 0 || vm.step !== 1) return ''
      let position = tooltip.key / 2

      if (tooltip.series.userOptions.stack != vm.selectedId && !vm.questionIdSelected) return ''

      let tooltipEl
      if (tooltip.series.type == 'column') {
        let data = {
          left: 0,
          right: 0,
        }

        let second = Math.floor(((tooltip.key + 1) * vm.step + (vm.part - 1) * 16 * vm.step) / 2)

        let imgEl = getImageTooltipEl(vm.imageURL + second + '.jpg')

        let leftData, rightData
        let answerValueId = tooltip.series.userOptions.stack
        leftData = roundDecimal(vm.dataScreeningFormatted[answerValueId]['left_count_label'][position], 1)
        rightData = roundDecimal(Math.abs(vm.dataScreeningFormatted[answerValueId]['right_count_label'][position]), 1)

        data['left'] = String(leftData) + '% '
        data['right'] = String(rightData) + '% '

        let reactionTagsEl = getReactionTagTooltipEl(vm.$props.project, data)
        tooltipEl = getTooltipLineChart(imgEl, reactionTagsEl)
        return tooltipEl
      } else {
        return ''
      }
    },
    setYMax() {
      this.maxSecond = 0
      this.maxVal = 0
      this.minVal = 0
      if (this.seriesData && this.seriesData.length) {
        this.seriesData.map(attribute => {
          let listValue = []
          let total = []
          const attributeValueId = attribute.id
          attribute.data.forEach(data => {
            listValue.push(data[0])
            total.push(data[1] + data[2])
          })

          if (listValue.length == 0) return
          // change maxSecond
          let maxSecond = Math.max(...listValue)
          if (this.maxSecond < maxSecond) this.maxSecond = maxSecond
          // change maxVal, minVal
          if (this.isGoodBad) {
            let maxVal = Math.max(...this.dataScreeningFormatted[attributeValueId]['all_left_count'])
            let minVal = Math.min(...this.dataScreeningFormatted[attributeValueId]['all_right_count'])
            if (this.maxVal < maxVal && typeof maxVal == 'number') this.maxVal = maxVal
            if (this.minVal > minVal && typeof minVal == 'number') this.minVal = minVal
          } else {
            let maxVal = Math.max(
              ...this.dataScreeningFormatted[attributeValueId]['all_left_count'].map(
                (e, index) => e + this.dataScreeningFormatted[attributeValueId]['all_right_count'][index],
              ),
            )
            if (this.maxVal < maxVal && typeof maxVal == 'number') this.maxVal = maxVal
            this.minVal = 0
          }
        })
      }

      const { max: min, interval: interval_min } = roundNumberLabelChart(this.minVal)
      const { max, interval: interval_max } = roundNumberLabelChart(this.maxVal)
      this.chartOptions.yAxis.max = max <= 100 ? max : 100

      if (this.isGoodBad) {
        if (this.minVal != 0) {
          this.chartOptions.yAxis.min = min >= -100 ? min : -100
        } else {
          this.chartOptions.yAxis.min = -0.5
        }
      } else {
        this.chartOptions.yAxis.min = 0
      }

      this.chartOptions.yAxis.tickInterval = Math.max(interval_min, interval_max)
      this.chartOptions.xAxis.tickInterval = 1
    },
    nextPartCheck() {
      if (this.maxSecond >= this.part * this.step * 8) EventBus.$emit('nextPartActive', true)
      else EventBus.$emit('nextPartActive', false)
    },
  },
  mounted() {
    this.initSeriesData()
  },
  created() {},
}
</script>
<style lang="scss" scoped>
::v-deep {
  .highcharts-credits {
    display: none;
  }

  .highcharts-series {
    opacity: 1;
  }

  .highcharts-container {
    margin-top: 10px;
  }
}
</style>
