<template>
  <div class="d-flex align-items-center justify-content-center w-64 interaction-content mt-3 w-100">
    <!-- <div class="d-flex mt-3">
      <div class="sentiment-posinega-positive" :class="{'invisible': !sentiment_posinega.positive}"
           :style="{width: sentiment_posinega.positive + '%'}">
        <p class="m-0" :class="{'invisible': sentiment_posinega.positive < 10}">Positive
          {{ sentiment_posinega.positive ? sentiment_posinega.positive.toFixed(1) : this.valueDefault }}%</p>
        <div class="positive-line cursor-pointer">
          <span v-if="sentiment_posinega.positive < 10" class="text-tooltip"
                :class="{'text-tooltip-right': sentiment_posinega.positive < 5}">Positive
            {{ sentiment_posinega.positive ? sentiment_posinega.positive.toFixed(1) : this.valueDefault }}%
          </span>
        </div>
      </div>
      <div class="sentiment-posinega-neutral"
           :class="[{'invisible': !sentiment_posinega.neutral}, {'mr-0': !sentiment_posinega.negative}, {'ml-0': !sentiment_posinega.positive}]"
           :style="{width: sentiment_posinega.neutral + '%'}">
        <p class="m-0"
           :class="[{'invisible': sentiment_posinega.neutral < 10}, {'text-right': !sentiment_posinega.negative}, {'pl-3': !sentiment_posinega.negative && !sentiment_posinega.positive}]">
          Neutral
          {{ sentiment_posinega.neutral ? sentiment_posinega.neutral.toFixed(1) : this.valueDefault }}%</p>
        <div class="neutral-line cursor-pointer"
             :class="[{'border-right-radius': !sentiment_posinega.negative}, {'border-left-radius': !sentiment_posinega.positive}]">
          <span v-if="sentiment_posinega.neutral < 10" class="text-tooltip"
                :class="[{'text-tooltip-left': sentiment_posinega.negative < 5}, {'text-tooltip-right': sentiment_posinega.positive < 5}]">
            Neutral {{ sentiment_posinega.neutral ? sentiment_posinega.neutral.toFixed(1) : this.valueDefault }}%
          </span>
        </div>
      </div>
      <div class="sentiment-posinega-negative text-right" :class="{'invisible': !sentiment_posinega.negative}"
           :style="{width: sentiment_posinega.negative + '%'}">
        <p class="m-0" :class="{'invisible': sentiment_posinega.negative < 10}">Negative
          {{ sentiment_posinega.negative ? sentiment_posinega.negative.toFixed(1) : this.valueDefault }}%</p>
        <div class="negative-line cursor-pointer">
           <span v-if="sentiment_posinega.negative < 10" class="text-tooltip"
                 :class="{'text-tooltip-left': sentiment_posinega.negative < 5}">Negative
            {{ sentiment_posinega.negative ? sentiment_posinega.negative.toFixed(1) : this.valueDefault }}%
          </span>
        </div>
      </div>
    </div> -->
    <div class="d-flex w-100 interaction-bar"
         v-if="(interactionButton1.value || interactionButton2.value) && this.reportVideo.tally_result">
      <div class="interaction-button-1" :style="{width: interactionButton1.value + '%'}">
        <p class="m-0" :class="{'invisible': interactionButton1.value < 15}" :style="{color: interactionButton1.color}">
          {{ interactionButton1.text }} {{ interactionButton1.value.toFixed(1) }}%</p>
        <div class="interaction-button-1-line cursor-pointer"
             :class="{'interaction-button-border-right': !interactionButton2.text || !interactionButton2.color || interactionButton2.value == 0}"
             :style="{backgroundColor: interactionButton1.color}">
          <span v-if="interactionButton1.value < 15" class="text-tooltip"
                :class="{'text-tooltip-right': interactionButton1.value < 5}"
                :style="{color: interactionButton1.color}">
            {{ interactionButton1.text }} {{ interactionButton1.value.toFixed(1) }}%
          </span>
        </div>
      </div>
      <div v-if="interactionButton2.text && interactionButton2.color" class="interaction-button-2 text-right"
           :style="{width: interactionButton2.value + '%'}">
        <p class="m-0" :class="{'invisible': interactionButton2.value < 15}" :style="{color: interactionButton2.color}">
          {{ interactionButton2.text }} {{ interactionButton2.value.toFixed(1) }}%</p>
        <div class="interaction-button-2-line cursor-pointer" :style="{backgroundColor: interactionButton2.color}" :class="{'border-left-radius': interactionButton1.value == 0}">
           <span v-if="interactionButton2.value < 15" class="text-tooltip"
                 :class="{'text-tooltip-left': interactionButton2.value < 5}"
                 :style="{color: interactionButton2.color}">
            {{ interactionButton2.text }} {{ interactionButton2.value.toFixed(1) }}%
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: ['videoDetail', 'reportVideo', 'comments', 'secondInteractionTotalData'],
  data() {
    return {
      valueDefault: '0.0'
    }
  },
  computed: {
    // sentiment_posinega() {
    //   let sentiment_posinega_valid = this.comments && Object.keys(this.comments).length && Object.keys(this.comments.sentiment_posinega).length
    //   return {
    //     'positive': sentiment_posinega_valid ? this.comments.sentiment_posinega.positive : '',
    //     'neutral': sentiment_posinega_valid ? this.comments.sentiment_posinega.neutral : '',
    //     'negative': sentiment_posinega_valid ? this.comments.sentiment_posinega.negative : '',
    //   }
    // },
    totalInteraction() {
      return this.secondInteractionTotalData && Object.keys(this.secondInteractionTotalData).length ? this.secondInteractionTotalData.total_2 + this.secondInteractionTotalData.total_3 : this.reportVideo && Object.keys(this.reportVideo).length ? this.reportVideo.total_2 + this.reportVideo.total_3 : 0
    },
    interactionButton1() {
      let interactionButton1Valid = this.videoDetail && this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length
      return {
        text: interactionButton1Valid ? this.videoDetail.interaction_btn_1.text : '',
        color: interactionButton1Valid ? this.colorInteraction(this.videoDetail.interaction_btn_1.color) : '',
        value: this.totalInteraction && Object.keys(this.secondInteractionTotalData).length ?
            Math.round(this.secondInteractionTotalData.total_2 / this.totalInteraction * 100) :
            this.totalInteraction && Object.keys(this.reportVideo).length ?
                Math.round(this.reportVideo.total_2 / this.totalInteraction * 100) :
                this.secondInteractionTotalData.total_2  == 0 && this.secondInteractionTotalData.total_3 == 0 ? 0 : 100
      }
    },
    interactionButton2() {
      let interactionButton2Valid = this.videoDetail && this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length
      return {
        text: interactionButton2Valid ? this.videoDetail.interaction_btn_2.text : '',
        color: interactionButton2Valid ? this.colorInteraction(this.videoDetail.interaction_btn_2.color) : '',
        value: this.secondInteractionTotalData.total_2  == 0 && this.secondInteractionTotalData.total_3 == 0 ? 0 : 100 - this.interactionButton1.value
      }
    }
  },
  methods: {
    colorInteraction(colorValue) {
      return colorValue == 1 ? '#4976ab' : colorValue == 2 ? '#e5bb26' : colorValue == 3 ? '#ce6060' : colorValue == 4 ? '#cf2f99' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.invisible {
  visibility: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

p, .text-tooltip {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 2px;
  white-space: nowrap;
}

.text-tooltip {
  position: absolute;
  z-index: 1;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
}

.text-tooltip-right {
  left: -2px;
  transform: translateX(0%);
}

.text-tooltip-left {
  left: 20px;
  transform: translateX(-100%);
}

.border-right-radius {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.border-left-radius {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.sentiment-posinega-positive {
  width: calc(100% / 3);
  color: #49AB94;

  .positive-line {
    position: relative;
    height: 12px;
    background-color: #49AB94;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    &:hover .text-tooltip {
      visibility: visible;
    }
  }
}

.sentiment-posinega-neutral {
  width: calc(100% / 3);
  color: #999999;
  margin: 0 2px;

  .neutral-line {
    position: relative;
    height: 12px;
    background-color: #999999;

    &:hover .text-tooltip {
      visibility: visible;
    }
  }
}

.mr-0 {
  margin-right: 0;
}

.ml-0 {
  margin-left: 0;
}

.sentiment-posinega-negative {
  width: calc(100% / 3);
  color: #CE6060;

  .negative-line {
    position: relative;
    height: 12px;
    background-color: #CE6060;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    &:hover .text-tooltip {
      visibility: visible;
    }
  }
}

.interaction-button-1 {
  .interaction-button-1-line {
    position: relative;
    height: 14px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    &:hover .text-tooltip {
      visibility: visible;
    }
  }

  .interaction-button-border-right {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.interaction-button-2 {
  margin-left: 2px;

  .interaction-button-2-line {
    position: relative;
    height: 14px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    &:hover .text-tooltip {
      visibility: visible;
    }
  }
}

.interaction-content {
  @media screen and (max-width: 1400px) {
    width: 100% !important;
    .interaction-bar{
      margin-top: 0.75rem;
    }
  }
}
</style>