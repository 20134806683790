<template>
  <div>
    <highcharts
      v-show="chartMode == listChartMode.line.value"
      id="chart-container"
      class="mt-2"
      :options="chartOptions"
      ref="areaRangeLineCharts"
      :constructor-type="'chart'"
    ></highcharts>
    <column-stack-button-chart
      v-show="chartMode == listChartMode.column.value"
      :reportScreening="reportScreening"
      :answerValueIdSelected="answerValueIdSelected"
      :selectedAnswerId="selectedAnswerId"
      :step="step"
      :part="part"
      :answerValueSelected="answerValueSelected"
      :questionIdSelected="questionIdSelected"
      :seriesData="seriesData"
      :selectedId="selectedId"
      :dataReport="dataReport"
      :project="project"
      :imageURL="imageURL"
      :dataReportSummary="dataReportSummary"
      :reactionButton="reactionButton"
    ></column-stack-button-chart>
  </div>
</template>
<script>
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import ColumnStackButtonChart from '@/components/Charts/Segment/ColumnStackButtonChart.vue'
import {
  getColorGrandient,
  formatEachInteractionSeriesData,
  lineSerieOptions,
  areaRangeSerieOptions,
  stateChartActive,
  getAreaRangeChartOption,
  getXColumnsByStep,
  getImageTooltipEl,
  getTooltipTopLineChart,
  getTooltipBottomLineChart,
  getReactionTagTooltipEl,
  findSerieNameOnClick,
} from '@/commons/charts'
const jQuery = require('jquery')
import EventBus from '@/commons/event_bus'
import { listChartMode } from '@/commons/constants'
import { roundDecimal, roundNumberLabelChart } from '@/commons/helpers'

export default {
  props: {
    selectedId: String,
    showChartIds: String,
    seriesData: Array,
    allSeriesData: Array,
    dataReport: [Object, Array],
    dataReportSummary: Object,
    step: Number,
    part: Number,
    numberColumn: {
      type: Number,
      default: 8,
    },
    imageURL: String,
    column: Number,
    maxColumn: Number,
    nameChart: String,
    project: Object,
    reportScreening: [Object, Array],
    answerValueIdSelected: Array,
    selectedAnswerId: [Number, String],
    questionIdSelected: [Number, String],
    answerValueSelected: Array,
    chartMode: Number,
    reactionButton: [Object, Array],
  },
  components: {
    highcharts: Chart,
    ColumnStackButtonChart,
  },
  data() {
    let vm = this
    let chartOptions = getAreaRangeChartOption(this.numberColumn)
    return {
      chartOptions: {
        chart: {
          height: '340px',
          animation: false,
          events: {
            load: function (e) {
              vm.chart = e.target
              vm.load(e.target)
            },
            redraw: function (e) {
              vm.redraw(e)
            },
          },
        },
        ...chartOptions,
        plotOptions: {
          ...chartOptions.plotOptions,
          series: {
            events: {
              mouseOut: vm.mouseOut,
              click: function (e) {
                let point = {
                  x: e.offsetX - this.chart.plotLeft,
                  y: e.offsetY - this.chart.plotTop,
                }

                let name = findSerieNameOnClick(this.chart.series, point, e.point.x)
                if (name) {
                  name = name.split('_')[0]
                  if (!vm.questionIdSelected) {
                    vm.selectedChart(name.split('-')[1])
                  } else {
                    EventBus.$emit('selectedAnswerChart', name)
                  }
                }
              },
            },
            point: {
              events: {
                mouseOver: function (e) {
                  let point = {
                    x: this.originalEvent.chartX - vm.chart.plotLeft,
                    y: this.originalEvent.chartY - vm.chart.plotTop,
                  }
                  let defaultSeriesHover = e.target.series.name
                  let nameSeries = findSerieNameOnClick(vm.chart.series, point, e.target.x)
                  if (nameSeries) {
                    let name = nameSeries.split('_')[0]
                    vm.chart.series.forEach(e => {
                      if (e.name.includes(name + '_')) {
                        e.setState('hover')
                      } else {
                        e.setState('')
                      }
                    })

                    vm.$emit('addClassActive', name)
                  } else {
                    vm.chart.series.find(e => e.name == defaultSeriesHover).setState('')
                  }
                },
              },
            },
            clip: false,
          },
        },
        yAxis: {
          ...chartOptions.yAxis,
          startOnTick: false,
          endOnTick: false,
          max: 110,
          min: 0,
          labels: {
            ...chartOptions.yAxis.labels,
            formatter: function () {
              return this.value <= 100 && this.value >= -100 ? roundDecimal(Math.abs(this.value), 1) + '%' : ''
            },
          },
        },
      },
      chart: null,
      nameHover: null,
      currentSelected: 0,
      image: null,
      tooltipElTop: null,
      tooltipElBottom: null,
      image2: null,
      line: null,
      positionTooltip: 0,
      listChartMode,
    }
  },
  watch: {
    step: function (newValue) {
      this.chartOptions.xAxis.tickInterval = newValue
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(newValue, 0),
      }
      this.prevSelected = this.currentSelected
      this.currentSelected = 0
      this.chartOptions.xAxis.min = 0
      this.chartOptions.xAxis.max = newValue * this.maxColumn
      this.clearTooltip()
      this.initSeriesData()
      this.load(this.chart)
    },
    part: function (newValue, oldValue) {
      this.prevPart = oldValue
      this.prevSelected = this.currentSelected
      this.currentSelected = (newValue - 1) * this.step * this.column
      this.chartOptions.xAxis.min = (newValue - 1) * this.step * this.column
      this.chartOptions.xAxis.tickInterval = this.step
      this.chartOptions.xAxis.max = (newValue - 1) * this.step * this.column + this.step * this.maxColumn
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(this.step, (newValue - 1) * this.step * this.column),
      }
      this.clearTooltip()
      this.initSeriesData()
      this.load(this.chart)
    },
    selectedId: function () {
      if (this.questionIdSelected) return
      this.updateSelectedId()
    },
    selectedAnswerId: function () {
      if (!this.questionIdSelected) return
      this.updateSelectedId()
    },
    chartMode: function () {
      this.updateSelectedId()
    },
    seriesData: {
      handler: function (newValue, oldValue) {
        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
          this.clearTooltip()
          this.initSeriesData()
          while (this.chart.series.length > 0) this.chart.series[0].remove(false)
          this.chart.redraw()
          this.load(this.chart)
        }
      },
      deep: true,
    },
    imageURL: function () {
      this.load(this.chart)
    },
  },
  methods: {
    updateSelectedId() {
      if (this.chartMode != listChartMode.line.value) return
      this.clearTooltip()
      this.initSeriesData()
      this.chart.redraw()
      while (this.chart.series.length > 0) this.chart.series[0].remove(false)
      this.chart.redraw()
      this.load(this.chart)
    },
    selectedChart(name) {
      EventBus.$emit('selectedChart', name)
    },
    clearTooltip() {
      if (this.chart.xAxis[0].options.plotLines && this.chart.xAxis[0].options.plotLines.length) {
        this.chart.xAxis[0].removePlotLine('plotLineTooltip')
        this.chart.xAxis[0].update()
      }
      if (this.tooltipElTop) {
        this.tooltipElTop.destroy()
        this.tooltipElTop = null
      }
      if (this.tooltipElBottom) {
        this.tooltipElBottom.destroy()
        this.tooltipElBottom = null
      }
      if (this.image) {
        this.image.destroy()
        this.image = null
      }
      if (this.image2) {
        this.image2.destroy()
        this.image2 = null
      }
    },
    load(chart) {
      if ((this.selectedId && !this.questionIdSelected) || (this.selectedAnswerId && this.questionIdSelected)) {
        let idSelected = this.questionIdSelected ? this.selectedAnswerId : this.selectedId
        let reportVideo = this.seriesData?.find(e => e.id == idSelected)
        if (reportVideo) {
          let line1 = this.chartOptions.series.find(e => e.name == idSelected + '_line1')
          let line2 = this.chartOptions.series.find(e => e.name == idSelected + '_line2')
          if (!line1 || !line2) return
          let serieMaxX = Math.max(...line1.data.map(e => e.x))
          if (serieMaxX < this.currentSelected) {
            this.currentSelected = this.step * this.column * (this.part - 1)
          }
          let position1, position2
          line1.data.map(e => {
            if (e.x != this.currentSelected) {
              e.marker = {}
            } else {
              e.marker = { enabled: true }
            }
            return e
          })

          line2.data.map(e => {
            if (e.x != this.currentSelected) {
              e.marker = {}
            } else {
              e.marker = { enabled: true }
            }
            return e
          })
          if (!line1.data.find(serie => serie.x == this.currentSelected)) {
            return
          }
          let y1 = line1.data.find(serie => serie.x == this.currentSelected).y
          let y2 = line2.data.find(serie => serie.x == this.currentSelected).y

          chart.update(this.chartOptions)
          let imgEl = getImageTooltipEl(this.imageURL + this.currentSelected + '.jpg')
          let reactionTagsEl = getReactionTagTooltipEl(
            this.project,
            {
              left: Math.abs(y1) + '%',
              right: Math.abs(y2) + '%',
            },
            true,
          )

          if (chart.xAxis[0].options.plotLines && chart.xAxis[0].options.plotLines.length) {
            chart.xAxis[0].options.plotLines[0].value = this.currentSelected
          } else {
            chart.xAxis[0].addPlotLine({
              className: 'line-show-tooltip',
              color: '#49AB94',
              width: 1,
              value: this.currentSelected,
              id: 'plotLineTooltip',
              zIndex: 2,
            })
          }

          chart.xAxis[0].update()
          this.$nextTick(() => {
            let tooltipElTop, tooltipElBottom
            let positionTop, positionBottom
            line2 = this.chart.series.find(e => e.name == idSelected + '_line2')
            position2 = line2.data.find(
              serie => serie && Object.prototype.hasOwnProperty.call(serie, 'x') && serie.x == this.currentSelected,
            )
            line1 = this.chart.series.find(e => e.name == idSelected + '_line1')
            position1 = line1.data.find(
              serie => serie && Object.prototype.hasOwnProperty.call(serie, 'x') && serie.x == this.currentSelected,
            )
            if (y1 >= y2) {
              tooltipElTop = getTooltipTopLineChart(imgEl, reactionTagsEl.left)
              tooltipElBottom = getTooltipBottomLineChart(reactionTagsEl.right)
              positionTop = position1.plotY - 50
              positionBottom = position2.plotY
              if (position1.y > 100) {
                positionTop = position1.y
              }
              if (position1.y == 100) {
                positionTop = positionTop + 20
              }
            } else {
              tooltipElTop = getTooltipTopLineChart(imgEl, reactionTagsEl.right)
              tooltipElBottom = getTooltipBottomLineChart(reactionTagsEl.left)
              positionTop = position2.plotY - 50
              positionBottom = position1.plotY
              if (position2.y > 100) {
                positionTop = position2.y
              }
              if (position2.y == 100) {
                positionTop = positionTop + 20
              }
            }
            if (positionTop < 45) {
              positionTop = positionTop + 80
            }
            if (positionTop + 60 > positionBottom) {
              positionBottom += positionTop + 60 - positionBottom + this.column
            }
            let numberColumn = (this.currentSelected - this.chartOptions.xAxis.min) / this.step
            let positionX = (numberColumn * chart.plotWidth) / this.maxColumn + 5
            let gridBox = chart.xAxis[0].gridGroup.getBBox()
            let widthColumn = gridBox.width / this.column
            if (this.tooltipElTop) {
              this.tooltipElTop.destroy()
            }
            if (this.tooltipElBottom) {
              this.tooltipElBottom.destroy()
            }
            this.tooltipElTop = this.drawTooltipSVG(tooltipElTop, positionX, positionTop)
            this.tooltipElBottom = this.drawTooltipSVG(tooltipElBottom, positionX, positionBottom)
            if (numberColumn != 0) {
              if (this.image2) {
                this.image2.destroy()
              }
              this.image2 = chart.renderer
                .image('/images/svg/icon_prev_line.svg', gridBox.x + numberColumn * widthColumn - 30, 10, 28, 16)
                .attr({
                  zIndex: 100,
                  cursor: 'pointer',
                  'data-report': 'action',
                })
                .translate(0, 0)
                .addClass('image-plot-line image-plot-line-prev')
                .on('mousedown', this.start)
                .add()
            } else {
              this.image2 = null
            }
            let currentSeries = this.chartOptions.series.find(e => e.name == idSelected + '_line1')

            let maxChart = Math.max(...currentSeries.data.map(e => e.x))
            let currentVideoMaxX = maxChart < chart.xAxis[0].max ? maxChart : chart.xAxis[0].max - this.step / 2
            if (numberColumn == Math.floor((currentVideoMaxX - (this.part - 1) * this.column) / this.step)) {
              this.image = null
            } else {
              if (this.image) {
                this.image.destroy()
              }
              this.image = chart.renderer
                .image('/images/svg/icon_next_line.svg', gridBox.x + numberColumn * widthColumn + 2, 10, 28, 16)
                .attr({
                  zIndex: 100,
                  cursor: 'pointer',
                  'data-report': 'action',
                })
                .translate(0, 0)
                .addClass('image-plot-line image-plot-line-next')
                .on('mousedown', this.start)
                .add()
            }
            this.line = chart.xAxis[0].plotLinesAndBands[0].svgElem.translate(0, 0)
          })
        }
      }
    },
    redraw(e) {
      let chart = e.target
      this.chart = chart
      if (chart.oldChartWidth && chart.chartWidth != this.oldChartWidth) {
        this.oldChartWidth = chart.chartWidth
        this.reflow(chart)
      }
    },
    reflow(chart) {
      if (this.selectedId) {
        this.clearTooltip()
        this.load(chart)
      }
    },
    start(e) {
      let chart = this.chart
      jQuery(document).bind({
        'mousemove.image-plot-line': this.steps,
        'mouseup.image-plot-line': this.stop,
      })
      let gridBox = chart.xAxis[0].gridGroup.getBBox()
      let widthColumn = gridBox.width / this.column
      let numberColumn = (this.currentSelected - this.chartOptions.xAxis.min) / this.step

      if (jQuery(e.target).hasClass('image-plot-line-next')) {
        if (this.image) {
          this.image.destroy()
          this.image = null
        }
        this.image = chart.renderer
          .image('/images/svg/icon_next_line_hover.svg', gridBox.x + numberColumn * widthColumn + 2, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-next')
          .on('mousedown', this.start)
          .add()
      } else {
        if (this.image2) {
          this.image2.destroy()
          this.image2 = null
        }
        this.image2 = chart.renderer
          .image('/images/svg/icon_prev_line_hover.svg', gridBox.x + numberColumn * widthColumn - 30, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-prev')
          .on('mousedown', this.start)
          .add()
      }
      this.line = chart.xAxis[0].plotLinesAndBands[0].svgElem.translate(0, 0)
      this.clickX = e.pageX - this.line.translateX
    },
    steps(e) {
      let chart = this.chart
      let gridBox = chart.xAxis[0].gridGroup.getBBox()
      let widthColumn = gridBox.width / this.column
      let currentSeries
      if (!this.questionIdSelected) {
        currentSeries = this.chartOptions.series.find(e => e.name == this.selectedId + '_line1')
      } else {
        currentSeries = this.chartOptions.series.find(e => e.name == this.selectedAnswerId + '_line1')
      }

      let maxChart = Math.max(...currentSeries.data.map(e => e.x))
      let currentVideoMaxX = maxChart < chart.xAxis[0].max ? maxChart : chart.xAxis[0].max
      let maxWidthExtra = (widthColumn * (currentVideoMaxX - this.currentSelected)) / this.step
      let numberColumn = (this.currentSelected - (this.part - 1) * this.column * this.step) / this.step
      let maxWidthMinus = widthColumn * numberColumn
      let translateX = e.pageX - this.clickX
      if (maxWidthMinus + translateX < 0) return
      if (translateX > maxWidthExtra) return

      if (this.line) {
        this.line.translate(translateX)
      }
      if (this.image) {
        this.image.translate(translateX)
      }
      if (this.image2) {
        this.image2.translate(translateX)
      }
      this.tooltipElTop.attr('x', this.positionTooltip + translateX)
      this.tooltipElBottom.attr('x', this.positionTooltip + translateX)
    },
    stop() {
      jQuery(document).unbind({
        'mousemove.image-plot-line': this.steps,
        'mouseup.image-plot-line': this.stop,
      })
      let chart = this.chart
      let gridBox = chart.xAxis[0].gridGroup.getBBox()
      let widthColumn = gridBox.width / this.column
      this.prevSelected = this.currentSelected
      this.currentSelected =
        this.currentSelected + Math.round((this.line.translateX / chart.plotWidth) * this.column) * this.step

      let currentSeries
      if (!this.questionIdSelected) {
        currentSeries = this.chartOptions.series.find(e => e.name == this.selectedId + '_line1')
      } else {
        currentSeries = this.chartOptions.series.find(e => e.name == this.selectedAnswerId + '_line1')
      }

      let maxChart = Math.max(...currentSeries.data.map(e => e.x))
      if (this.currentSelected > maxChart) {
        this.currentSelected = this.currentSelected - this.step
      }
      let numberColumn = (this.currentSelected - (this.part - 1) * this.column * this.step) / this.step
      let positionX = (numberColumn * chart.plotWidth) / this.maxColumn + 5
      let line1, line2
      if (!this.questionIdSelected) {
        line1 = this.chartOptions.series.find(e => e.name == this.selectedId + '_line1')
        line2 = this.chartOptions.series.find(e => e.name == this.selectedId + '_line2')
      } else {
        line1 = this.chartOptions.series.find(e => e.name == this.selectedAnswerId + '_line1')
        line2 = this.chartOptions.series.find(e => e.name == this.selectedAnswerId + '_line2')
      }

      if (!line1 || !line2) return
      line1.data.map(e => {
        if (e.x != this.currentSelected) {
          e.marker = {}
        } else {
          e.marker = { enabled: true }
        }
        return e
      })
      line2.data.map(e => {
        if (e.x != this.currentSelected) {
          e.marker = {}
        } else {
          e.marker = { enabled: true }
        }
        return e
      })
      let y1 = line1.data.find(serie => serie.x == this.currentSelected).y
      let y2 = line2.data.find(serie => serie.x == this.currentSelected).y

      chart.update(this.chartOptions)
      this.clearTooltip()
      chart.xAxis[0].update()
      let imgEl = getImageTooltipEl(this.imageURL + this.currentSelected + '.jpg')
      let reactionTagsEl = getReactionTagTooltipEl(
        this.project,
        {
          left: Math.abs(y1) + '%',
          right: Math.abs(y2) + '%',
        },
        true,
      )
      let tooltipElTop, tooltipElBottom
      let positionTop, positionBottom
      let position1, position2

      if (!this.questionIdSelected) {
        line1 = this.chart.series.find(e => e.name == this.selectedId + '_line1')
        line2 = this.chart.series.find(e => e.name == this.selectedId + '_line2')
      } else {
        line1 = this.chart.series.find(e => e.name == this.selectedAnswerId + '_line1')
        line2 = this.chart.series.find(e => e.name == this.selectedAnswerId + '_line2')
      }

      position2 = line2.data.find(
        serie => serie && Object.prototype.hasOwnProperty.call(serie, 'x') && serie.x == this.currentSelected,
      )
      position1 = line1.data.find(
        serie => serie && Object.prototype.hasOwnProperty.call(serie, 'x') && serie.x == this.currentSelected,
      )
      if (y1 >= y2) {
        tooltipElTop = getTooltipTopLineChart(imgEl, reactionTagsEl.left)
        tooltipElBottom = getTooltipBottomLineChart(reactionTagsEl.right)
        positionTop = position1.plotY - 50
        positionBottom = position2.plotY

        if (position1.y > 100) {
          positionTop = position1.y
        }
        if (position1.y == 100) {
          positionTop = positionTop + 20
        }
      } else {
        tooltipElTop = getTooltipTopLineChart(imgEl, reactionTagsEl.right)
        tooltipElBottom = getTooltipBottomLineChart(reactionTagsEl.left)
        positionTop = position2.plotY - 50
        positionBottom = position1.plotY

        if (position2.y > 100) {
          positionTop = position2.y
        }
        if (position2.y == 100) {
          positionTop = positionTop + 20
        }
      }
      if (positionTop < 45) {
        positionTop = positionTop + 80
      }
      if (positionTop + 60 > positionBottom) {
        positionBottom += positionTop + 60 - positionBottom + this.column
      }
      this.tooltipElTop = this.drawTooltipSVG(tooltipElTop, positionX, positionTop)
      this.tooltipElBottom = this.drawTooltipSVG(tooltipElBottom, positionX, positionBottom)
      if (chart.xAxis[0].options.plotLines && chart.xAxis[0].options.plotLines.length) {
        chart.xAxis[0].options.plotLines[0].value = this.currentSelected
      } else {
        chart.xAxis[0].addPlotLine({
          className: 'line-show-tooltip',
          color: '#49AB94',
          width: 1,
          value: this.currentSelected,
          id: 'plotLineTooltip',
          zIndex: 2,
        })
      }
      chart.xAxis[0].update()
      this.line = chart.xAxis[0].plotLinesAndBands[0].svgElem.translate(0, 0)
      if (numberColumn != 0) {
        if (this.image2) {
          this.image2.destroy()
        }
        this.image2 = chart.renderer
          .image('/images/svg/icon_prev_line.svg', gridBox.x + numberColumn * widthColumn - 30, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-prev')
          .on('mousedown', this.start)
          .add()
      } else {
        this.image2 = null
      }
      let currentVideoMaxX = maxChart < chart.xAxis[0].max ? maxChart : chart.xAxis[0].max - this.step / 2
      if (numberColumn == Math.floor((currentVideoMaxX - (this.part - 1) * this.column * this.step) / this.step)) {
        this.image = null
      } else {
        if (this.image) {
          this.image.destroy()
        }
        this.image = chart.renderer
          .image('/images/svg/icon_next_line.svg', gridBox.x + numberColumn * widthColumn + 2, 10, 28, 16)
          .attr({
            zIndex: 100,
            cursor: 'pointer',
            'data-report': 'action',
          })
          .translate(0, 0)
          .addClass('image-plot-line image-plot-line-next')
          .on('mousedown', this.start)
          .add()
      }
    },
    drawTooltipSVG(tooltipEl, positionX, positonY) {
      this.positionTooltip = positionX
      return this.chart.renderer
        .text(tooltipEl, positionX, positonY, true)
        .attr({
          zIndex: 100,
          cursor: 'pointer',
        })
        .add()
    },
    initSeriesData() {
      let series = []
      let { color1, color2 } = getColorGrandient(this.project, null)
      let dataLine = []
      this.seriesData.forEach(videoData => {
        let { line1, line2, arearange1, arearange2 } = formatEachInteractionSeriesData(videoData.data)
        dataLine = dataLine.concat([line1, line2])
        let selectedId = this.questionIdSelected ? this.selectedAnswerId : this.selectedId
        if (selectedId == videoData.id) {
          series.push({
            ...lineSerieOptions,
            name: videoData.id + '_line1',
            className: 'area-line-segment',
            color: color1,
            data: line1,
            zIndex: videoData.id.includes('avg') ? 3 : 1,
            zones: [
              {
                value: this.step * this.part * this.numberColumn,
              },
              {
                dashStyle: 'ShortDash',
              },
            ],
          })
          series.push({
            ...lineSerieOptions,
            name: videoData.id + '_line2',
            className: 'area-line-segment',
            color: color2,
            data: line2,
            zIndex: videoData.id.includes('avg') ? 3 : 1,
            zones: [
              {
                value: this.step * this.part * this.numberColumn,
              },
              {
                dashStyle: 'ShortDash',
              },
            ],
          })
        }
        series.push({
          name: videoData.id + '_arearange1',
          className: 'area-line-segment',
          ...areaRangeSerieOptions,
          fillOpacity: 0.5,
          fillColor: this.getFillGradientColor('right'),
          ...(videoData.id == selectedId ? { states: stateChartActive, opacity: 1 } : {}),
          data: arearange1,
          zIndex: videoData.id.includes('avg') ? 2 : 1,
          findNearestPointBy: 'xy',
          linkedTo: videoData.id + '_arearange2',
          zoneAxis: 'x',
          zones: [
            {
              value: this.step * this.part * this.numberColumn,
            },
            {
              className: 'lightcolor',
            },
          ],
        })
        series.push({
          name: videoData.id + '_arearange2',
          className: 'area-line-segment',
          ...areaRangeSerieOptions,
          fillOpacity: 0.5,
          fillColor: this.getFillGradientColor('left'),
          ...(videoData.id == selectedId ? { states: stateChartActive, opacity: 1 } : {}),
          data: arearange2,
          zIndex: videoData.id.includes('avg') ? 2 : 1,
          findNearestPointBy: 'xy',
          linkedTo: videoData.id + '_arearange1',
          zoneAxis: 'x',
          zones: [
            {
              value: this.step * this.part * this.numberColumn,
            },
            {
              className: 'lightcolor',
            },
          ],
        })
      })

      // set max y axis
      if (dataLine.length && this.dataReport) {
        let maxY = Math.max(...dataLine.map(e => Math.max(...e.map(e => e.y))))
        const { max, interval } = roundNumberLabelChart(maxY)

        this.chartOptions.yAxis.max = max <= 100 ? max : 100
        this.chartOptions.yAxis.tickInterval = interval
      } else {
        this.chartOptions.yAxis.max = 0.5
        this.chartOptions.yAxis.tickInterval = 0.5
      }

      this.chartOptions.series = series

      this.$nextTick(function () {
        jQuery('#chart-segment .area-line-segment').attr('data-report', 'action')
      })
    },
    mouseOut(e) {
      let nameOut = e.target.name
      this.$emit('removeClassActive', nameOut)
    },
    getFillGradientColor(low) {
      let { color1, color2 } = getColorGrandient(this.project, low)
      return {
        linearGradient: [0, 0, 0, 360],
        stops: [
          [0, Highcharts.Color(color1).setOpacity(0.5).get('rgba')],
          [0.3, Highcharts.Color(color1).setOpacity(0.25).get('rgba')],
          [1, Highcharts.Color(color2).setOpacity(0.25).get('rgba')],
        ],
      }
    },
  },
  mounted() {
    this.chartOptions.xAxis.min = (this.part - 1) * this.step * this.column
    this.chartOptions.xAxis.tickInterval = this.step
    this.chartOptions.xAxis.max = (this.part - 1) * this.step * this.column + this.step * this.maxColumn
    this.currentSelected = (this.part - 1) * this.step * this.column
    this.initSeriesData()
    this.chart = this.$refs.areaRangeLineCharts.chart
    this.load(this.chart)
    this.$emit('setChart', this.chart)
  },
}
</script>
<style lang=""></style>
